import {
  APIService,
  BluepagesService,
  UserImagesService,
} from "@/common/api.service";
import AuthService from "@/common/auth.service";
import { ErrorHandler } from "../plugins/error-handler";
import {
  CHECK_AUTH,
  LOGIN,
  GET_ACCESS_TOKEN,
  GET_API_TOKEN,
  REFRESH_TOKEN_TIMERS,
  GET_USER_DATA,
  CREATE_NEW_USER,
  UPDATE_USER,
  GET_USER_PROFILE_PICTURE,
  GET_USER_BLUEPAGES_PICTURE,
  GET_USER_BLUEPAGES_PICTURE_RAW,
  LOGOUT,
  DELETE_USER,
  DELETE_OTHER_USER,
  ONBOARD_NEW_USER,
  UPDATE_USER_BADGE,
  UPDATE_USER_FROM_BLUEPAGES,
  CREATE_USER_PREFERENCES,
  GET_USER_PREFERENCES,
  UPDATE_USER_PREFERENCES,
} from "./actions.type";
import axios from "axios";
import Cookies from "js-cookie";
import {
  SET_AUTH,
  PURGE_AUTH,
  SET_USER_DATA,
  SET_ONBOARD_DATA,
  SET_PROFILE_PICTURE,
  SET_JRSS_DATA,
  SET_RESOURCE_USER,
  SET_TSC_DETAIL,
  SET_USER_PREFERENCES,
  SET_CANDIDATE_DATA,
} from "./mutations.type";

const onboardCandidate = {
  data: null,
  profilePicture: null,
  isAuthenticated: !!AuthService.getAccessToken(),
};

const newData = {
  data: null,
  profilePicture: null,
  isAuthenticated: !!AuthService.getAccessToken(),
};

const state = {
  data: null,
  profilePicture: null,
  isAuthenticated: !!AuthService.getAccessToken(),
  userPreferences: null,
};

function parsePermissions(roles) {
  return roles[0].mapped_roles.role.role_permissions
    .map((perm) => perm.permission.code)
    .flat();
}

const getters = {
  currentUser(state) {
    return state.data;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  profilePicture(state) {
    return state.profilePicture;
  },
  /** role can be a string or an array of roles
   * returns whether current user has been assigned this role
   * */
  userHasRole: (state) => (roles) => {
    if (Array.isArray(roles) && roles) {
      return roles.some((role) =>
        state.data?.roles[0]?.role?.name?.includes(role)
      );
    } else if (roles) {
      return state.data?.roles[0]?.role?.name?.includes(roles);
    } else {
      return true;
    }
  },
  userHasPerm: (state) => (perms) => {
    if (Array.isArray(perms) && perms) {
      return perms.some((perm) => state.data?.perms?.includes(perm));
    } else if (perms) {
      return state?.data?.perms?.includes(perms);
    } else {
      return true;
    }
  },
  checkUserIsProjectManager: (state) => () => {
    return state.data.roles[0].role.name.includes("Project Manager");
  },
  userPreferences(state) {
    return state.userPreferences;
  },
};

const actions = {
  async [LOGIN]() {
    window.location.href = process.env.VUE_APP_AUTH_URL + "/talentmatch"; //  old method - axios GET "/hello" -then catch this statement
  },
  async [CHECK_AUTH](context) {
    // Check for access token and serial number in user cookie
    // check if the access token has expired
    // if serial number/tokens are in local stroage, get user data from API
    if (
      AuthService.getAccessToken() &&
      AuthService.getSerialNumber() &&
      AuthService.getAPItoken()
    ) {
      // load user data from database if it has not been loaded already
      if (!state.data) {
        await context.dispatch(GET_USER_DATA);
      }
      // check if the access token has expired
      if (
        AuthService.getAccessTokenExpiryTime() <=
          Math.round(Date.now() / 1000) &&
        AuthService.getAccessToken()
      ) {
        context.commit(PURGE_AUTH);
        ErrorHandler(
          {
            title: "User instance timed out",
            subTitle: "Please log in to continue using this service",
          },
          "warning"
        );
      }
      // otherwise check if there is an user cookie
    } else if (Cookies.get("user")) {
      const userCookie = Cookies.get("user");
      const user = JSON.parse(userCookie);
      // save tokens and serial number
      AuthService.saveAccessToken(user["accessToken"]);
      AuthService.saveRefreshToken(user["refreshToken"]);
      AuthService.saveAPItoken(user["apiToken"]);
      AuthService.saveSerialNumber(user["_json"].uid);
      AuthService.saveAccessTokenExpiryTime(user.accessTokenExpiryTime);
      AuthService.saveApiTokenExpiryTime(user["apiTokenExpire"]);
      await context.dispatch(GET_USER_DATA);
    } else {
      // if user is not logged in, clear any "leftovers" from previous sessions
      context.commit(PURGE_AUTH);
    }
  },
  async [GET_USER_DATA](context) {
    try {
      let { data } = await APIService.get(
        "/v3/user_profile",
        AuthService.getSerialNumber()
      );

      if (!data.profile) {
        const new_user = await context.dispatch(CREATE_NEW_USER);
        const newuser = await APIService.get(
          "/v3/user_profile",
          new_user.serial_number
        );
        data = newuser.data;
      }
      data.profile.perms = parsePermissions(data.profile.roles);
      const { candidate } = { candidate: data.profile, ...data };
      context.commit(SET_AUTH, candidate);
    } catch (error) {
      context.commit(PURGE_AUTH);
      ErrorHandler(
        {
          title: "Unable to contact backend systems",
          subTitle: "Please ensure your system is configured correctly",
        },
        "warning"
      );
    }
  },
  /** @todo this is a temp solution for refreshing access token */
  async [GET_ACCESS_TOKEN](context) {
    try {
      const { data } = await axios({
        url: `${process.env.VUE_APP_AUTH_URL}/refresh_token`,
        method: "GET",
        timeout: 5000,
        headers: {
          refreshtoken: AuthService.getRefreshToken(),
        },
      });
      AuthService.saveAccessToken(data.access_token);
      AuthService.saveRefreshToken(data.refresh_token);
    } catch (error) {
      //throw new Error(error);
      ErrorHandler(
        {
          title: "User instance timed out",
          subTitle: "Please log in to continue using this service",
        },
        "warning"
      );
      context.commit(PURGE_AUTH);
    }
  },
  async [GET_API_TOKEN](context) {
    try {
      const { data } = await axios({
        url: `${process.env.VUE_APP_AUTH_URL}/refresh_api`,
        method: "GET",
        timeout: 5000,
        headers: {
          accesstoken: AuthService.getAccessToken(),
          serialnumber: AuthService.getSerialNumber(),
        },
      });
      AuthService.saveAPItoken(data.token);
      // AuthService.saveApiTokenExpiryTime(data.exp);
    } catch (error) {
      context.commit(PURGE_AUTH);
      ErrorHandler(
        {
          title: "User instance timed out",
          subTitle: "Please log in to continue using this service",
        },
        "warning"
      );
      //throw new Error(error);
    }
  },
  async [REFRESH_TOKEN_TIMERS](context) {
    try {
      if (
        AuthService.getAccessToken() &&
        AuthService.getAPItoken() &&
        AuthService.getAccessTokenExpiryTime() > 100
      ) {
        const { data } = await axios({
          url: `${process.env.VUE_APP_AUTH_URL}/refresh_api`,
          method: "GET",
          timeout: 5000,
          headers: {
            accesstoken: AuthService.getAccessToken(),
            serialnumber: AuthService.getSerialNumber(),
          },
        });
        AuthService.saveAPItoken(data.token);
        AuthService.saveAccessTokenExpiryTime(data.exp);
        AuthService.saveApiTokenExpiryTime(data.exp);
      }
    } catch (error) {
      context.commit(PURGE_AUTH);
      ErrorHandler(
        {
          title: "User instance timed out",
          subTitle: "Please log in to continue using this service",
        },
        "warning"
      );
      //throw new Error(error);
    }
  },
  async [CREATE_NEW_USER](context) {
    try {
      // Get information from Bluepages
      const { data } = await BluepagesService.get(
        "profiles",
        AuthService.getSerialNumber(),
        "profile_combined"
      );
      // TODO: pull up practice, region etc. include badges
      const user = {
        serial_number: data?.content?.identity_info?.userId,
        email: data?.content?.identity_info?.content?.mail?.[0],
        full_name: data?.content?.identity_info?.content?.nameFull,
        job_title: data?.content?.identity_info?.content?.role,
        practice: data?.content?.identity_info?.content?.practiceAlignment,
        phone_number:
          data?.content?.identity_info?.content?.telephone?.mobile &&
          `+${data?.content?.identity_info?.content?.telephone?.mobile.replace(
            "-",
            ""
          )}`,
        slack_id: data.content.identity_info.content.preferredSlackId,
        manager_name:
          data?.content?.identity_info?.content?.functionalManager?.nameDisplay,
        manager_serial_number:
          data?.content?.identity_info?.content?.functionalManager?.uid,
        manager_email:
          data?.content?.identity_info?.content?.functionalManager
            ?.preferredIdentity,
        pronouns: data?.content?.bpPronoun,
        //Pulled during eduction so list would update each time enter form, and only store badges that are selected
        // badges:
        //   data?.content?.profile_extended?.content?.certifications?.badges?.map(
        //     (badge) => {
        //       return {
        //         id: badge.badgeId,
        //         name: badge.badgeName,
        //         image: badge.badgeImageUrl,
        //       };
        //     }
        //   ),
        title: data?.content?.identity_info?.content?.courtesyTitle,
        is_deleted: false,
      };

      const response = await APIService.post("/v3/user", user);
      context.commit(SET_AUTH, response.data);
      return response.data.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_USER_FROM_BLUEPAGES](context, payload) {
    try {
      // Get information from Bluepages
      const { data } = await BluepagesService.get(
        "profiles",
        payload.serial_number,
        "profile_combined"
      );
      const user = {
        email: data?.content?.identity_info?.content?.mail[0],
        full_name: data?.content?.identity_info?.content?.nameFull,
        job_title: data?.content?.identity_info?.content?.role,
        practice_name: data?.content?.identity_info?.content?.practiceAlignment,
        phone_number:
          data?.content?.identity_info?.content?.telephone?.mobile &&
          `+${data?.content?.identity_info?.content?.telephone?.mobile.replace(
            "-",
            ""
          )}`,
        slack_id: data?.content?.identity_info?.content?.preferredSlackId,
        manager_name:
          data?.content?.identity_info?.content?.functionalManager?.nameDisplay,
        manager_serial_number:
          data?.content?.identity_info?.content?.functionalManager?.uid,
        manager_email:
          data?.content?.identity_info?.content?.functionalManager
            ?.preferredIdentity,
        pronouns: data?.content?.bpPronoun,
        title: data?.content?.identity_info?.content?.courtesyTitle,
        is_deleted: false,
        penpic_complete: payload.penpic_complete,
      };

      if (payload.source === "resources") {
        await APIService.update("/v3/my_profile", payload.serial_number, {
          ...user,
        });
        const user_id = payload.id;
        const resource = await APIService.get("v3/resources", user_id);
        context.commit(SET_RESOURCE_USER, resource.data.rows[0]);
      } else if (payload.source === "tsc") {
        const user_id = payload.id;
        await APIService.update("v3/tsc", user_id, {
          ...user,
        });
        const { data } = await APIService.get("v3/tsc_by_id", user_id);
        context.commit(SET_TSC_DETAIL, data.rows[0]);
      } else if (payload.source === "candidates") {
        await APIService.update("v3/my_profile", payload.serial_number, {
          ...user,
        });
        if (payload.isUser) {
          const { data } = await APIService.get(
            "/v3/user_profile",
            payload.serial_number
          );
          data.profile.perms = parsePermissions(data.profile.roles);
          const { users } = { users: data.profile, ...data };
          context.commit(SET_AUTH, users);
        } else {
          const { data } = await APIService.get(
            "/v3/user_data",
            payload.serial_number
          );
          context.commit(SET_CANDIDATE_DATA, data);
        }
      }
      return { status: "ok" };
    } catch (error) {
      return { status: "failed" };
    }
  },
  async [GET_USER_PROFILE_PICTURE](context) {
    try {
      const { data } = await UserImagesService.get(
        AuthService.getSerialNumber()
      );
      context.commit(SET_PROFILE_PICTURE, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_USER_BLUEPAGES_PICTURE]() {
    try {
      const { data } = await BluepagesService.getImage(
        "image",
        AuthService.getSerialNumber()
      );
      return URL.createObjectURL(data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_USER_BLUEPAGES_PICTURE_RAW]() {
    try {
      const { data } = await BluepagesService.getImage(
        "image",
        AuthService.getSerialNumber()
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  },

  async [UPDATE_USER]() {
    try {
      if (state.profilePicture) {
        const { data } = await UserImagesService.upload(
          AuthService.getSerialNumber(),
          state.profilePicture
        );
        await APIService.update(
          "v3/my_profile",
          AuthService.getSerialNumber(),
          {
            ...state.data,
            profile_picture: data.filename,
          }
        );
      } else if (!onboardCandidate.data) {
        await APIService.update(
          "v3/my_profile",
          AuthService.getSerialNumber(),
          {
            ...state.data,
          }
        );
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async [ONBOARD_NEW_USER](context, serial_number) {
    try {
      const { data } = await BluepagesService.get(
        "profiles",
        serial_number,
        "profile_combined"
      );
      const user = {
        serial_number: data?.content?.identity_info?.userId,
        email: data?.content?.identity_info?.content?.mail?.[0],
        full_name: data?.content?.identity_info?.content?.nameFull,
        job_title: data?.content?.identity_info?.content?.role,
        practice: data?.content?.identity_info?.content?.practiceAlignment,
        phone_number:
          data?.content?.identity_info?.content?.telephone?.mobile &&
          `+${data?.content?.identity_info?.content?.telephone?.mobile.replace(
            "-",
            ""
          )}`,
        manager_name:
          data?.content?.identity_info?.content?.functionalManager?.nameDisplay,
        manager_serial_number:
          data?.content?.identity_info?.content?.functionalManager?.uid,
        manager_email:
          data?.content?.identity_info?.content?.functionalManager
            ?.preferredIdentity,
        pronouns: data?.content?.bpPronoun,
        //Pulled during eduction so list would update each time enter form, and only store badges that are selected
        // badges:
        //   data?.content?.profile_extended?.content?.certifications?.badges.map(
        //     (badge) => {
        //       return {
        //         id: badge.badgeId,
        //         name: badge.badgeName,
        //         image: badge.badgeImageUrl,
        //       };
        //     }
        //   ),
        title: data?.content?.identity_info?.content?.courtesyTitle,
        slack_id: data?.content?.identity_info?.content?.preferredSlackId,
        profile_picture: "fallback.jpeg",
      };
      await APIService.post("v3/user", user);
      const userData = await APIService.get("v3/user_data", user.serial_number);
      return userData.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  async [DELETE_USER](context) {
    try {
      await APIService.update(
        "v3/delete_profile",
        AuthService.getSerialNumber()
      );
      context.commit(PURGE_AUTH);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_OTHER_USER](context, serialNum) {
    try {
      await APIService.delete("v3/users", serialNum);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_USER_PREFERENCES](context, id) {
    try {
      const { data } = await APIService.get("v3/user_preferences", id);
      context.commit(SET_USER_PREFERENCES, data.preferences);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_USER_PREFERENCES](context, data) {
    try {
      await APIService.post("v3/user_preferences", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_USER_PREFERENCES](context, payload) {
    try {
      await APIService.update("v3/user_preferences", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
};

const mutations = {
  async [SET_ONBOARD_DATA](onboardCandidate, newonboardData) {
    onboardCandidate.data = newonboardData;

    await APIService.update("v3/users", onboardCandidate.data.serial_number, {
      ...onboardCandidate.data,
    });
  },
  async [SET_JRSS_DATA](oldData, newData) {
    oldData.data = newData;
    await APIService.update("jrss", oldData.data.id, {
      ...newData,
    });
  },
  [SET_USER_DATA](state, user) {
    state.data = user;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.data = user;
    state.errors = {};
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.data = {};
    AuthService.destroyAccessToken();
    AuthService.destroyRefreshToken();
    AuthService.destroySerialNumber();
    AuthService.destroyAPItoken();
    AuthService.destroyApiTokenExpiryTime();
    AuthService.destroyAccessTokenExpiryTime();
    Cookies.remove("user");
  },
  [SET_PROFILE_PICTURE](state, picture) {
    state.profilePicture = picture;
  },
  [SET_USER_PREFERENCES](state, preferences) {
    state.userPreferences = preferences;
  },
};

export default {
  onboardCandidate,
  state,
  actions,
  mutations,
  getters,
};
