<template>
  <div class="detail-tile">
    <div class="detail-tile-container">
      <div class="detail-description-container">
        <p class="detail-title">Found a bug? Let us know</p>
        <p class="detail-description">
          Thank you for helping us to improve IBM SkillsMatch! Please give a
          detailed description of the bug, so that we know where to make the
          change. All responses are anonymous.
        </p>
      </div>
      <cv-loading :active="isBugUploading" :overlay="true" />
      <cv-modal ref="modal">
        <template slot="title">Bug report sent</template>
        <template slot="content">
          <p>
            Thank you for submitting your bug report. Your report has been
            submitted and will be addressed as soon as possible.
          </p>
        </template>
      </cv-modal>

      <div class="detail-input-container">
        <cv-interactive-tooltip direction="top">
          <template slot="label">Bug occured when: </template>
          <template slot="content">
            <p>
              Please select an option from below which best describes the bug.
            </p>
          </template>
        </cv-interactive-tooltip>
        <br />
        <cv-radio-group :vertical="true">
          <cv-radio-button
            v-for="location in bugLocationOptions"
            v-bind:key="location.value"
            name="group-1"
            v-bind:label="location.label"
            v-bind:value="location.value"
            v-model="bug.location"
          />
        </cv-radio-group>
        <cv-radio-group
          :vertical="true"
          v-if="isAdmin"
          class="additional-options"
        >
          <cv-radio-button
            v-for="location in bugLocationOptionsManager"
            v-bind:key="location.value"
            name="group-1"
            v-bind:label="location.label"
            v-bind:value="location.value"
            v-model="bug.location"
          />
        </cv-radio-group>
        <cv-radio-group :vertical="true" class="additional-options">
          <cv-radio-button
            v-for="location in bugLocationOptionsOther"
            v-bind:key="location.value"
            name="group-1"
            v-bind:label="location.label"
            v-bind:value="location.value"
            v-model="bug.location"
          />
        </cv-radio-group>
        <div class="error-message" v-if="$v.bug.location.$error">
          An option must be selected
        </div>
      </div>
      <div class="detail-input-container">
        <cv-interactive-tooltip direction="bottom">
          <template slot="label">Additional Details</template>
          <template slot="content">
            <p>
              Any information that may help us to fix this bug as quickly as
              possible.
            </p>
          </template>
        </cv-interactive-tooltip>
        <cv-text-area
          placeholder="e.g. What you were doing when the bug occured"
          v-model="bug.description"
          invalid-message
        ></cv-text-area>

        <div class="error-message" v-if="$v.bug.description.$error">
          Additional Details field is needed and can not exceed 500 characters
        </div>
        <br />
        <span
          >Characters Remaining:
          {{ bug.description ? 500 - bug.description.length : 500 }}
        </span>
      </div>
      <cv-interactive-tooltip direction="bottom">
        <template slot="label">Screenshot upload: </template>
        <template slot="content">
          <p>Please provide us with a screenshot of the error that occured.</p>
        </template>
      </cv-interactive-tooltip>
      <cv-file-uploader
        class="file-upload"
        ref="fileUploader"
        accept=".jpg,.png,.jpeg"
        kind="button"
        :clear-on-reselect="true"
        drop-target-label="Upload screenshot"
        :removable="true"
        @change="changeScreenshot"
      />
      <div class="error-message" v-if="fileSizeError">
        The file size should not exceed 2MB
      </div>
    </div>
    <div class="submit-button-container">
      <cv-button @click="goToLeaveFeedback" class="bx--btn" kind="secondary"
        >Back</cv-button
      >
      <cv-button @click="submit" class="bx--btn" kind="primary"
        >Submit</cv-button
      >
    </div>
  </div>
</template>
<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import { CREATE_BUG, UPLOAD_BUG_IMAGE } from "@/store/actions.type";

export default {
  name: "BugReport",
  data() {
    return {
      bug: {
        description: null,
        location: null,
        image: null,
        image_filename: null,
      },
      bugLocationOptions: [
        { label: "Logging in", value: "Logging_In" },
        { label: "Viewing my PenPic", value: "Viewing_PenPic" },
        { label: "Editing my personal details", value: "Editing_Personal" },
        { label: "Editing my summary", value: "Editing_Summary" },
        { label: "Editing my experience", value: "Editing_Experience" },
        { label: "Editing my additional details", value: "Editing_Additional" },
        { label: "Logging out", value: "Logging_Out" },
      ],
      bugLocationOptionsManager: [
        {
          label: "Searching for a candidate (Manager access only)",
          value: "Searching_admin",
        },
        {
          label: "Viewing a candidate's PenPic (Manager access only)",
          value: "Viewing_PenPic_admin",
        },
        {
          label: "Using the analytics page (Manager access only)",
          value: "Using_Analytics_admin",
        },
      ],
      bugLocationOptionsOther: [
        { label: "Other (Please Specify Below)", value: "Other" },
      ],
      isBugUploading: false,
      fileSizeError: false,
    };
  },
  computed: {
    ...mapGetters({
      checkRole: "userHasRole",
    }),
    isAdmin() {
      return !this.checkRole("Candidate");
    },
  },
  validations: {
    bug: {
      description: {
        required,
        maxLength: maxLength(500),
      },
      location: {
        required,
      },
    },
  },
  methods: {
    ...mapActions({
      createBug: CREATE_BUG,
      uploadBugImage: UPLOAD_BUG_IMAGE,
    }),
    changeScreenshot(file) {
      if (file && file[0]) {
        this.bug.image_filename = file[0].file.name;
        this.bug.file = file[0].file;
        this.fileSizeError = false;
        const imageFile = file[0]?.file;
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          this.bug.image = reader.result;
        });
        if (imageFile) {
          /** reject file if it's bigger than 2MB */
          if (imageFile.size > 2097152) {
            this.fileSizeError = true;
            this.$refs.fileUploader.internalFiles = [];
          } else {
            reader.readAsDataURL(imageFile);
          }
        } else {
          this.bug.image = null;
        }
      }
    },
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.isBugUploading = true;
          await this.createBug(this.bug);
          const formData = new FormData();
          formData.append("file", this.bug.file);
          await this.uploadBugImage(formData);
          /** clear out all values in the bug */
          Object.keys(this.bug).forEach((key) => (this.bug[key] = null));
          this.$v.$reset();
          this.isBugUploading = false;
          /** Remove file from uploader */
          this.$refs.fileUploader.remove();
          this.$refs.modal.show();
        } catch (error) {
          this.isBugUploading = false;
          throw new Error(error);
        }
      }
    },

    goToLeaveFeedback() {
      this.$router.push("/feedback");
    },
  },
};
</script>
<style scoped>
.file-upload {
  align-items: center;
  align-content: center;
}

.error-message {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.34;
  letter-spacing: 0.32px;
  margin: 0.25rem 0 0;
  overflow: hidden;
  color: #ff8389;
}

.submit-button-container {
  display: flex;
  justify-content: flex-start;
}

.bx--btn {
  max-width: 50%;
  min-width: 50%;
  text-align: left;
  font-size: 14px;
}

span {
  float: right;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.34;
  letter-spacing: 0.32px;
  display: inline-flex;
  align-items: center;
  color: #c6c6c6;
}

.additional-options {
  margin-top: 0.5rem;
}
</style>
