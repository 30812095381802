import Vue from "vue";
import Router from "vue-router";
import store from "../store/index";
import bug from "../views/general/reportABug/ReportABug.vue";
import suggestions from "../views/general/suggestions/Suggestions.vue";

Vue.use(Router);
const lazyLoad = (view) => {
  return () => import(`@/views/${view}.vue`);
};

export default new Router({
  routes: [
    {
      path: "/penpic",
      name: "PenPic",
      component: lazyLoad("candidates/PenPic/PenPic"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("UPPG")) {
          next();
        } else if (store.getters.currentUser.penpic_complete) {
          next();
        } else {
          next({ name: "Edit Details" });
        }
      },
    },
    {
      path: "/",
      name: "home",
      component: lazyLoad("general/homePage/homePage"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm(["AA", "URG", "OSCG"])) {
          next();
        } else if (!store.getters.currentUser.penpic_complete) {
          next({ name: "Edit Details" });
        } else {
          next();
        }
      },
    },
    {
      path: "/search",
      name: "search",
      component: lazyLoad("candidates/candidateFilter/CandidateFilter"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("UPPF")) {
          next();
        } else {
          next({ name: "home" });
        }
      },
    },
    {
      path: "/penpic/edit-details",
      name: "Edit Details",
      component: lazyLoad("candidates/editDetails/EditDetails"),
    },
    {
      path: "/feedback",
      name: "feedback",
      component: lazyLoad("general/feedback/Feedback"),
    },

    {
      path: "/suggestions",
      name: "suggestions",
      component: suggestions,
    },

    {
      path: "/bug",
      name: "bug",
      component: bug,
    },

    {
      path: "/login",
      name: "Login",
      component: lazyLoad("general/login/Login"),
      beforeEnter: (to, from, next) => {
        if (store.state.user.isAuthenticated) next({ name: "home" });
        else next();
      },
    },

    {
      path: "/insights",
      name: "Insights",
      component: lazyLoad("analytics/insights/Insights"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("ANL")) next();
        else next({ name: "PenPic" });
      },
    },

    {
      path: "/reporting",
      name: "Reporting",
      component: lazyLoad("analytics/reporting/Reporting"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("ANL")) next();
        else next({ name: "PenPic" });
      },
    },

    {
      path: "/boarding",
      name: "Boarding",
      component: lazyLoad("boarding/CandidateTrackerView"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("ANL")) next();
        else next({ name: "PenPic" });
      },
    },

    {
      path: "/engagements/open-seats",
      name: "Open Seats",
      component: lazyLoad("resourceManagement/OpenSeats/OpenSeats"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("OSF")) next();
        else next({ name: "home" });
      },
    },
    {
      path: "/engagements/projects",
      name: "Projects",
      component: lazyLoad("resourceManagement/Projects/Projects"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("URF")) next();
        else next({ name: "home" });
      },
    },
    {
      path: "/resourcing/tsc",
      name: "TSC",
      component: lazyLoad("resourceManagement/Tsc/TSC"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("TSCF")) next();
        else next({ name: "home" });
      },
    },
    {
      path: "/engagements/candidates",
      name: "Candidates",
      component: lazyLoad("resourceManagement/Candidates/Candidates"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("OSF")) next();
        else next({ name: "home" });
      },
    },
    {
      path: "/resourcing",
      name: "Resourcing",
      component: lazyLoad("resourceManagement/RMLandingPage"),
    },
    {
      path: "/engagements",
      name: "Engagements",
      component: lazyLoad("resourceManagement/EngagementsLandingPage"),
    },
    {
      path: "/resourcing/resources",
      name: "Resources",
      component: lazyLoad("resourceManagement/Resources/Resources"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("URF")) next();
        else next({ name: "home" });
      },
    },
    {
      path: "/admin/bugs",
      name: "Bugs",
      component: lazyLoad("adminControls/Bugs"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("AA")) next();
        else next({ name: "home" });
      },
    },
    {
      path: "/admin/feedback",
      name: "Feedback",
      component: lazyLoad("adminControls/Feedback"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("AA")) next();
        else next({ name: "home" });
      },
    },
    {
      path: "/admin/manage",
      name: "Manage",
      component: lazyLoad("adminControls/dataTables/Manage"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("AA")) next();
        else next({ name: "home" });
      },
    },
    // {
    //   path: "/tasks",
    //   name: "Tasks",
    //   component: lazyLoad("tasks/TaskView"),
    // },
    {
      path: "/admin-controls",
      name: "AdminControls",
      component: lazyLoad("adminControls/AdminControls"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("AA")) next();
        else next({ name: "home" });
      },
    },
    // {
    //   path: "/insights/map",
    //   name: "Map",
    //   component: lazyLoad("analytics/analyticsTabs/Map"),
    //   beforeEnter: (to, from, next) => {
    //     if (store.getters.userHasPerm("ANL")) next();
    //     else next({ name: "home" });
    //   },
    // },
    {
      path: "*",
      component: lazyLoad("general/notFound/NotFound"),
    },
    {
      path: "/system_settings/resource_management_configuration",
      name: "Configuration",
      component: lazyLoad("adminControls/Settings"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("AA")) next();
        else next({ name: "home" });
      },
    },

    {
      path: "/system_settings/advanced",
      name: "AdvancedSettings",
      component: lazyLoad("adminControls/Settings"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("AA")) next();
        else next({ name: "home" });
      },
    },
    {
      path: "/system_settings/application_configuration",
      name: "AppConfig",
      component: lazyLoad("adminControls/Settings"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("AA")) next();
        else next({ name: "home" });
      },
    },
    {
      path: "/system_settings/user_role_management",
      name: "UserRoleManagement",
      component: lazyLoad("adminControls/Settings"),
      beforeEnter: (to, from, next) => {
        if (store.getters.userHasPerm("AA")) next();
        else next({ name: "home" });
      },
    },
  ],
});

// To-do Top Trump routes if/when TopTrumps are re-investigated
// {
//   path: "/search?toptrump=true",
//   name: "toptrumpsearch",
//   beforeEnter: (to, from, next) => {
//     if (store.getters.userHasPerm("TTUF")) {
//       next();
//     } else {
//       next({ name: "PenPic" });
//     }
//   },
// },
// {
//   path: "/toptrump",
//   name: "TopTrump",
//   component: lazyLoad("candidates/topTrump/TopTrump"),
//   beforeEnter: (to, from, next) => {
//     if (store.getters.userHasPerm("TTUG")) {
//       next();
//     } else if (store.getters.currentUser.penpic_complete) {
//       next();
//     } else next({ name: "PenPic" });
//   },
// },
