import { APIService } from "@/common/api.service";
import {
  GET_PERSON_ACCOUNT_CHART,
  GET_PERSON_AVAILABILITY_CHART,
  GET_PERSON_BAND_CHART,
  GET_PERSON_BAND_GROUP_CHART,
  GET_PERSON_JRSS_CHART,
  GET_PERSON_GROWTH_CHART,
  GET_TECH_SKILL_OVERVIEW_CHART,
  GET_TECH_SKILL_EXPERIENCE_CHART,
  GET_PROF_SKILL_OVERVIEW_CHART,
  GET_PROF_SKILL_EXPERIENCE_CHART,
  GET_BAND_BY_ACCOUNT_CHART,
  GET_PERSONS_OVER_TIME_BY_ACCOUNT_CHART,
  GET_OPEN_SEAT_OVER_TIME_CHART,
  GET_OPEN_SEAT_BY_ACCOUNT_CHART,
  GET_OPEN_SEAT_BY_JRSS_CHART,
  GET_OPEN_SEAT_BY_MONTH_CHART,
  GET_OPEN_SEAT_BY_PROJECT_CHART,
  GET_PERSON_PRACTICE_CHART,
  GET_RESOURCE_BOARDING_BY_GROUP,
} from "./actions.type";
import {
  SET_PERSON_ACCOUNT_CHART,
  SET_PERSON_AVAILABILITY_CHART,
  SET_PERSON_BAND_CHART,
  SET_PERSON_BAND_GROUP_CHART,
  SET_PERSON_JRSS_CHART,
  SET_PERSON_GROWTH_CHART,
  SET_TECH_SKILL_OVERVIEW_CHART,
  SET_TECH_SKILL_EXPERIENCE_CHART,
  SET_PROF_SKILL_OVERVIEW_CHART,
  SET_PROF_SKILL_EXPERIENCE_CHART,
  SET_BAND_BY_ACCOUNT_CHART,
  SET_PERSONS_OVER_TIME_BY_ACCOUNT_CHART,
  SET_OPEN_SEAT_OVER_TIME_CHART,
  SET_OPEN_SEAT_BY_ACCOUNT_CHART,
  SET_OPEN_SEAT_BY_JRSS_CHART,
  SET_OPEN_SEAT_BY_MONTH_CHART,
  SET_OPEN_SEAT_BY_PROJECT_CHART,
  SET_PERSON_PRACTICE_CHART,
  SET_CLUSTER_FILTER,
  SET_OPEN_SEAT_FILTER,
  SET_RESOURCE_BOARDING_BY_GROUP,
} from "./mutations.type";

const state = {
  personAccountChart: [],
  personAvailabilityChart: [],
  personBandChart: [],
  personBandGroupChart: [],
  personJrssChart: [],
  personGrowthChart: [],
  techSkillsOverviewChart: [],
  techSkillsExperienceChart: [],
  profSkillsOverviewChart: [],
  profSkillsExperienceChart: [],
  bandsByAccountChart: [],
  personsOverTimeByAccountChart: [],
  openSeatsOverTimeChart: [],
  openSeatsByAccountChart: [],
  openSeatsByJrssChart: [],
  openSeatsByMonthChart: [],
  openSeatsByProjectChart: [],
  personPracticeChart: [],
  resourceBoardingCount: [],

  clusterFilter: [],
  openSeatsFilter: [],
};
const getters = {
  personAccountChart(state) {
    return state.personAccountChart;
  },
  personAvailabilityChart(state) {
    return state.personAvailabilityChart;
  },
  personBandChart(state) {
    return state.personBandChart;
  },
  personBandGroupChart(state) {
    return state.personBandGroupChart;
  },
  personJrssChart(state) {
    return state.personJrssChart;
  },
  personGrowthChart(state) {
    return state.personGrowthChart;
  },
  techSkillsOverviewChart(state) {
    return state.techSkillsOverviewChart;
  },
  profSkillsOverviewChart(state) {
    return state.profSkillsOverviewChart;
  },
  techSkillsExperienceChart(state) {
    return state.techSkillsExperienceChart;
  },
  profSkillsExperienceChart(state) {
    return state.profSkillsExperienceChart;
  },
  bandsByAccountChart(state) {
    return state.bandsByAccountChart;
  },
  personsOverTimeByAccountChart(state) {
    return state.personsOverTimeByAccountChart;
  },
  openSeatsOverTimeChart(state) {
    return state.openSeatsOverTimeChart;
  },
  openSeatsByAccountChart(state) {
    return state.openSeatsByAccountChart;
  },
  openSeatsByJrssChart(state) {
    return state.openSeatsByJrssChart;
  },
  openSeatsByMonthChart(state) {
    return state.openSeatsByMonthChart;
  },
  openSeatsByProjectChart(state) {
    return state.openSeatsByProjectChart;
  },
  personPracticeChart(state) {
    return state.personPracticeChart;
  },
  openSeatsFilter(state) {
    return state.openSeatsFilter;
  },
  clusterFilter(state) {
    return state.clusterFilter;
  },
  resourceBoardingCount(state) {
    return state.resourceBoardingCount;
  },
};

const actions = {
  async [GET_PERSON_ACCOUNT_CHART](context, filter) {
    try {
      const { data } = await APIService.query(
        "analytics/cluster-insight/person-account-piechart",
        filter
      );

      context.commit(SET_PERSON_ACCOUNT_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PERSON_AVAILABILITY_CHART](context, filter) {
    try {
      const { data } = await APIService.query(
        "analytics/cluster-insight/person-availability-piechart",
        filter
      );

      context.commit(SET_PERSON_AVAILABILITY_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PERSON_BAND_CHART](context, filter) {
    try {
      const { data } = await APIService.query(
        "analytics/cluster-insight/person-band-piechart",
        filter
      );

      context.commit(SET_PERSON_BAND_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PERSON_BAND_GROUP_CHART](context, filter) {
    try {
      const { data } = await APIService.query(
        "analytics/cluster-insight/person-bandgroup-piechart",
        filter
      );

      context.commit(SET_PERSON_BAND_GROUP_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PERSON_JRSS_CHART](context, filter) {
    try {
      const { data } = await APIService.query(
        "analytics/cluster-insight/person-jrss-piechart",
        filter
      );

      context.commit(SET_PERSON_JRSS_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PERSON_GROWTH_CHART](context, filter) {
    try {
      const { data } = await APIService.query(
        "/analytics/cluster-insight/person-growth-bargraph",
        filter
      );
      context.commit(SET_PERSON_GROWTH_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PERSON_PRACTICE_CHART](context, filter) {
    try {
      const { data } = await APIService.query(
        "/analytics/cluster-insight/person-practice-bargraph",
        filter
      );
      context.commit(SET_PERSON_PRACTICE_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TECH_SKILL_EXPERIENCE_CHART](context, params) {
    try {
      const { data } = await APIService.query(
        "/analytics/skills-overview/person-technicalskills-expereince-bargraph",
        params
      );
      context.commit(SET_TECH_SKILL_EXPERIENCE_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TECH_SKILL_OVERVIEW_CHART](context, jrsParam) {
    try {
      const { data } = await APIService.query(
        "/analytics/skills-overview/person-technicalskills-piechart",
        jrsParam
      );
      context.commit(SET_TECH_SKILL_OVERVIEW_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PROF_SKILL_EXPERIENCE_CHART](context, jrsParam) {
    try {
      const { data } = await APIService.query(
        "/analytics/skills-overview/person-professionalskills-expereince-bargraph",
        jrsParam
      );
      context.commit(SET_PROF_SKILL_EXPERIENCE_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PROF_SKILL_OVERVIEW_CHART](context, jrsParam) {
    try {
      const { data } = await APIService.query(
        "/analytics/skills-overview/person-professionalskills-piechart",
        jrsParam
      );
      context.commit(SET_PROF_SKILL_OVERVIEW_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_BAND_BY_ACCOUNT_CHART](context, accountParam) {
    try {
      const { data } = await APIService.query(
        "/analytics/account-insight/bands-account-bargraph",
        accountParam
      );
      context.commit(SET_BAND_BY_ACCOUNT_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PERSONS_OVER_TIME_BY_ACCOUNT_CHART](context, filter) {
    try {
      const { data } = await APIService.query(
        "/analytics/account-insight/person-over-time-line-graph",
        filter
      );
      context.commit(SET_PERSONS_OVER_TIME_BY_ACCOUNT_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [GET_OPEN_SEAT_OVER_TIME_CHART](context, accountParam) {
    try {
      const { data } = await APIService.query(
        "/analytics/open-seats/over-time-linegraph",
        accountParam
      );
      context.commit(SET_OPEN_SEAT_OVER_TIME_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_OPEN_SEAT_BY_ACCOUNT_CHART](context, accountParam) {
    try {
      const { data } = await APIService.query(
        "/analytics/open-seats/by-account-piechart",
        accountParam
      );
      context.commit(SET_OPEN_SEAT_BY_ACCOUNT_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_OPEN_SEAT_BY_JRSS_CHART](context, accountParam) {
    try {
      const { data } = await APIService.query(
        "/analytics/open-seats/remaining-seats-jrss-piechart",
        accountParam
      );
      context.commit(SET_OPEN_SEAT_BY_JRSS_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_OPEN_SEAT_BY_MONTH_CHART](context, accountParam) {
    try {
      const { data } = await APIService.query(
        "/analytics/open-seats/month-piechart",
        accountParam
      );
      context.commit(SET_OPEN_SEAT_BY_MONTH_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_OPEN_SEAT_BY_PROJECT_CHART](context, accountParam) {
    try {
      const { data } = await APIService.query(
        "/analytics/open-seats/by-project-bargraph",
        accountParam
      );
      context.commit(SET_OPEN_SEAT_BY_PROJECT_CHART, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_RESOURCE_BOARDING_BY_GROUP](context, accountParam) {
    try {
      const { data } = await APIService.query("/v3/resource_group_insights");
      context.commit(SET_RESOURCE_BOARDING_BY_GROUP, data);
    } catch (error) {
      throw new Error(error);
    }
  },
};

const mutations = {
  [SET_PERSON_ACCOUNT_CHART](state, personAccountChart) {
    state.personAccountChart = personAccountChart;
  },
  [SET_PERSON_AVAILABILITY_CHART](state, personAvailabilityChart) {
    state.personAvailabilityChart = personAvailabilityChart;
  },
  [SET_PERSON_BAND_CHART](state, personBandChart) {
    state.personBandChart = personBandChart;
  },
  [SET_PERSON_BAND_GROUP_CHART](state, personBandGroupChart) {
    state.personBandGroupChart = personBandGroupChart;
  },
  [SET_PERSON_JRSS_CHART](state, personJrssChart) {
    state.personJrssChart = personJrssChart;
  },
  [SET_PERSON_GROWTH_CHART](state, personGrowthChart) {
    state.personGrowthChart = personGrowthChart;
  },
  [SET_PERSON_PRACTICE_CHART](state, personPracticeChart) {
    state.personPracticeChart = personPracticeChart;
  },
  [SET_TECH_SKILL_EXPERIENCE_CHART](state, techSkillsExperienceChart) {
    state.techSkillsExperienceChart = techSkillsExperienceChart;
  },
  [SET_TECH_SKILL_OVERVIEW_CHART](state, techSkillsOverviewChart) {
    state.techSkillsOverviewChart = techSkillsOverviewChart;
  },
  [SET_PROF_SKILL_EXPERIENCE_CHART](state, profSkillsExperienceChart) {
    state.profSkillsExperienceChart = profSkillsExperienceChart;
  },
  [SET_PROF_SKILL_OVERVIEW_CHART](state, profSkillsOverviewChart) {
    state.profSkillsOverviewChart = profSkillsOverviewChart;
  },
  [SET_BAND_BY_ACCOUNT_CHART](state, data) {
    state.bandsByAccountChart = data;
  },
  [SET_PERSONS_OVER_TIME_BY_ACCOUNT_CHART](
    state,
    personsOverTimeByAccountChart
  ) {
    state.personsOverTimeByAccountChart = personsOverTimeByAccountChart;
  },
  [SET_OPEN_SEAT_OVER_TIME_CHART](state, openSeatsOverTimeChart) {
    state.openSeatsOverTimeChart = openSeatsOverTimeChart;
  },
  [SET_OPEN_SEAT_BY_ACCOUNT_CHART](state, openSeatsByAccountChart) {
    state.openSeatsByAccountChart = openSeatsByAccountChart;
  },
  [SET_OPEN_SEAT_BY_JRSS_CHART](state, openSeatsByJrssChart) {
    state.openSeatsByJrssChart = openSeatsByJrssChart;
  },
  [SET_OPEN_SEAT_BY_MONTH_CHART](state, openSeatsByMonthChart) {
    state.openSeatsByMonthChart = openSeatsByMonthChart;
  },
  [SET_OPEN_SEAT_BY_PROJECT_CHART](state, openSeatsByProjectChart) {
    state.openSeatsByProjectChart = openSeatsByProjectChart;
  },
  [SET_OPEN_SEAT_FILTER](state, data) {
    state.openSeatsFilter = data;
  },
  [SET_CLUSTER_FILTER](state, data) {
    state.clusterFilter = data;
  },
  [SET_RESOURCE_BOARDING_BY_GROUP](state, data) {
    state.resourceBoardingCount = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
