<template>
  <div class="form-container">
    <cv-modal ref="modal" id="add-feedback">
      <template slot="title">Feedback sent</template>
      <template slot="content">
        <p>
          Thank you for providing us with feedback. We will use it to improve
          SkillsMatch.
        </p>
      </template>
      <template slot="primary-button">Close</template>
    </cv-modal>

    <div class="detail-tile">
      <div class="detail-tile-container">
        <div class="detail-description-container">
          <p class="detail-title">Leave a suggestion</p>
          <p class="detail-description">
            Thank you for helping us to improve IBM SkillsMatch! Please leave a
            suggestions in a form below, so that we know where to improve. All
            responses are anonymous.
          </p>
        </div>
        <div class="detail-input-container">
          <cv-interactive-tooltip direction="bottom">
            <template slot="label">What we are doing well</template>
            <template slot="content">
              <p>Let us know what you like about SkillsMatch</p>
            </template>
          </cv-interactive-tooltip>
          <cv-text-area
            placeholder="e.g. I really like the design of the application."
            v-model="suggestion.positives"
            ><template
              v-if="
                !$v.suggestion.positives.maxLength &&
                $v.suggestion.positives.$error
              "
              slot="invalid-message"
            >
              Feedback can't exceed 500 characters
            </template></cv-text-area
          >
          <br />
          <span
            >Characters Remaining:
            {{ suggestion.positives ? 500 - suggestion.positives.length : 500 }}
          </span>
        </div>

        <div class="detail-input-container">
          <cv-interactive-tooltip direction="bottom">
            <template slot="label">Suggestions</template>
            <template slot="content">
              <p>
                Any information that you believe will help to improve
                SkillsMatch.
              </p>
            </template>
          </cv-interactive-tooltip>
          <cv-text-area
            placeholder="e.g. X could provide Y functionality."
            v-model="suggestion.improvements"
            ><template
              v-if="
                !$v.suggestion.improvements.required &&
                $v.suggestion.improvements.$error
              "
              slot="invalid-message"
            >
              Please leave a suggestion </template
            ><template
              v-if="
                !$v.suggestion.improvements.maxLength &&
                $v.suggestion.improvements.$error
              "
              slot="invalid-message"
            >
              The suggestion can't exceed 500 characters
            </template></cv-text-area
          >
          <br />
          <span
            >Characters Remaining:
            {{
              suggestion.improvements
                ? 500 - suggestion.improvements.length
                : 500
            }}
          </span>
        </div>

        <div class="detail-input-container">
          <cv-interactive-tooltip direction="bottom">
            <template slot="label">Rating</template>
            <template slot="content">
              <p>On scale from 1 to 5, how would you rate SkillsMatch?</p>
            </template>
          </cv-interactive-tooltip>
          <!-- Change values to integer -->
          <cv-radio-group class="rating-container">
            <cv-radio-button
              name="group-1"
              label="1"
              value="1"
              v-model="suggestion.rating"
            />
            <cv-radio-button
              name="group-1"
              label="2"
              value="2"
              v-model="suggestion.rating"
            />
            <cv-radio-button
              name="group-1"
              label="3"
              value="3"
              v-model="suggestion.rating"
            />
            <cv-radio-button
              name="group-1"
              label="4"
              value="4"
              v-model="suggestion.rating"
            />
            <cv-radio-button
              name="group-1"
              label="5"
              value="5"
              v-model="suggestion.rating"
            />
          </cv-radio-group>
          <div class="error-message" v-if="$v.suggestion.rating.$error">
            A rating must be selected
          </div>
        </div>
      </div>
      <div class="submit-button-container">
        <cv-button @click="goToLeaveFeedback" class="bx--btn" kind="secondary"
          >Back</cv-button
        >
        <cv-button @click="submit" class="bx--btn" kind="primary"
          >Submit</cv-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required, maxLength } from "vuelidate/lib/validators";
import { CREATE_FEEDBACK } from "@/store/actions.type";
export default {
  name: "Suggestion",
  data() {
    return {
      suggestion: {
        rating: null,
        positives: null,
        improvements: null,
      },
      isSuggestionUploading: false,
      fileSizeError: false,
    };
  },
  validations: {
    suggestion: {
      improvements: {
        required,
        maxLength: maxLength(500),
      },
      rating: {
        required,
      },
      positives: {
        maxLength: maxLength(500),
      },
    },
  },

  methods: {
    ...mapActions({
      createFeedback: CREATE_FEEDBACK,
    }),
    goToLeaveFeedback() {
      this.$router.push("/feedback");
    },
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.isSuggestionUploading = true;
          await this.createFeedback(this.suggestion);
          // clear out all values in the suggestion
          Object.keys(this.suggestion).forEach(
            (key) => (this.suggestion[key] = null)
          );
          this.$v.$reset();
          this.isSuggestionUploading = false;
          // Remove file from uploader
          this.$refs.modal.show();
        } catch (error) {
          this.isSuggestionUploading = false;
          throw new Error(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-button-container {
  display: flex;
  width: 100%;
}

.bx--btn {
  max-width: 50%;
  min-width: 50%;
  text-align: left;
  font-size: 14px;
  padding: 1rem;
}

.rating-container {
  padding-top: 0.5rem;
}

.error-message {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.34;
  letter-spacing: 0.32px;
  margin: 0.25rem 0 0;
  overflow: hidden;
  color: var(--cds-danger-02);
}
span {
  float: right;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.34;
  letter-spacing: 0.32px;
  display: inline-flex;
  align-items: center;
  color: var(--cds-text-02);
}
</style>
