import { APIService } from "@/common/api.service";
import { dropdownOptions } from "./functions.module";
import {
  GET_TASKLIST_TEMPLATE,
  GET_TASKLIST_TEMPLATE_DETAIL,
  UPDATE_TASKLIST_TEMPLATE,
  CREATE_TASKLIST_TEMPLATE,
  DELETE_TASKLIST_TEMPLATE,
  GET_TASK_TEMPLATE,
  GET_TASK_TEMPLATE_DETAIL,
  GET_TASK_TEMPLATE_BY_TASKLIST,
  UPDATE_TASK_TEMPLATE,
  CREATE_TASK_TEMPLATE,
  DELETE_TASK_TEMPLATE,
  GET_TASK,
  GET_TASK_DETAIL,
  GET_USER_TASKS,
  UPDATE_USER_TASKS,
  CREATE_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  GET_TASK_TYPE,
  GET_TASK_TYPE_DETAIL,
  UPDATE_TASK_TYPE,
  CREATE_TASK_TYPE,
  DELETE_TASK_TYPE,
  GET_TASKLIST_STATUS,
  GET_TASKLIST_STATUS_DETAIL,
  UPDATE_TASKLIST_STATUS,
  CREATE_TASKLIST_STATUS,
  DELETE_TASKLIST_STATUS,
  GET_TASK_STATUS,
  GET_TASK_STATUS_DETAIL,
  UPDATE_TASK_STATUS,
  CREATE_TASK_STATUS,
  DELETE_TASK_STATUS,
  GET_TASKLIST,
  GET_TASKLIST_DETAIL,
  GET_USER_TASKLIST,
  UPDATE_TASKLIST,
  CREATE_TASKLIST,
  DELETE_TASKLIST,
} from "./actions.type";
import {
  SET_CREATED_TASKLIST,
  SET_TASKLIST_TEMPLATE,
  SET_TASKLIST_TEMPLATE_DETAIL,
  SET_TASK_TEMPLATE,
  SET_TASK_TEMPLATE_DETAIL,
  SET_TASK_TEMPLATE_BY_TASKLIST,
  SET_TASK,
  SET_TASK_DETAIL,
  SET_TASK_TYPE,
  SET_TASK_TYPE_DETAIL,
  SET_TASKLIST_STATUS,
  SET_TASKLIST_STATUS_DETAIL,
  SET_TASK_STATUS,
  SET_TASK_STATUS_DETAIL,
  SET_TASKLIST,
  SET_TASKLIST_DETAIL,
  SET_USER_TASKLIST,
  SET_USER_TASKS,
} from "./mutations.type";

const actions = {
  async [GET_TASKLIST_TEMPLATE](context, attributes) {
    try {
      const { data } = await APIService.query(
        "v3/tasklist_template",
        attributes
      );
      context.commit(SET_TASKLIST_TEMPLATE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TASKLIST_TEMPLATE_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("v3/tasklist_template", id);
      context.commit(SET_TASKLIST_TEMPLATE_DETAIL, data.tasklist_template);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_TASKLIST_TEMPLATE](context, payload) {
    try {
      await APIService.update("v3/tasklist_template", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_TASKLIST_TEMPLATE](context, data) {
    try {
      await APIService.post("v3/tasklist_template", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_TASKLIST_TEMPLATE](context, id) {
    try {
      await APIService.delete("v3/tasklist_template", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TASK_TEMPLATE](context, attributes) {
    try {
      const { data } = await APIService.query("v3/task_template", attributes);
      context.commit(SET_TASK_TEMPLATE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TASK_TEMPLATE_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("v3/task_template", id);
      context.commit(SET_TASK_TEMPLATE_DETAIL, data.task_template);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TASK_TEMPLATE_BY_TASKLIST](context, id) {
    try {
      const { data } = await APIService.get("v3/task_template_detail", id);
      context.commit(SET_TASK_TEMPLATE_BY_TASKLIST, data.task_template);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_TASK_TEMPLATE](context, payload) {
    try {
      await APIService.update("v3/task_template", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_TASK_TEMPLATE](context, data) {
    try {
      const response = await APIService.post("v3/task_template", data);
      return response.data.task_template;
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_TASK_TEMPLATE](context, id) {
    try {
      await APIService.delete("v3/task_template", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TASK](context, attributes) {
    try {
      const { data } = await APIService.query("v3/task", attributes);
      context.commit(SET_TASK, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TASK_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("v3/task", id);
      context.commit(SET_TASK_DETAIL, data.task);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_USER_TASKS](context, params) {
    try {
      // params should be an array -> [user_id, tasklist_template_id]
      const { data } = await APIService.get_task("v3/user_tasks", params);
      context.commit(SET_USER_TASKS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_USER_TASKS](context, payload) {
    try {
      await APIService.update("v3/user_tasks", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_TASK](context, payload) {
    try {
      await APIService.update("v3/task", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_TASK](context, payload) {
    try {
      await APIService.post("v3/task", payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_TASK](context, id) {
    try {
      await APIService.delete("v3/task", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TASK_TYPE](context, attributes) {
    try {
      const { data } = await APIService.query("v3/task_type", attributes);
      context.commit(SET_TASK_TYPE, data.rows);
    } catch (error) {
      throw new Error();
    }
  },
  async [GET_TASK_TYPE_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("v3/task_type", id);
      context.commit(SET_TASK_TYPE_DETAIL, data.task_type);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_TASK_TYPE](context, payload) {
    try {
      await APIService.update("v3/task_type", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_TASK_TYPE](context, payload) {
    try {
      await APIService.post("v3/task_type", payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_TASK_TYPE](context, id) {
    try {
      await APIService.delete("v3/task_type", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TASKLIST_STATUS](context, attributes) {
    try {
      const { data } = await APIService.query("v3/tasklist_status", attributes);
      context.commit(SET_TASKLIST_STATUS, data.rows);
    } catch (error) {
      throw new Error();
    }
  },
  async [GET_TASKLIST_STATUS_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("v3/tasklist_status", id);
      context.commit(SET_TASKLIST_STATUS_DETAIL, data.tasklist_status);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_TASKLIST_STATUS](context, payload) {
    try {
      await APIService.update("v3/tasklist_status", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_TASKLIST_STATUS](context, payload) {
    try {
      await APIService.post("v3/tasklist_status", payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_TASKLIST_STATUS](context, id) {
    try {
      await APIService.delete("v3/tasklist_status", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TASK_STATUS](context, attributes) {
    try {
      const { data } = await APIService.query("v3/task_status", attributes);
      context.commit(SET_TASK_STATUS, data.rows);
    } catch (error) {
      throw new Error();
    }
  },
  async [GET_TASK_STATUS_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("v3/task_status", id);
      context.commit(SET_TASK_STATUS_DETAIL, data.task_status);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_TASK_STATUS](context, payload) {
    try {
      await APIService.update("v3/task_status", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_TASK_STATUS](context, payload) {
    try {
      await APIService.post("v3/task_status", payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_TASK_STATUS](context, id) {
    try {
      await APIService.delete("v3/task_status", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TASKLIST](context, attributes) {
    try {
      const { data } = await APIService.query("v3/tasklist", attributes);
      context.commit(SET_TASKLIST, data.rows);
    } catch (error) {
      throw new Error();
    }
  },
  async [GET_TASKLIST_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("v3/tasklist", id);
      context.commit(SET_TASKLIST_DETAIL, data.tasklist);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_USER_TASKLIST](context, id) {
    try {
      const { data } = await APIService.get("v3/user_tasklist", id);
      context.commit(SET_TASKLIST_DETAIL, data.user_tasklist);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_TASKLIST](context, payload) {
    try {
      await APIService.update("v3/tasklist", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_TASKLIST](context, payload) {
    try {
      const { data } = await APIService.post("v3/tasklist", payload);
      context.commit(SET_CREATED_TASKLIST, data.tasklist);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_TASKLIST](context, id) {
    try {
      await APIService.delete("v3/task_status", id);
    } catch (error) {
      throw new Error(error);
    }
  },
};

const state = {
  created_tasklist: {},
  tasklist_template: [],
  tasklist_template_detail: [],
  task_template: {},
  task_template_detail: [],
  tasks_by_tasklist: [],
  task: [],
  task_detail: {},
  task_status: [],
  task_type: [],
  task_type_detail: {},
  tasklist_status: [],
  tasklist_status_detail: {},
  tasklist: [],
  tasklist_detail: {},
  user_tasklist: [],
  user_tasks: [],
};

const mutations = {
  [SET_CREATED_TASKLIST](state, data) {
    state.created_tasklist = data;
  },
  [SET_TASKLIST_TEMPLATE](state, data) {
    state.tasklist_template = data;
  },
  [SET_TASKLIST_TEMPLATE_DETAIL](state, data) {
    state.tasklist_template_detail = data;
  },
  [SET_TASK_TEMPLATE](state, data) {
    state.task_template = data;
  },
  [SET_TASK_TEMPLATE_DETAIL](state, data) {
    state.task_template_detail = data;
  },
  [SET_TASK_TEMPLATE_BY_TASKLIST](state, data) {
    state.tasks_by_tasklist = data;
  },
  [SET_TASK](state, data) {
    state.task = data;
  },
  [SET_TASK_DETAIL](state, data) {
    state.task_detail = data;
  },
  [SET_TASK_TYPE](state, data) {
    state.task_type = data;
  },
  [SET_TASK_STATUS](state, data) {
    state.task_status = data;
  },
  [SET_TASK_TYPE_DETAIL](state, data) {
    state.task_type_detail = data;
  },
  [SET_TASKLIST_STATUS](state, data) {
    state.tasklist_status = data;
  },
  [SET_TASKLIST_STATUS_DETAIL](state, data) {
    state.tasklist_status_detail = data;
  },
  [SET_TASKLIST](state, data) {
    state.tasklist = data;
  },
  [SET_TASKLIST_DETAIL](state, data) {
    state.tasklist_detail = data;
  },
  [SET_USER_TASKLIST](state, data) {
    state.user_tasklist = data;
  },
  [SET_USER_TASKS](state, data) {
    return (state.user_tasks = data);
  },
};

const getters = {
  created_tasklist(state) {
    return state.created_tasklist;
  },
  tasklist_template(state) {
    return dropdownOptions(state.tasklist_template);
  },
  tasklist_template_detail(state) {
    return state.tasklist_template_detail;
  },
  task_template(state) {
    return state.task_template;
  },
  task_template_detail(state) {
    return state.task_template_detail;
  },
  tasks_by_tasklist(state) {
    return state.tasks_by_tasklist;
  },
  task(state) {
    return state.task;
  },
  task_detail(state) {
    return state.task_detail;
  },
  task_type(state) {
    return state.task_type;
  },
  task_status(state) {
    return state.task_status;
  },
  task_type_detail(state) {
    return state.task_type_detail;
  },
  tasklist_status(state) {
    return state.tasklist_status;
  },
  tasklist_status_detail(state) {
    return state.tasklist_status_detail;
  },
  tasklist(state) {
    return state.tasklist;
  },
  tasklist_detail(state) {
    return state.tasklist_detail;
  },
  user_tasklist(state) {
    return state.user_tasklist;
  },
  user_tasks(state) {
    return state.user_tasks;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
