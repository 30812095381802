function dropdownOptions(p) {
  return p
    .map((option) => {
      return {
        id: String(option.id),
        value: String(option.id),
        name: String(option.name),
        label: String(option.name),
        code: option.code ? String(option.code) : "",
      };
    })
    .sort((previous, current) => {
      if (previous.name > current.name) {
        return 1;
      } else {
        return -1;
      }
    });
}

// input -> 2022-05-15T20:00:00.000Z
// output -> Sun 15 May 2022
function generateDate(date) {
  if (!date) {
    return;
  }
  return new Date(date).toUTCString().replace(",", "").substring(0, 15);
}

// Downloads a capture by creating a virtual link with specified file name and data URL.
function downloadCapture(fileName, dataURL) {
  /*
  Downloads a capture by creating a virtual link with specified file name and data URL.
  @params {string} - The desired file name
  @params{string} - The dataURL
  */
  // Split the data URL to extract the MIME type and the actual data
  const hrefSplit = dataURL.split(",");
  // Create a virtual link element
  const virtualLink = document.createElement("a");
  // Set the download attribute of the link to the encoded file name
  virtualLink.setAttribute("download", encodeURIComponent(fileName));
  // Set the href attribute of the link to the modified data URL
  virtualLink.setAttribute(
    "href",
    hrefSplit[0] + "," + encodeURIComponent(hrefSplit[1])
  );
  // Simulate a click event on the virtual link to trigger the file download
  virtualLink.click();
}

/*
  Downloads a Workbook onto excel desired information passed through sheets
  @params {array[objects]} -  sheets
  @params {string} - fileName
  */
function downloadToExcel(fileName, sheets) {
  const workbook = XLSX.utils.book_new();

  for (const sheet of sheets) {
    let content = XLSX.utils.json_to_sheet(sheet.content);
    XLSX.utils.book_append_sheet(workbook, content, sheet.name);
  }

  // Generate the Excel file
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  // Convert the buffer to a Blob
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  // gets the date in DDD-dd-mm-yyyy format (e.g. Fri 20 Feb 2004)
  const today = new Date().toUTCString().replace(",", "").substring(0, 15);
  fileName = `${fileName} ${today}.xlsx`;
  // Save the Blob as an Excel file
  saveAs(blob, fileName);
}

// checks value of data passed through so that it isn't blank or makes an error in the worksheet
function checkValue(value, type) {
  if (type === "date" && value) {
    // if value isn't null and it's a date
    return generateDate(value);
  }
  if (type === "bool") {
    // if value is true and is a Boolean
    if (value) {
      return "True";
    }
    // if value is false (but isn't null) and is a Boolean
    else if (value === false) {
      return "False";
    }
  }
  // if value is null
  return value ? value : "N/A";
}

/*
  Called from RMPage, maps each piece of information into standard english, as well as removing any information not wanted in excel spreadsheet
  @params {string} - The table referenced in current RMPage
  @params{object} - Object containing rows of all information in table referenced in current RMPage
  return{object} - Object containing rows where keys are in standard english, and have important keys at the beginning
  */
function attributeMapper(tableName, rows) {
  const newKeys = {
    // headerInDatabase: [headerInStandardEnglish, dataType]
    account: ["Account", "str"],
    account_group: ["Account Group", "str"],
    account_board: ["Account Board", "bool"],
    additional_comments: ["Additional Comments", "str"],
    additional_id: ["Alternative ID", "str"],
    agency: ["Agency", "str"],
    agency_rate: ["Agency Rate", "str"],
    auto_closed_date: ["Closed Date (Auto)", "date"],
    auto_opened_date: ["Opened Date (Auto)", "date"],
    av_date: ["Available Date", "date"],
    band: ["Band", "str"],
    bms_rate: ["BMS Rate", "str"],
    charge_rate: ["Charge Rate", "str"],
    client: ["Client", "str"],
    client_id: ["Client ID", "str"],
    client_name: ["Client", "str"],
    closed_import_session_id: ["Import Session ID (CLOSED)", "str"],
    cluster_board: ["Cluster Board", "bool"],
    cmn: ["Client Machine No.", "str"],
    comments: ["Comments", "str"],
    contact_title: ["Contact (Title)", "str"],
    created_at: ["Created At", "date"],
    created_by: ["Created By", "str"],
    csa_number: ["CSA Number", "str"],
    currency: ["Currency", "str"],
    cv_reviewer: ["CV Reviewer", "str"],
    cv_reviewer_email: ["CV Reviewer Email", "str"],
    cv_reviewer_serial_number: ["CV Reviewer Serial No", "str"],
    description: ["Description", "str"],
    email: ["Email", "str"],
    end_date: ["End Date", "date"],
    full_name: ["Full Name", "str"],
    gender: ["Gender", "str"],
    growth_platform: ["Growth Platform", "str"],
    hcam: ["HCAM ID", "str"],
    high_band: ["High Band", "str"],
    imn: ["IBM Machine No.", "str"],
    imported_by: ["Imported By", "str"],
    industry_name: ["Industry", "str"],
    job_title: ["Job Title", "str"],
    jrs: ["JRS", "str"],
    jrs_name: ["JRS", "str"],
    location: ["Location", "str"],
    low_band: ["Low Band", "str"],
    manager_email: ["Manager Email", "str"],
    manager_name: ["Manager Name", "str"],
    manager_serial_number: ["Manager Serial Number", "str"],
    name: ["Project Name", "str"],
    new_import_session_id: ["Import Session ID (NEW)", "str"],
    offboarded_at: ["Last Offboard Date", "date"],
    offboarded_by: ["Offboarded By", "str"],
    offboard_initiated_at:["Ofboarding Initiated By", "str"],
    offboard_initiated_by_name: ["Ofboarding Initiated By", "str"],
    offboard_completion_by_name: ["Ofboarding Completed By", "str"],
    office_location: ["Office Location", "str"],
    on_project: ["On Project", "bool"],
    onboarded_at: ["Last Onboard Date", "date"],
    onboard_initiated_at: ["Onboarding Initiated at", "date"],
    onboarding_status_name: ["Onboarding Status", "str"],
    onboard_initiated_by_name:["Onboarding Initiated By", "str"],
    onboard_completion_by_name:["Onboarding Completed By", "str"],
    onboarded_by: ["Onboarded By", "str"],
    onsite_end_date: ["Onsite End Date", "date"],
    onsite_start_date: ["Onsite Start Date", "date"],
    open_import_session_id: ["Import Session ID (OPEN)", "str"],
    open_seat_account: ["Open Seat Account", "str"],
    open_seat_client: ["Open Seat Client", "str"],
    open_seat_sector: ["Open Seat Sector", "str"],
    open_seat_status: ["Status", "str"],
    open_seat_type: ["Type", "str"],
    opportunity_id: ["Opportunity ID", "str"],
    opportunity_name: ["Opportunity Name", "str"],
    opportunity_owner: ["Opportunity Owner", "str"],
    optional_skills: ["Optional Skills", "str"],
    owner_name: ["Owner Name", "str"],
    pay_rate: ["Pay Rate", "str"],
    phone: ["Mobile Number", "str"],
    pmp_created_by: ["PMP Created By", "str"],
    pmp_created_date: ["PMP Created Date", "date"],
    pmp_last_modified_by: ["PMP Last Modified By", "str"],
    pmp_last_modified_date: ["PMP Last Modified Date", "date"],
    pmp_submitted_by: ["PMP Submitted By", "str"],
    pmp_submitted_date: ["PMP Submitted Date", "date"],
    pmp_id: ["PMP ID", "str"],
    practice: ["Practice", "str"],
    priority_seat: ["Priority Seat", "bool"],
    project: ["Project", "str"],
    project_account: ["Project Account", "str"],
    project_board: ["Project Board", "bool"],
    project_current: ["Project Board", "bool"],
    project_sector: ["Project Sector", "str"],
    project_onboard: ["Project Onboarded At", "date"],
    project_offboard: ["Project Offboarded At", "date"],
    project_name: ["Project", "str"],
    purchase_order_box_url: ["PO Box Link", "str"],
    purchase_order_end_date: ["PO End Date", "date"],
    purchase_order_number: ["PO Number", "str"],
    purchase_order_start_date: ["PO Start Date", "date"],
    rate_currency: ["Rate Currency", "str"],
    released_to_tsc: ["Released To TSC", "bool"],
    remarks: ["Remarks", "str"],
    required_skills: ["Required Skills", "str"],
    resource_type: ["Resource Type", "str"],
    seat_title: ["Seat Title", "str"],
    sector: ["Sector", "str"],
    sector_name: ["Sector", "str"],
    sell_rate: ["Sell Rate", "str"],
    serial_number: ["Serial Number", "str"],
    start_date: ["Start Date", "date"],
    status: ["Status", "str"],
    status_comments: ["Status Comments", "str"],
    tram_number: ["TRAM Number", "str"],
    updated_at: ["Updated At", "date"],
    updated_by: ["Updated By", "str"],
    work_location_city: ["Work Location City", "str"],
  };

  // array of rows which aren't wanted in the worksheet
  const exlcusionList = [
    "account_id",
    "account_group_id",
    "account_name",
    "created_by_id",
    "code",
    "client_id",
    "client_logo",
    "high_band_id",
    "id",
    "import_session_id",
    "industry_id",
    "is_deleted",
    "is_onboard",
    "jrss",
    "jrs_id",
    "low_band_id",
    "profile_picture",
    "project_id",
    "sector_id",
    "status_id",
    "tsc_end_date",
    "tsc_start_date",
    "type_id",
    "updated_by_id",
    "user_id",
  ];

  // list of the required fields to be included in the report for candidate Tracking page i.e onboarding and offboarding report
  const requiredTrackingColumns = [
    "full_name",
    "serial_number",
    "band",
    "jrs_name",
    "manager_name",
    "manager_email",
    "onboarded_at",
    "offboarded_at",
    "account",
    "onboarding_status",
    "onboard_initiated_at",
    "onboard_initiated_by_name", 
    "onboard_completion_by_name",
    "offboard_initiated_at",
    "offboard_initiated_by_name", 
    "offboard_completion_by_name" ,
    "created_at",
  ];

  // list of columns which are listed first in their respective tables
  const tableKeys = {
    "Technical Sub Contractors": ["full_name", "serial_number"],
    Resources: ["full_name", "av_date"],
    "Open Seats": ["pmp_id", "seat_title", "client_name"],
    "boarding": requiredTrackingColumns,
    Projects: [
      "name",
      "client_name",
      "manager_name",
      "manager_serial_number",
      "manager_email",
    ],
    Candidates: [
      "full_name",
      "serial_number",
      "hcam",
      "pmp_id",
      "seat_title",
      "client_name",
    ],
    projects: [
      "full_name",
      "serial_number",
      "hcam",
      "pmp_id",
      "seat_title",
      "client_name",
    ],
  };

  // maps the rows passed through to a new object, which excluded unwanted rows
  return rows.map((object) => {
    const newObject = {};
    for (const key of tableKeys[tableName]) {
      if (key in object) {
        newObject[newKeys[key][0]] = checkValue(object[key], newKeys[key][1]);
      }
    }

    for (const key in object) {
      if (tableKeys[tableName].includes(key)) {
        continue;
      }
      if (exlcusionList.includes(key)) {
        delete object[key];
        continue;
      }
      // checks the value of the data, with instructions regarding the data type listed in newKeys
      newObject[newKeys[key][0]] = checkValue(object[key], newKeys[key][1]);
    }
    return newObject;
  });
}

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export {
  dropdownOptions,
  generateDate,
  downloadCapture,
  downloadToExcel,
  attributeMapper,
};
