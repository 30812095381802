import { SET_ERROR, SET_ORIENTATION } from "./mutations.type";

const state = {
  errors: [],
  isPortrait: false,
};

const getters = {
  errors(state) {
    state.errors = state.errors.slice(0, 3);
    return state.errors;
  },
  isPortrait(state) {
    return state.isPortrait;
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = [error, ...state.errors];
  },
  [SET_ORIENTATION](state, orientation) {
    state.isPortrait = orientation;
  },
};

export default {
  state,
  mutations,
  getters,
};
