import Vue from "vue";
import Vuex from "vuex";

import candidates from "./candidates.module";
import config from "./app.config.module";
import resourcing from "./resourcing.module";
import analytics from "./analytics.module";
import user from "./user.module";
import utils from "./utils.module";
import tasks from "./tasks.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    candidates,
    config,
    resourcing,
    analytics,
    user,
    utils,
    tasks,
  },
});
