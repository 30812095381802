<template class="nav-item">
  <div v-if="userHasPerms && userHasPenPic">
    <cv-side-nav-link v-if="type === 'link'" :to="path">
      <template v-slot:nav-icon
        ><img :src="require(`@/assets/dark/${imgSource}`)" alt="Icon"
      /></template>
      {{ name }}
    </cv-side-nav-link>
    <cv-side-nav-menu-item v-if="type === 'menu-item'" :to="path">
      <template v-slot:nav-icon
        ><img :src="require(`@/assets/dark/${imgSource}`)" alt="Icon" />
      </template>
      {{ name }}
    </cv-side-nav-menu-item>
    <!-- For type === 'menu-item-button', path is function which happens on click -->
    <cv-side-nav-menu-item v-if="type === 'menu-item-button'" onclick="path">
      <template v-slot:nav-icon
        ><img :src="require(`@/assets/dark/${imgSource}`)" alt="Icon" />
      </template>
      {{ name }}
    </cv-side-nav-menu-item>
    <cv-header-menu-item v-if="type === 'header'" :to="path">
      {{ name }}
    </cv-header-menu-item>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "MenuItem",
  props: [
    "name",
    "path",
    "permNeeded",
    "mustHavePenpic",
    "type",
    "roleOverride",
    "imgSource",
  ],
  computed: {
    ...mapGetters({
      user: "currentUser",
      checkPerms: "userHasPerm",
    }),
    // check if user has the role needed for the menu item to be displayed
    userHasPerms() {
      return this.checkPerms(this.permNeeded);
    },
    // check if user has complete penpic AND if this condition is actually required
    userHasPenPic() {
      if (this.mustHavePenpic) {
        // check if user has a role that allow to override this
        if (this.roleOverride && this.checkPerms(this.roleOverride)) {
          return true;
        } else {
          return this.user?.penpic_complete;
        }
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped>
.menu-item-wrapper {
  display: flex;
  height: 50px;
  width: 200px;
}

.cv-header-menu-item {
  width: 200px;
  text-align: center;
  height: 3rem;
}
.bx--side-nav .cv-header-menu-item {
  text-align: left;
}

img {
  height: 20px;
}
</style>
