<template>
  <div>
    <cv-toast-notification
      v-show="elementVisible"
      v-if="isVisiable"
      :kind="notification.kind"
      :title="notification.title"
      :sub-title="notification.subTitle"
      :caption="notification.caption"
      @close="closeNotification"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisiable: true,
      elementVisible: true,
    };
  },

  created() {
    setTimeout(() => (this.elementVisible = false), 3000);
  },
  props: {
    notification: Object,
  },
  methods: {
    closeNotification() {
      this.isVisiable = false;
    },
  },
};
</script>
<style lang="scss">
.bx--toast-notification {
  pointer-events: auto;
}
</style>
