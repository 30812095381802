<template>
  <cv-modal ref="timeoutModal" @modal-hidden="resetTimers">
    <template slot="title"> Are you still there? </template>
    <template slot="content" class="box">
      <p>
        You have been inactive for a while. SkillsMatch will automatically log
        you out in:
      </p>
      <baseTimer
        :activate="activate"
        :secondsLeft="secondsLeft"
        style="margin: 0 auto"
      />
    </template>
    <template slot="primary-button">Still here!</template>
  </cv-modal>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import baseTimer from "./baseTimer.vue";
import AuthService from "@/common/auth.service";
import { REFRESH_TOKEN_TIMERS } from "../store/actions.type";
import { SET_TIMING_OUT } from "../store/mutations.type";
export default {
  name: "TimeoutModal",
  components: {
    baseTimer,
  },
  data() {
    return {
      modalTimer: null,
      displayModal: false,
      displayTimer: null,
      activate: false,
      secondsLeft: 120, // Sets the timer in seconds
      triggerTimer: null,
      lastCountValue: 0,
    };
  },

  watch: {
    apiChange() {
      // If authenticated
      if (this.authenticated) {
        // If the interval has not been set
        if (!this.triggerTimer) {
          // Set the interval to be every hour
          this.triggerTimeout();
          this.triggerTimer = setInterval(() => {
            // Call activity check
            this.activityCheck();
          }, 60 * 60 * 1000);
        }
      }
    },
    authenticated() {
      // If you get logged out
      if (!this.authenticated) {
        // Clear the interval and timeout
        clearInterval(this.triggerTimer);
        clearTimeout(this.modalTimer);
        // Hide the modal if it is showing
        this.$refs.timeoutModal.hide();
        // Send the user to a new route so they get re-routed to login
        this.$router.push("/login");
      }
    },
  },
  computed: {
    ...mapGetters({
      apiChange: "timing_out",
      authenticated: "isAuthenticated",
    }),
  },

  methods: {
    ...mapMutations({
      setTimingOut: SET_TIMING_OUT,
    }),
    ...mapActions({
      refreshTokenTimers: REFRESH_TOKEN_TIMERS,
    }),
    async resetTimers() {
      this.secondsLeft = 120;
      this.activate = false;
      this.setTimingOut();
    },
    activityCheck() {
      // Checks if more than 5 api calls have been made
      if (this.apiChange > this.lastCountValue + 5) {
        this.lastCountValue = this.apiChange;
        // If yes, function gets called
        this.triggerTimeout();
      }
    },
    async triggerTimeout() {
      // Clears any previous timeouts for the modal
      clearTimeout(this.modalTimer);
      // Regenerates token and expiry
      await this.refreshTokenTimers();
      // Gets the expiry
      let token_expiry = AuthService.getAccessTokenExpiryTime();
      let timeToExpiry = token_expiry - Math.round(Date.now() / 1000);
      // sets a timer for the modal to appear
      this.modalTimer = setTimeout(() => {
        this.$refs.timeoutModal.show();
        this.activate = true;
      }, (timeToExpiry - this.secondsLeft) * 1000);
    },
  },
};
</script>

<style scoped>
.box {
  display: block;
}
</style>
