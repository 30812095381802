export const PURGE_AUTH = "purgeAuth";
export const SET_AUTH = "setAuth";
export const SET_USER_DATA = "setUserData";
export const SET_ONBOARD_DATA = "setOnboardCandidateData";
export const SET_PROFILE_PICTURE = "setProfilePicture";
export const SET_JRSS_DATA = "setJrssData";
export const SET_TIMING_OUT = "setTimingOut";

export const SET_CLUSTER_FILTER = "setClusterFilter";
export const SET_OPEN_SEAT_FILTER = "setOpenSeatFilter";
export const SET_FILTER = "setFilter";
export const SET_STATUSES = "setStatuses";
export const SET_ACTION_TYPE = "setActionType";

export const SET_CLIENT = "setClient";
export const SET_PROJECT = "setProject";
export const SET_PROJECT_DETAIL = "setProjectDetail";
export const SET_PROJECT_OPEN_SEATS = "setProjectOpenSeats";
export const SET_PROJECT_RESOURCES = "setProjectResources";
export const SET_ALL_SKILL = "setAllSkill";
export const SET_TECH_SKILL = "setTechSkill";
export const SET_SOFT_SKILL = "setSoftSkill";
export const SET_BADGE = "setBadge";
export const SET_SPECIALISM = "setSpecialism";
export const SET_OTHER_ROLE = "setOtherRole";

export const SET_CONTACT_DETAIL = "setContactDetail";

export const SET_RESOURCE_GROUPS = "setResourceGroups";
export const SET_RESOURCE_GROUP_DETAIL = "setResourceGroupDetail";

export const SET_IMAGE_MODAL = "imageModal";
export const SET_ASSIGNED_CANDIDATES = "assCan";
export const SET_CANDIDATE = "setCandidate";
export const SET_CANDIDATE_DATA = "setCandidateData";
export const SET_CANDIDATE_DETAIL = "setCandidateDetail";
export const SET_CANDIDATE_COMMENTS = "setCandidateComments";
export const SET_FILTERED_CANDIDATE = "setFilteredCandidate";
export const SET_TOPTRUMP_CANDIDATE = "setTopTrumpCandidate";
export const SET_CANDIDATE_BY_SERIAL = "setCandidatesBySerial";
export const SET_IMAGE_SOURCE = "setImageSource";
export const SET_ACCOUNT_GROUP = "setAccountGroup";

export const SET_BUG = "setBug";
export const SET_FEEDBACK = "setFeedback";
export const SET_FEEDBACK_DETAIL = "setFeedbackDetail";
export const SET_ROLE = "setRole";
export const SET_USER_ROLE = "setUserRole";

export const SET_JRSS = "setJrss";
export const SET_ERROR = "setError";
export const SET_BAND = "setBand";
export const SET_PRONOUN = "setPronoun";
export const SET_TSC = "setTsc";
export const SET_TSC_DETAIL = "setTscDetail";
export const SET_SEAT_STATUS = "setSeatStatus";
export const SET_OPEN_SEAT_STATUS_HISTORY = "setOpenSeatStatusHistory";
export const SET_RESOURCE_TYPE = "setResourceType";
export const SET_ARCHIVE = "setArchive";
export const SET_OPEN_SEAT = "setOpenSeat";
export const SET_OPEN_SEAT_DETAIL = "setOpenSeatDetail";
export const SET_AGENCY = "setAgency";
export const SET_CLEARANCE = "setClearance";
export const SET_CV = "setCV";
export const SET_ACCOUNT = "setAccount";
export const SET_PAGINATION_META = "setPaginationMeta";
export const SET_COMPONENT = "setComponent";
export const SET_CERTIFICATION = "setCertification";
export const SET_SEARCH_COMPONENT = "setSearchComponent";
export const SET_SEARCH_HISTORY = "setSearchHistory";
export const SET_NOTIFICATION = "setNotifcation";
export const SET_RESOURCE_PARAMETERS_DATA = "setResourceParametersData";
export const SET_SECTOR = "setSector";
export const SET_INDUSTRY = "setIndustry";

export const SET_CURRENCY = "setCurrency";
export const SET_OPEN_SEAT_PERSON_STATUS = "setOpenSeatPersonStatus";
export const SET_PERSON = "setPerson";
export const SET_CANDIDATE_PERSON = "setCandidatePerson";

export const SET_OPEN_SEAT_CANDIDATE = "setOpenSeatCandidate";
export const SET_SUITABLE_CANDIDATES = "setSuitableCandidates";
export const SET_EMPLOYEE_TYPE = "setEmployeeType";
export const SET_GENDER = "setGender";
export const SET_OPEN_SEAT_STATUS = "setOpenSeatsStatus";
export const SET_OPEN_SEAT_TYPE = "setOpenSeatsTypes";
export const SET_ATTACHMENTS = "setAttachments";
export const SET_EMAIL_TEMPLATE = "setEmailTemplate";
export const SET_SELECTED_EMAIL_TEMPLATE = "setSelectedEmailTemplate";
export const SET_CLIENT_COLOUR = "setClientColour";
export const SET_CONTACT_EMAIL_TYPE = "setContactEmailType";
export const SET_CONTACT_NUMBER_TYPE = "setContactNumberType";
export const SET_CONTACT_METHOD = "setContactMethod";
export const SET_CONTACT_TYPE = "setContactType";
export const SET_DEGREE_LEVEL = "setDegreeLevel";
export const SET_EXPERIENCE_LEVEL = "setExperienceLevel";
export const SET_GROWTH_PLATFORM = "setGrowthPlatform";
export const SET_LOCATION_TYPE = "setLocationType";
export const SET_PRACTICE = "setPractice";
export const SET_SKILL = "setSkill";
export const SET_ROLE_PERMISSION = "setRolePermission";
export const SET_PERMISSION = "setPermission";
export const SET_RESOURCE_PARAMETER = "setResourceParameter";
export const SET_RESOURCE_PARAMETER_DATA = "setResourceParameterData";
export const SET_RESOURCE_FOR_OPEN_SEAT = "setResourceForOpenSeat";
export const SET_TSC_SNAPSHOT = "setTscSnapshot";
export const SET_OPEN_SEAT_USER_STATUS = "setOpenSeatUserStatus";
export const SET_OPEN_SEAT_USERS_BY_ID = "setOpenSeatUsersById";
export const SET_CANDIDATE_USER = "setCandidateUser";
export const SET_RESOURCE_USER = "setResourceUser";
export const SET_RESOURCES = "setResources";
export const SET_LOADING = "setLoading";

export const SET_DOCUMENT_TYPE = "setDocumentType";
export const SET_LOCATION = "setLocation";

export const SET_PERSON_ACCOUNT_CHART = "setPersonAccountChart";
export const SET_PERSON_AVAILABILITY_CHART = "setPersonAvailabilityChart";
export const SET_PERSON_BAND_CHART = "setPersonBandChart";
export const SET_PERSON_BAND_GROUP_CHART = "setPersonBandGroupChart";
export const SET_PERSON_JRSS_CHART = "setPersonJrssChart";
export const SET_PERSON_GROWTH_CHART = "setPersonGrowthChart";
export const SET_PERSON_PRACTICE_CHART = "setPersonPracticeChart";
export const SET_TECH_SKILL_OVERVIEW_CHART = "setTechSkillsOverviewChart";
export const SET_TECH_SKILL_EXPERIENCE_CHART = "setTechSkillsExperienceChart";
export const SET_PROF_SKILL_OVERVIEW_CHART = "setProfSkillsOverviewChart";
export const SET_PROF_SKILL_EXPERIENCE_CHART = "setProfSkillsExperienceChart";
export const SET_BAND_BY_ACCOUNT_CHART = "setBandByAccountChart";
export const SET_PERSONS_OVER_TIME_BY_ACCOUNT_CHART =
  "setPersonsOverTimeByAccountChart";
export const SET_OPEN_SEAT_OVER_TIME_CHART = "setOpenSeatsOverTimeChart";
export const SET_OPEN_SEAT_BY_ACCOUNT_CHART = "setOpenSeatsByAccountChart";
export const SET_OPEN_SEAT_BY_JRSS_CHART = "setOpenSeatsByJrssChart";
export const SET_OPEN_SEAT_BY_MONTH_CHART = "setOpenSeatsByMonthChart";
export const SET_OPEN_SEAT_BY_PROJECT_CHART = "setOpenSeatsByProjectChart";
export const SET_USER_INVOICE = "setUserInvoice";
export const SET_RESOURCE_BOARDING_BY_GROUP = "setResourceBoardingCount";

export const SET_USER_PURCHASE_ORDERS = "setUserPurchaseOrders";

export const SET_TASKLIST_TEMPLATE = "setTasklistTemplate";
export const SET_TASKLIST_TEMPLATE_DETAIL = "setTasklistTemplateDetail";

export const SET_TASKLIST = "setTasklist";
export const SET_TASKLIST_DETAIL = "setTasklistDetail";
export const SET_USER_TASKLIST = "setUserTasklist";
export const SET_CREATED_TASKLIST = "setCreatedTasklist";

export const SET_TASK_TEMPLATE = "setTaskTemplate";
export const SET_TASK_TEMPLATE_DETAIL = "setTaskTemplateDetail";
export const SET_TASK_TEMPLATE_BY_TASKLIST = "setTaskTemplateByTasklist";

export const SET_TASK = "setTask";
export const SET_TASK_DETAIL = "setTaskDetail";
export const SET_USER_TASKS = "setUserTasks";

export const SET_TASK_TYPE = "setTaskType";
export const SET_TASK_TYPE_DETAIL = "setTaskTypeDetail";

export const SET_TASKLIST_STATUS = "setTasklistStatus";
export const SET_TASKLIST_STATUS_DETAIL = "setTasklistStatusDetail";

export const SET_TASK_STATUS = "setTaskStatus";
export const SET_TASK_STATUS_DETAIL = "setTaskStatusDetail";

export const SET_PERMISSIONS_GROUPS = "setPermissionsGroups";
export const SET_PERMISSIONS_GROUPS_DETAIL = "setPermissionsGroupsDetail";

export const SET_REPORT = "setReport";

export const SET_ROLE_MAPPERS = "setRoleMappers";

export const SET_USER_PREFERENCES = "setUserPreferences";

export const SET_EMAIL_LOGS = "setEmailLogs";
export const SET_EMAIL_LOG_DETAIL = "setEmailLogDetail";

export const SET_CANDIDATE_TRACKER = "setCandidateTracker";
export const SET_CANDIDATE_TRACKER_DETAIL = "setCandidateTrackerDetail";

export const SET_ONBOARDING_STATUSES = "setOnboardingStatuses";
export const SET_SLACK_STATUSES = "setSlackStatuses";

export const SET_ORIENTATION = "setOrientation";
export const SET_GREY_SCALE = "setGreyScale";
export const SET_CLIENT_CONFIG = "setClientConfig";
export const SET_ANONYMOUS = "setAnonymous";
