<template>
  <div class="notification-wrapper">
    <!--Notification component handle frontend messages. This take argument from notification mapGetter, which can be set by calling setNotification in mutation.type.js-->
    <Notifcation
      v-for="(notification, index) in notifications"
      :key="index"
      :notification="notification"
    />

    <!--ErrorNotification component handle error from backend. This takes argument from error mapGetter, which is updated by error-handler.js-->
    <ErrorNotification
      v-for="(error, index) in errors"
      v-bind:key="index"
      v-bind:title="error.title"
      v-bind:sub-title="error.subTitle"
      v-bind:caption="error.caption"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Notifcation from "./Notification.vue";
import ErrorNotification from "./ErrorNotification.vue";
export default {
  components: { ErrorNotification, Notifcation },
  computed: {
    ...mapGetters({
      errors: "errors",
      notifications: "notification",
    }),
  },
};
</script>
<style scoped>
.notification-wrapper {
  height: 100vh;
  width: 100%;
  max-width: 100vw;
  position: absolute;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 3em;
}
</style>
