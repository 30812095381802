import { APIService } from "@/common/api.service";
import {
  GET_CANDIDATE,
  GET_CANDIDATE_DATA,
  GET_CANDIDATE_DETAIL,
  GET_TOPTRUMP_CANDIDATE,
  GET_CANDIDATE_BY_SERIAL,
  CREATE_CANDIDATE_COMMENT,
  GET_COMMENTS_BY_CANDIDATE,
} from "./actions.type";
import {
  SET_CANDIDATE,
  SET_FILTERED_CANDIDATE,
  SET_CANDIDATE_DATA,
  SET_CANDIDATE_DETAIL,
  SET_TOPTRUMP_CANDIDATE,
  SET_CANDIDATE_BY_SERIAL,
  SET_CANDIDATE_COMMENTS,
} from "./mutations.type";

const state = {
  allCandidate: [],
  filteredCandidate: [],
  topTrumpCandidate: [],
  displayedCandidateData: null,
  candidatesBySerial: [],
  candidateList: {},
  candidateDetail: null,
  comments: [],
};

const getters = {
  candidate(state) {
    return state.allCandidate;
  },
  candidates(state) {
    return state.candidates;
  },
  filteredCandidate(state) {
    return state.filteredCandidate;
  },
  displayedCandidateData(state) {
    return state.displayedCandidateData;
  },
  toptrumpCandidate(state) {
    return state.topTrumpCandidate;
  },
  candidatesBySerial(state) {
    return state.candidatesBySerial;
  },
  candidateList(state) {
    return state.candidateList;
  },
  candidateDetail(state) {
    return state.candidateDetail;
  },
  comments(state) {
    return state.comments;
  },
};

const actions = {
  async [GET_CANDIDATE](context, searchFilter) {
    try {
      const { data } = await APIService.query("v3/users", searchFilter);
      context.commit(SET_CANDIDATE, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TOPTRUMP_CANDIDATE](context, searchFilter) {
    try {
      const { data } = await APIService.query(
        "v2/top_trump_users",
        searchFilter
      );
      // filter out canidates who haven't completed their penpics

      context.commit(SET_TOPTRUMP_CANDIDATE, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  // get data of a specific candidate

  async [GET_CANDIDATE_DATA](context, serialNumber) {
    try {
      const { data } = await APIService.get("/v3/user_data", serialNumber);
      context.commit(SET_CANDIDATE_DATA, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  //get candidate and openseat details
  async [GET_CANDIDATE_DETAIL](context, serialNumber) {
    try {
      const { data } = await APIService.get(
        "/v3/candidate/detail",
        serialNumber
      );
      context.commit(SET_CANDIDATE_DETAIL, data.rows[0]);
    } catch (error) {
      throw new Error(error);
    }
  },
  //get candidates by serial number array.
  async [GET_CANDIDATE_BY_SERIAL](context, serialNumbers) {
    try {
      const { data } = await APIService.query(
        "/users/id/get-users-by-serial",
        serialNumbers
      );
      context.commit(SET_CANDIDATE_BY_SERIAL, data.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_COMMENTS_BY_CANDIDATE](context, ref_id) {
    try {
      const { data } = await APIService.get("/v3/candidate_comments", ref_id);

      context.commit(SET_CANDIDATE_COMMENTS, data.comments);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_CANDIDATE_COMMENT](context, data) {
    try {
      const comment = await APIService.post("/v3/candidate_comments", data);
      return comment.data.com;
    } catch (error) {
      throw new Error(error);
    }
  },
};

const mutations = {
  [SET_CANDIDATE](state, candidate) {
    state.allCandidate = candidate.users;
    state.candidateList = candidate;
  },
  [SET_TOPTRUMP_CANDIDATE](state, toptrumpcandidates) {
    state.topTrumpCandidate = toptrumpcandidates;
  },
  [SET_FILTERED_CANDIDATE](state, candidates) {
    state.filteredCandidate = candidates;
  },
  [SET_CANDIDATE_DATA](state, candidate) {
    state.displayedCandidateData = candidate;
  },
  [SET_CANDIDATE_DETAIL](state, candidate) {
    state.candidateDetail = candidate;
  },
  [SET_CANDIDATE_BY_SERIAL](state, candidatesBySerial) {
    state.candidatesBySerial = candidatesBySerial;
  },
  [SET_CANDIDATE_COMMENTS](state, comments) {
    state.comments = comments;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
