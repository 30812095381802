//tODO : change the name of "data" module to something that makes more sense
import { APIService } from "@/common/api.service";
import { dropdownOptions } from "./functions.module";
import {
  //Application Configuration Routes
  CREATE_ACCOUNT,
  GET_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
  CREATE_ACCOUNT_GROUP,
  GET_ACCOUNT_GROUP,
  UPDATE_ACCOUNT_GROUP,
  DELETE_ACCOUNT_GROUP,
  GET_BADGE,
  GET_USER_BADGE,
  CREATE_BADGE,
  UPDATE_BADGE,
  DELETE_BADGE,
  GET_BAND,
  CREATE_BAND,
  UPDATE_BAND,
  DELETE_BAND,
  GET_CONTACT_METHOD,
  CREATE_CONTACT_METHOD,
  UPDATE_CONTACT_METHOD,
  DELETE_CONTACT_METHOD,
  GET_CONTACT_TYPE,
  CREATE_CONTACT_TYPE,
  UPDATE_CONTACT_TYPE,
  DELETE_CONTACT_TYPE,
  GET_DEGREE_LEVEL,
  CREATE_DEGREE_LEVEL,
  UPDATE_DEGREE_LEVEL,
  DELETE_DEGREE_LEVEL,
  GET_EXPERIENCE_LEVEL,
  CREATE_EXPERIENCE_LEVEL,
  UPDATE_EXPERIENCE_LEVEL,
  DELETE_EXPERIENCE_LEVEL,
  GET_GENDER,
  CREATE_GENDER,
  UPDATE_GENDER,
  DELETE_GENDER,
  GET_GROWTH_PLATFORM,
  CREATE_GROWTH_PLATFORM,
  UPDATE_GROWTH_PLATFORM,
  DELETE_GROWTH_PLATFORM,
  GET_JRSS,
  UPDATE_JRSS,
  DELETE_JRSS,
  CREATE_JRSS,
  GET_LOCATION,
  CREATE_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  GET_LOCATION_TYPE,
  CREATE_LOCATION_TYPE,
  UPDATE_LOCATION_TYPE,
  DELETE_LOCATION_TYPE,
  GET_OTHER_ROLE,
  CREATE_OTHER_ROLE,
  UPDATE_OTHER_ROLE,
  DELETE_OTHER_ROLE,
  GET_PRACTICE,
  CREATE_PRACTICE,
  UPDATE_PRACTICE,
  DELETE_PRACTICE,
  GET_PRONOUN,
  CREATE_PRONOUN,
  UPDATE_PRONOUN,
  DELETE_PRONOUN,
  GET_SECTOR,
  CREATE_SECTOR,
  UPDATE_SECTOR,
  DELETE_SECTOR,
  GET_SKILL,
  CREATE_SKILL,
  UPDATE_SKILL,
  DELETE_SKILL,
  GET_TECH_SKILL,
  GET_SOFT_SKILL,
  GET_SPECIALISM,
  CREATE_SPECIALISM,
  UPDATE_SPECIALISM,
  DELETE_SPECIALISM,

  // Application Permissions Data
  GET_ROLE,
  CREATE_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  GET_PERMISSION,
  CREATE_PERMISSION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
  GET_PERMISSIONS_GROUPS,
  GET_PERMISSIONS_GROUPS_DETAIL,
  CREATE_PERMISSION_GROUPS,
  UPDATE_PERMISSION_GROUPS,
  GET_ROLE_MAPPERS,

  //---------------------------------> May need to be sourced using SQL Queries
  GET_USER_ROLE,
  CREATE_USER_ROLE,
  UPDATE_USER_ROLE,
  DELETE_USER_ROLE,
  GET_ROLE_PERMISSION,
  CREATE_ROLE_PERMISSION,
  UPDATE_ROLE_PERMISSION,
  DELETE_ROLE_PERMISSION,
  //---------------------------------> May need to be sourced using SQL Queries

  // Feedback and Bugs
  GET_BUG,
  GET_BUG_DETAIL,
  CREATE_BUG,
  UPDATE_BUG,
  UPLOAD_BUG_IMAGE,
  DELETE_BUG,
  GET_FEEDBACK,
  GET_FEEDBACK_DETAIL,
  CREATE_FEEDBACK,
  UPDATE_FEEDBACK,
  DELETE_FEEDBACK,

  //@To-do Need to make separate queries for each of the STAUSES.
} from "./actions.type";
import {
  SET_TIMING_OUT,
  SET_GREY_SCALE,
  SET_CLIENT_CONFIG,
  SET_ANONYMOUS,
  //Config
  SET_ACCOUNT,
  SET_ACCOUNT_GROUP,
  SET_BADGE,
  SET_BAND,
  SET_CLIENT_COLOUR,
  SET_CONTACT_METHOD,
  SET_CONTACT_TYPE,
  SET_DEGREE_LEVEL,
  SET_EXPERIENCE_LEVEL,
  SET_GENDER,
  SET_GROWTH_PLATFORM,
  SET_JRSS,
  SET_LOCATION,
  SET_LOCATION_TYPE,
  SET_OTHER_ROLE,
  SET_PRACTICE,
  SET_PRONOUN,
  SET_SECTOR,
  SET_SKILL,
  SET_SOFT_SKILL,
  SET_TECH_SKILL,
  SET_SPECIALISM,
  SET_FILTER,

  //Admin
  SET_ROLE,
  SET_USER_ROLE,
  SET_ROLE_PERMISSION,
  SET_PERMISSION,
  SET_FEEDBACK,
  SET_FEEDBACK_DETAIL,
  SET_BUG,
  SET_LOADING,
  SET_PERMISSIONS_GROUPS,
  SET_PERMISSIONS_GROUPS_DETAIL,
  SET_ROLE_MAPPERS,
} from "./mutations.type";

const state = {
  // Timer for timing out tokens
  timing_out: [],
  //config data
  account: [],
  account_group: [],
  badge: [],
  bands: [],
  client_colour: "",
  contact_method: [],
  contact_type: [],
  degree_level: [],
  experience_level: [],
  filter: [],
  gender: [],

  growth_platform: [],
  jrss: [],
  location: [],
  location_type: [],
  other_role: [],
  practice: [],
  pronoun: [],
  sector: [],
  skill: [],
  tech_skill: [],
  soft_skill: [],
  specialism: [],

  //Admin
  user_role: [],
  role: [],
  permission: [],
  role_permission: [],
  grey_scale: false,
  client_config: true,
  anonymous: false,
  loading: false,
  bug: [],
  feedback: [],
  feedback_detail: [],
  permissions_groups: [],
  permissions_groups_detail: [],
  role_mappers: [],
};

const getters = {
  timing_out(state) {
    return state.timing_out;
  },
  //Config
  account(state) {
    return dropdownOptions(state.account);
  },
  account_group(state) {
    return dropdownOptions(state.account_group);
  },
  badge(state) {
    return dropdownOptions(state.badge);
  },
  band(state) {
    return state.bands
      .map((option) => {
        return {
          id: String(option.id),
          value: String(option.id),
          name: String(option.name),
          label: String(option.name),
          band_group: String(option.band_group),
          code: option.code ? String(option.code) : "",
        };
      })
      .sort((previous, current) => {
        if (previous.name > current.name) {
          return 1;
        } else {
          return -1;
        }
      });
  },

  client_colour(state) {
    return state.client_colour;
  },
  contact_method(state) {
    return dropdownOptions(state.contact_method);
  },
  contact_type(state) {
    return dropdownOptions(state.contact_type);
  },
  degree_level(state) {
    return dropdownOptions(state.degree_level);
  },
  experience_level(state) {
    return dropdownOptions(state.experience_level);
  },
  filter(state) {
    return state.filter;
  },
  gender(state) {
    return dropdownOptions(state.gender);
  },
  growth_platform(state) {
    return dropdownOptions(state.growth_platform);
  },
  jrss(state) {
    return dropdownOptions(state.jrss);
  },
  location(state) {
    return dropdownOptions(state.location);
  },
  location_type(state) {
    return dropdownOptions(state.location_type);
  },
  other_role(state) {
    return dropdownOptions(state.other_role);
  },
  practice(state) {
    return dropdownOptions(state.practice);
  },
  pronoun(state) {
    return dropdownOptions(state.pronoun);
  },
  sector(state) {
    return dropdownOptions(state.sector);
  },
  specialism(state) {
    return dropdownOptions(state.specialism);
  },
  skill(state) {
    return dropdownOptions(state.skill);
  },
  tech_skill(state) {
    for (let i of state.tech_skill) {
      i.value = String(i.id);
      i.label = i.name;
    }
    return state.tech_skill;
  },
  soft_skill(state) {
    for (let i of state.soft_skill) {
      i.value = String(i.id);
      i.label = i.name;
    }
    return state.soft_skill;
  },
  role(state) {
    return dropdownOptions(state.role);
  },
  role_permission(state) {
    return dropdownOptions(state.role_permission);
  },
  user_role(state) {
    return dropdownOptions(state.user_role);
  },
  permission(state) {
    return dropdownOptions(state.permission);
  },
  bug(state) {
    return state.bug;
  },
  feedback(state) {
    return state.feedback;
  },
  feedback_detail(state) {
    return state.feedback_detail;
  },
  loading(state) {
    return state.loading;
  },
  grey_scale(state) {
    return state.grey_scale;
  },
  client_config(state) {
    return state.client_config;
  },
  anonymous(state) {
    return state.anonymous;
  },
  permissions_groups(state) {
    return dropdownOptions(state.permissions_groups);
  },
  permissions_groups_detail(state) {
    return state.permissions_groups_detail;
  },
  role_mappers(state) {
    return state.role_mappers;
  },
};

const actions = {
  async [GET_ACCOUNT](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/accounts", attributes);
      context.commit(SET_ACCOUNT, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_ACCOUNT](context, payload) {
    try {
      await APIService.update("/v3/accounts", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_ACCOUNT](context, id) {
    try {
      await APIService.delete("/v3/accounts", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_ACCOUNT](context, data) {
    try {
      await APIService.post("/v3/accounts", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_ACCOUNT_GROUP](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/account_groups", attributes);
      context.commit(SET_ACCOUNT_GROUP, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_ACCOUNT_GROUP](context, payload) {
    try {
      await APIService.update("/v3/account_groups", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_ACCOUNT_GROUP](context, id) {
    try {
      await APIService.delete("/v3/account_groups", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_ACCOUNT_GROUP](context, data) {
    try {
      await APIService.post("/v3/account_groups", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_BADGE](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/badges", attributes);
      context.commit(SET_BADGE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_USER_BADGE](context, param) {
    try {
      const { data } = await APIService.get("/v3/user_badges", param);
      context.commit(SET_BADGE, data.user_badge);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [UPDATE_BADGE](context, payload) {
    try {
      await APIService.update("/v3/badges", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_BADGE](context, id) {
    try {
      await APIService.delete("/v3/badges", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_BADGE](context, data) {
    try {
      await APIService.post("/v3/badges", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_BAND](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/bands", attributes);
      context.commit(SET_BAND, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_BAND](context, payload) {
    try {
      await APIService.update("/v3/bands", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_BAND](context, id) {
    try {
      await APIService.delete("/v3/bands", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_BAND](context, data) {
    try {
      await APIService.post("/v3/bands", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_CONTACT_METHOD](context, attributes) {
    try {
      const { data } = await APIService.query(
        "/v3/contact_methods",
        attributes
      );
      context.commit(SET_CONTACT_METHOD, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_CONTACT_METHOD](context, payload) {
    try {
      await APIService.update("/v3/contact_methods", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_CONTACT_METHOD](context, id) {
    try {
      await APIService.delete("/v3/contact_methods", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_CONTACT_METHOD](context, data) {
    try {
      await APIService.post("/v3/contact_methods", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_CONTACT_TYPE](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/contact_types", attributes);
      context.commit(SET_CONTACT_TYPE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_CONTACT_TYPE](context, payload) {
    try {
      await APIService.update("/v3/contact_types", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_CONTACT_TYPE](context, id) {
    try {
      await APIService.delete("/v3/contact_types", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_CONTACT_TYPE](context, data) {
    try {
      await APIService.post("/v3/contact_types", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_DEGREE_LEVEL](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/degree_levels", attributes);
      context.commit(SET_DEGREE_LEVEL, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_DEGREE_LEVEL](context, payload) {
    try {
      await APIService.update("/v3/degree_levels", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_DEGREE_LEVEL](context, id) {
    try {
      await APIService.delete("/v3/degree_levels", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_DEGREE_LEVEL](context, data) {
    try {
      await APIService.post("/v3/degree_levels", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_EXPERIENCE_LEVEL](context, attributes) {
    try {
      const { data } = await APIService.query(
        "/v3/experience_levels",
        attributes
      );
      context.commit(SET_EXPERIENCE_LEVEL, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_EXPERIENCE_LEVEL](context, payload) {
    try {
      await APIService.update(
        "/v3/experience_levels",
        payload.id,
        payload.data
      );
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_EXPERIENCE_LEVEL](context, id) {
    try {
      await APIService.delete("/v3/experience_levels", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_EXPERIENCE_LEVEL](context, data) {
    try {
      await APIService.post("/v3/experience_levels", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_GENDER](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/genders", attributes);
      context.commit(SET_GENDER, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_GENDER](context, payload) {
    try {
      await APIService.update("/v3/genders", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_GENDER](context, id) {
    try {
      await APIService.delete("/v3/genders", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_GENDER](context, data) {
    try {
      await APIService.post("/v3/genders", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_GROWTH_PLATFORM](context, attributes) {
    try {
      const { data } = await APIService.query(
        "/v3/growth_platforms",
        attributes
      );
      context.commit(SET_GROWTH_PLATFORM, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_GROWTH_PLATFORM](context, payload) {
    try {
      await APIService.update("/v3/growth_platforms", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_GROWTH_PLATFORM](context, id) {
    try {
      await APIService.delete("/v3/growth_platforms", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_GROWTH_PLATFORM](context, data) {
    try {
      await APIService.post("/v3/growth_platforms", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_JRSS](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/jrss", attributes);
      context.commit(SET_JRSS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_JRSS](context, data) {
    try {
      await APIService.post("/v3/jrss", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_JRSS](context, payload) {
    try {
      await APIService.update("/v3/jrss", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_JRSS](context, id) {
    try {
      await JAPIService.delete("/v3/jrss", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_LOCATION](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/locations", attributes);
      context.commit(SET_LOCATION, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_LOCATION](context, payload) {
    try {
      await APIService.update("/v3/locations", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_LOCATION](context, id) {
    try {
      await APIService.delete("/v3/locations", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_LOCATION](context, data) {
    try {
      await APIService.post("/v3/locations", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_LOCATION_TYPE](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/location_types", attributes);
      context.commit(SET_LOCATION_TYPE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_LOCATION_TYPE](context, payload) {
    try {
      await APIService.update("/v3/location_types", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_LOCATION_TYPE](context, id) {
    try {
      await APIService.delete("/v3/location_types", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_LOCATION_TYPE](context, data) {
    try {
      await APIService.post("/v3/location_types", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [GET_OTHER_ROLE](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/other_roles", attributes);
      context.commit(SET_OTHER_ROLE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [UPDATE_OTHER_ROLE](context, payload) {
    try {
      await APIService.update("/v3/other_roles", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_OTHER_ROLE](context, id) {
    try {
      await APIService.delete("/v3/other_roles", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_OTHER_ROLE](context, data) {
    try {
      await APIService.post("/v3/other_roles", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PRACTICE](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/practices", attributes);
      context.commit(SET_PRACTICE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [UPDATE_PRACTICE](context, payload) {
    try {
      await APIService.update("/v3/practices", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_PRACTICE](context, id) {
    try {
      await APIService.delete("/v3/practices", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_PRACTICE](context, data) {
    try {
      await APIService.post("/v3/practices", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PRONOUN](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/pronouns", attributes);
      context.commit(SET_PRONOUN, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_PRONOUN](context, payload) {
    try {
      await APIService.update("/v3/pronouns", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_PRONOUN](context, id) {
    try {
      await APIService.delete("/v3/pronouns", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_PRONOUN](context, data) {
    try {
      await APIService.post("/v3/pronouns", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_SECTOR](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/sectors", attributes);
      context.commit(SET_SECTOR, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_SECTOR](context, payload) {
    try {
      await APIService.update("/v3/sectors", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_SECTOR](context, id) {
    try {
      await APIService.delete("/v3/sectors", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_SECTOR](context, data) {
    try {
      await APIService.post("/v3/sectors", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TECH_SKILL](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/skills", attributes);
      context.commit(SET_TECH_SKILL, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_SOFT_SKILL](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/skills", attributes);
      context.commit(SET_SOFT_SKILL, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_SKILL](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/skills", attributes);
      context.commit(SET_SKILL, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_SKILL](context, data) {
    try {
      await APIService.post("/v3/skills", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_SKILL](context, payload) {
    try {
      await APIService.update("/v3/skills", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_SKILL](context, id) {
    try {
      await APIService.delete("/v3/skills", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_SPECIALISM](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/specialisms", attributes);
      context.commit(SET_SPECIALISM, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_SPECIALISM](context, payload) {
    try {
      await APIService.update("/v3/specialisms", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_SPECIALISM](context, id) {
    try {
      await APIService.delete("/v3/specialisms", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_SPECIALISM](context, data) {
    try {
      await APIService.post("/v3/specialisms", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [GET_ROLE](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/roles", attributes);
      context.commit(SET_ROLE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_ROLE](context, payload) {
    try {
      await APIService.update("/v3/roles", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_ROLE](context, id) {
    try {
      await APIService.delete("/v3/roles", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_ROLE](context, data) {
    try {
      await APIService.post("/v3/roles", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [GET_USER_ROLE](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/user_roles", attributes);
      context.commit(SET_USER_ROLE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_USER_ROLE](context, data) {
    try {
      await APIService.post("/v3/user_roles", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_USER_ROLE](context, payload) {
    try {
      await APIService.update("/v3/user_roles", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_USER_ROLE](context, id) {
    try {
      await APIService.delete("/v3/user_roles", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PERMISSIONS_GROUPS](context, attributes) {
    try {
      const { data } = await APIService.query(
        "v3/permissions_groups",
        attributes
      );
      context.commit(SET_PERMISSIONS_GROUPS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PERMISSIONS_GROUPS_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("v3/permissions_groups", id);
      context.commit(SET_PERMISSIONS_GROUPS_DETAIL, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_PERMISSION_GROUPS](context, payload) {
    try {
      await APIService.update(
        "v3/permissions_groups",
        payload.id,
        payload.data
      );
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_PERMISSION_GROUPS](context, data) {
    try {
      await APIService.post("/v3/permissions_groups", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PERMISSION](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/permissions", attributes);
      context.commit(SET_PERMISSION, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_PERMISSION](context, payload) {
    try {
      await APIService.update("/v3/permissions", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_PERMISSION](context, id) {
    try {
      await APIService.delete("/v3/permissions", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_PERMISSION](context, data) {
    try {
      await APIService.post("/v3/permissions", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_ROLE_PERMISSION](context, attributes) {
    try {
      const { data } = await APIService.query(
        "/v3/role_permissions",
        attributes
      );
      context.commit(SET_ROLE_PERMISSION, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_ROLE_PERMISSION](context, payload) {
    try {
      await APIService.update("/v3/role_permissions", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_ROLE_PERMISSION](context, id) {
    try {
      await APIService.delete("/v3/role_permissions", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_ROLE_PERMISSION](context, data) {
    try {
      await APIService.post("/v3/role_permissions", data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [GET_FEEDBACK](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/feedbacks", attributes);
      context.commit(SET_FEEDBACK, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_FEEDBACK_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("/v3/feedbacks", id);
      context.commit(SET_FEEDBACK_DETAIL, data.feedback);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_FEEDBACK](context, data) {
    try {
      await APIService.post("/v3/feedbacks", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_FEEDBACK](context, payload) {
    try {
      await APIService.update("/v3/feedbacks", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_FEEDBACK](context, id) {
    try {
      await APIService.update("/v3/delete_feedback", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_BUG](context, attributes) {
    try {
      const { data } = await APIService.query("/v3/bugs", attributes);
      context.commit(SET_BUG, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_BUG_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("/v3/bugs", id);
      context.commit(SET_FEEDBACK_DETAIL, data.bug);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_BUG](context, data) {
    try {
      await APIService.post("/v3/bugs", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_BUG](context, payload) {
    try {
      await APIService.update("/v3/bugs", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPLOAD_BUG_IMAGE](context, payload) {
    try {
      await APIService.post("/v3/upload_bug_image", payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_BUG](context, id) {
    try {
      await APIService.update("/v3/delete_bug", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_ROLE_MAPPERS](context, search_component) {
    try {
      const { data } = await APIService.query(
        "v3/role_mapper",
        search_component
      );
      context.commit(SET_ROLE_MAPPERS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
};

const mutations = {
  [SET_ACCOUNT](state, account) {
    state.account = account;
  },
  [SET_ACCOUNT_GROUP](state, account_group) {
    state.account_group = account_group;
  },
  [SET_BADGE](state, badge) {
    state.badge = badge;
  },
  [SET_BAND](state, band) {
    state.bands = band;
  },
  [SET_CLIENT_COLOUR](state, client_colour) {
    state.client_colour = client_colour;
  },
  [SET_CONTACT_METHOD](state, contact_method) {
    state.contact_method = contact_method;
  },
  [SET_CONTACT_TYPE](state, contact_type) {
    state.contact_type = contact_type;
  },
  [SET_DEGREE_LEVEL](state, degree_level) {
    state.degree_level = degree_level;
  },
  [SET_EXPERIENCE_LEVEL](state, experience_level) {
    state.experience_level = experience_level;
  },
  [SET_FILTER](state, filter) {
    state.filter = filter;
  },
  [SET_GENDER](state, gender) {
    state.gender = gender;
  },
  [SET_GROWTH_PLATFORM](state, growth_platform) {
    state.growth_platform = growth_platform;
  },
  [SET_JRSS](state, jrss) {
    state.jrss = jrss;
  },
  [SET_LOCATION](state, location) {
    state.location = location;
  },
  [SET_LOCATION_TYPE](state, location_type) {
    state.location_type = location_type;
  },
  [SET_OTHER_ROLE](state, other_role) {
    state.other_role = other_role;
  },
  [SET_PRACTICE](state, practice) {
    state.practice = practice;
  },
  [SET_PRONOUN](state, pronoun) {
    state.pronoun = pronoun;
  },
  [SET_SECTOR](state, sector) {
    state.sector = sector;
  },
  [SET_SPECIALISM](state, specialism) {
    state.specialism = specialism;
  },
  [SET_SKILL](state, skill) {
    state.skill = skill;
  },
  [SET_TECH_SKILL](state, skill) {
    state.tech_skill = skill;
  },
  [SET_SOFT_SKILL](state, skill) {
    state.soft_skill = skill;
  },
  [SET_ROLE](state, role) {
    state.role = role;
  },
  [SET_USER_ROLE](state, user_role) {
    state.user_role = user_role;
  },
  [SET_PERMISSION](state, permission) {
    state.permission = permission;
  },
  [SET_ROLE_PERMISSION](state, role_permission) {
    state.role_permission = role_permission;
  },
  [SET_BUG](state, bug) {
    state.bug = bug;
  },
  [SET_FEEDBACK](state, feedback) {
    state.feedback = feedback;
  },
  [SET_FEEDBACK_DETAIL](state, feedback_detail) {
    state.feedback_detail = feedback_detail;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_GREY_SCALE](state, grey_scale) {
    state.grey_scale = grey_scale;
  },
  [SET_CLIENT_CONFIG](state, client_config) {
    state.client_config = client_config;
  },
  [SET_ANONYMOUS](state, anonymous) {
    state.anonymous = anonymous;
  },
  [SET_TIMING_OUT](state) {
    state.timing_out++;
  },
  [SET_PERMISSIONS_GROUPS](state, groups) {
    state.permissions_groups = groups;
  },
  [SET_PERMISSIONS_GROUPS_DETAIL](state, group) {
    state.permissions_groups_detail = group;
  },
  [SET_ROLE_MAPPERS](state, rm) {
    state.role_mappers = rm;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
