<template>
  <!-- Template for a loading screen with boolean check -->
  <cv-loading v-if="loading" :overlay="true" />
</template>
<script>
import { mapGetters } from "vuex";
export default {
  // Check store for page loading
  computed: {
    ...mapGetters({
      loading: "loading",
    }),
  },
};
</script>
