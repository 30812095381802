import { APIService } from "@/common/api.service";
import { dropdownOptions } from "./functions.module";
import {
  //Resource Management Views
  GET_ARCHIVES,
  GET_OPEN_SEAT,
  GET_OPEN_SEAT_STATUS_HISTORY,
  GET_OPEN_SEAT_BY_ID,
  GET_OPEN_SEAT_USERS_BY_ID,
  GET_SUITABLE_CANDIDATES,
  UPDATE_OPEN_SEAT,
  CREATE_OPEN_SEAT,
  GET_OPEN_SEAT_CANDIDATE,
  UPDATE_OPEN_SEAT_CANDIDATE,
  UPDATE_COMMENTS,
  CREATE_OPEN_SEAT_CANDIDATE,
  GET_SEARCH_COMPONENT,
  CREATE_SEARCH_HISTORY,
  GET_SEARCH_HISTORY,
  UPDATE_SEARCH_HISTORY,
  DELETE_SEARCH_HISTORY,
  GET_INDUSTRY,
  GET_CURRENCY,
  GET_NOTIFICATION,
  GET_EMPLOYEE_TYPE,
  GET_OPEN_SEAT_TYPE,
  GET_OPEN_SEAT_STATUS,
  GET_PROJECT,
  GET_PROJECT_BY_ID,
  GET_PROJECT_BY_NAME,
  GET_PROJECT_OPEN_SEATS,
  GET_PROJECT_RESOURCES,
  CREATE_PROJECT_RESOURCES,
  UPDATE_PROJECT_RESOURCES,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DELETE_SOFT,
  GET_TSC,
  GET_TSC_BY_ID,
  GET_TSC_BY_SERIAL,
  UPDATE_TSC,
  DELETE_TSC,
  CREATE_TSC,
  GET_CONTACT_DETAIL,
  UPDATE_CONTACT_DETAIL,
  CREATE_CONTACT_DETAIL,
  DELETE_CONTACT_DETAIL,
  GET_RESOURCE_GROUP,
  GET_RESOURCE_GROUP_DETAIL,
  CREATE_RESOURCE_GROUP,
  UPDATE_RESOURCE_GROUP,
  DELETE_RESOURCE_GROUP,
  //----------------------------> Need to be refactored to the user table.
  GET_OPEN_SEAT_USER_STATUS,
  GET_CANDIDATE_USER,
  GET_RESOURCE_USER,
  GET_RESOURCE_BY_ID,
  GET_RESOURCE_SERIAL,
  GET_RESOURCE_FOR_OPEN_SEAT,
  GET_DOCUMENT_TYPE,
  CREATE_RESOURCE,
  //Needs to be refactore based on the new DB schema.
  //---------------------------->

  //Resource Management Configuration
  GET_RESOURCE_PARAMETER,
  GET_RESOURCE_PARAMETER_DATA,
  CREATE_RESOURCE_PARAMETER_DATA,
  UPDATE_RESOURCE_PARAMETER_DATA,
  DELETE_RESOURCE_PARAMETER_DATA,
  GET_SEAT_STATUS,
  GET_RESOURCE_TYPE,
  UPLOAD_RESOURCES,
  GET_ATTACHMENTS_BY_ID,
  CREATE_ATTACHMENT,
  UPDATE_ATTACHMENT,
  UPLOAD_ATTACHMENT,
  GET_EMAIL_TEMPLATE,
  GET_EMAIL_TEMPLATE_BY_ID,
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  CREATE_AGENCY,
  GET_AGENCY,
  UPDATE_AGENCY,
  DELETE_AGENCY,
  CREATE_CLEARANCE,
  GET_CLEARANCE,
  UPDATE_CLEARANCE,
  DELETE_CLEARANCE,
  UPDATE_USER_CLEARANCE,
  GET_CLIENT,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  GET_ACTION_TYPE,
  GET_STATUSES,
  GET_IMAGE_SOURCE,
  GET_USER_INVOICE,
  GET_INVOICES_BY_PO,
  CREATE_USER_INVOICE,
  UPDATE_USER_INVOICE,
  DELETE_USER_INVOICE,
  UPDATE_PROJECT_BOARD,
  CREATE_PROJECT_BOARD,
  UPDATE_ACCOUNT_BOARD,
  CREATE_ACCOUNT_BOARD,
  GET_USER_PURCHASE_ORDERS,
  CREATE_USER_PURCHASE_ORDERS,
  UPDATE_USER_PURCHASE_ORDERS,
  DELETE_USER_PURCHASE_ORDERS,
  GET_OPEN_SEATS_BY_PMP_ID,
  GET_REPORT,
  SEND_EMAIL,
  GET_EMAIL_LOGS,
  GET_EMAIL_LOG_DETAIL,
  GET_EMAIL_LOGS_BY_SEAT,
  CREATE_EMAIL_LOG,
  GET_CANDIDATE_TRACKER,
  GET_CANDIDATE_TRACKER_DETAIL,
  UPDATE_CANDIDATE_TRACKER,
  GET_ONBOARDING_STATUSES,
  GET_SLACK_STATUSES,
} from "./actions.type";

import {
  SET_ATTACHMENTS,
  SET_IMAGE_MODAL,
  SET_ASSIGNED_CANDIDATES,
  SET_CLEARANCE,
  SET_CLIENT,
  SET_PROJECT,
  SET_PROJECT_DETAIL,
  SET_PROJECT_OPEN_SEATS,
  SET_PROJECT_RESOURCES,
  SET_DOCUMENT_TYPE,
  SET_EMPLOYEE_TYPE,
  SET_RESOURCE_TYPE,
  SET_EMAIL_TEMPLATE,
  SET_SELECTED_EMAIL_TEMPLATE,
  SET_RESOURCE_PARAMETER,
  SET_RESOURCE_PARAMETER_DATA,
  SET_RESOURCE_FOR_OPEN_SEAT,
  SET_NOTIFICATION,
  SET_INDUSTRY,
  SET_AGENCY,
  SET_CURRENCY,
  SET_SEAT_STATUS,
  SET_SEARCH_COMPONENT,
  SET_ARCHIVE,
  SET_OPEN_SEAT,
  SET_OPEN_SEAT_DETAIL,
  SET_SEARCH_HISTORY,
  SET_OPEN_SEAT_CANDIDATE,
  SET_OPEN_SEAT_USERS_BY_ID,
  SET_SUITABLE_CANDIDATES,
  SET_OPEN_SEAT_TYPE,
  SET_OPEN_SEAT_STATUS,
  SET_OPEN_SEAT_STATUS_HISTORY,
  SET_TSC_SNAPSHOT,
  SET_TSC,
  SET_TSC_DETAIL,
  SET_ACTION_TYPE,
  SET_STATUSES,
  SET_IMAGE_SOURCE,
  SET_RESOURCES,
  SET_CONTACT_DETAIL,
  SET_RESOURCE_GROUPS,
  SET_RESOURCE_GROUP_DETAIL,
  SET_REPORT,
  SET_EMAIL_LOGS,
  SET_EMAIL_LOG_DETAIL,

  //--Need to be refactored.
  SET_OPEN_SEAT_USER_STATUS,
  SET_CANDIDATE_USER,
  SET_RESOURCE_USER,
  SET_USER_INVOICE,
  SET_USER_PURCHASE_ORDERS,
  SET_CANDIDATE_TRACKER,
  SET_CANDIDATE_TRACKER_DETAIL,
  SET_ONBOARDING_STATUSES,
  SET_SLACK_STATUSES,
} from "./mutations.type";

const state = {
  attachments: [],
  agency: [],
  action_type: [],
  client: [],
  candidate_tracker: [],
  candidate_tracker_detail: {},
  candidate_user: [],
  clearance: [],
  currency: [],
  document_type: [],
  email_logs: [],
  email_log_detail: {},
  selectedEmailTemplate: 0,
  email_template: [
    {
      body: null,
      code: null,
      description: null,
      name: null,
      recipient: null,
      recipient_bcc: null,
      recipient_cc: null,
      sender: null,
      subject: null,
      attachments: [],
      id: null,
    },
  ],
  employee_type: [],
  industry: [],
  notification: [],
  open_seat_candidate: [],
  open_seat_users: [],
  suitable_candidates: [],
  open_seat_status: [],
  open_seat_type: [],
  open_seat_user_status: [],
  open_seat_status_history: [],
  open_seat: [],
  open_seat_detail: [
    {
      client_logo: "",
      pmp_id: "",
      csa_number: "",
      tram_number: "",
      client_id: "",
      project_id: "",
      high_band_id: "",
      low_band_id: "",
      jrs_id: "",
      start_date: "",
      end_date: "",
      released_to_tsc: "",
      status_id: "",
      type_id: "",
      priority_seat: "",
      seat_title: "",
      owner_name: "",
      onsite_start_date: "",
      onsite_end_date: "",
      opportunity_id: "",
      opportunity_name: "",
      opportunity_owner: "",
      sector_id: "",
      industry_id: "",
      required_skills: "",
      optional_skills: "",
      additional_comments: "",
      pmp_created_date: "",
      pmp_created_by: "",
      pmp_submitted_date: "",
      pmp_submitted_by: "",
      pmp_last_modified_date: "",
      pmp_last_modified_by: "",
      cv_reviewer_serial_number: "",
      cv_reviewer: "",
      cv_reviewer_email: "",
    },
  ],
  seat_status: [],
  statuses: [],
  image_modal: null,
  project: [],
  project_detail: [
    {
      account_id: "",
      client_id: "",
      code: "",
      created_at: "",
      created_by_id: "",
      end_date: "",
      id: "",
      is_deleted: "",
      location: "",
      manager_email: "",
      manager_name: "",
      manager_serial_number: "",
      name: "",
      start_date: "",
      updated_at: "",
      updated_by_id: "",
    },
  ],
  project_open_seats: [],
  project_resources: [],
  resource_type: [],
  resource_parameter: [],
  resource_parameter_data: [],
  resource_open_seat: [],
  resource_user: [],
  resource: [],
  search_component: [
    {
      attributes: [],
      filter: [],
      order: {},
      page: 1,
      perPage: 20,
      search: "",
    },
  ],
  search_history: [],
  tsc_snapshot: [],
  tsc: [],
  assigned_candidates: [],
  tsc_detail: [
    {
      serial_number: "",
      agency_id: "",
      pay_rate: "",
      agency_rate: "",
      bms_rate: "",
      sell_rate: "",
      charge_rate: "",
      employee_type_id: "",
      comments: "",
      purchase_order_number: "",
      purchase_order_box_url: "",
      purchase_order_start_date: "",
      purchase_order_end_date: "",
      offboarding_date: "",
      manager_name: "",
      manager_serial_number: "",
      manager_email: "",
      currency_id: "",
    },
  ],
  image_source: [],
  person_clearance: [],
  user_invoices: [],
  archive: [],
  contact_detail: [],
  user_purchase_orders: [],
  resource_groups: [],
  resource_group_detail: [],
  report: [],
  onboarding_statuses: [],
  slack_statuses: [],
};

const actions = {
  async [GET_ACTION_TYPE](context) {
    try {
      const { data } = await APIService.get("v3/action_types");
      context.commit(SET_ACTION_TYPE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_STATUSES](context) {
    try {
      const { data } = await APIService.get("v3/statuses");
      context.commit(SET_STATUSES, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_CLIENT](context) {
    try {
      const { data } = await APIService.get("v3/clients");
      context.commit(SET_CLIENT, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_CLIENT](context, data) {
    try {
      await APIService.post("v3/clients", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_CLIENT](context, payload) {
    try {
      await APIService.update("v3/clients", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_CLIENT](context, id) {
    try {
      await APIService.delete("v3/clients", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_DOCUMENT_TYPE](context, params) {
    try {
      const { data } = await APIService.query("/v3/document_types", params);
      context.commit(SET_DOCUMENT_TYPE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PROJECT](context, search_component) {
    try {
      const { data } = await APIService.query("v3/projects", search_component);
      context.commit(SET_PROJECT, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PROJECT_BY_ID](context, id) {
    try {
      const { data } = await APIService.get(`v3/project_by_id/${id}`);
      context.commit(SET_PROJECT_DETAIL, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PROJECT_BY_NAME](context, id) {
    try {
      const { data } = await APIService.get(`v3/project_by_name/${id}`);
      context.commit(SET_PROJECT_DETAIL, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PROJECT_OPEN_SEATS](context, id) {
    try {
      const { data } = await APIService.get(`v3/project_open_seats/${id}`);
      context.commit(SET_PROJECT_OPEN_SEATS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_PROJECT_RESOURCES](context, id) {
    try {
      const { data } = await APIService.get("v3/current_project_resources", id);
      context.commit(SET_PROJECT_RESOURCES, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_PROJECT_RESOURCES](context, data) {
    try {
      await APIService.post("v3/user_projects", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_PROJECT_RESOURCES](context, payload) {
    try {
      await APIService.update("v3/user_projects", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_PROJECT](context, data) {
    try {
      await APIService.post("v3/projects", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_PROJECT](context, payload) {
    try {
      await APIService.update("v3/projects", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_SOFT](context, payload) {
    try {
      await APIService.update("v3/soft_delete", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [GET_ARCHIVES](context, search_component) {
    try {
      const { data } = await APIService.query("v3/archive", search_component);
      context.commit(SET_ARCHIVE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [GET_OPEN_SEAT](context, search_component) {
    try {
      const { data } = await APIService.query(
        "v3/open_seats",
        search_component
      );
      context.commit(SET_OPEN_SEAT, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_OPEN_SEATS_BY_PMP_ID](context, id) {
    try {
      const { data } = await APIService.get("v3/open_seats_by_pmp_id", id);
      context.commit(SET_OPEN_SEAT_DETAIL, data.rows[0]);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_OPEN_SEAT_BY_ID](context, id) {
    try {
      const { data } = await APIService.get("v3/open_seats_by_id", id);
      context.commit(SET_OPEN_SEAT_DETAIL, data.rows[0]);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_OPEN_SEAT_USERS_BY_ID](context, id) {
    try {
      const { data } = await APIService.get("v3/open_seat_users_by_id", id);
      context.commit(SET_OPEN_SEAT_USERS_BY_ID, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_OPEN_SEAT_CANDIDATE](context, payload) {
    try {
      await APIService.update(
        "v3/open_seat_candidates",
        payload.id,
        payload.data
      );
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_COMMENTS](context, payload) {
    try {
      await APIService.update(
        "v3/candidate_comments",
        payload.id,
        payload.data
      );
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_SUITABLE_CANDIDATES](context, id) {
    try {
      const { data } = await APIService.get(
        "v3/suitable_open_seat_candidates",
        id
      );
      context.commit(SET_SUITABLE_CANDIDATES, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_OPEN_SEAT](context, data) {
    try {
      await APIService.postFormMultipart("v3/open_seats", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_OPEN_SEAT_CANDIDATE](context, data) {
    try {
      await APIService.post("v3/open_seat_candidates", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_OPEN_SEAT](context, payload) {
    try {
      await APIService.update("v3/open_seats", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [GET_CONTACT_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("v3/contact_details", id);
      context.commit(SET_CONTACT_DETAIL, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_CONTACT_DETAIL](context, data) {
    try {
      await APIService.post("v3/contact_details", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_CONTACT_DETAIL](context, payload) {
    try {
      await APIService.update("v3/contact_details", payload.id, payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_CONTACT_DETAIL](context, id) {
    try {
      await APIService.delete("v3/contact_details_delete", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TSC](context, search_component) {
    try {
      const { data } = await APIService.query("v3/tsc", search_component);
      context.commit(SET_TSC, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TSC_BY_ID](context, id) {
    try {
      const { data } = await APIService.get("v3/tsc_by_id", id);
      context.commit(SET_TSC_DETAIL, data.rows[0]);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_TSC_BY_SERIAL](context, id) {
    try {
      const { data } = await APIService.get("v3/tsc_by_serial", id);
      context.commit(SET_TSC_DETAIL, data.rows[0]);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [CREATE_TSC](context, data) {
    try {
      await APIService.post("v3/tsc", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_TSC](context, payload) {
    try {
      await APIService.update("v3/tsc", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_TSC](context, id) {
    try {
      await APIService.deleteArray("v3/tsc", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_RESOURCE_TYPE](context, search_component) {
    try {
      const { data } = await APIService.query(
        "v3/resource_types",
        search_component
      );
      context.commit(SET_RESOURCE_TYPE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_AGENCY](context) {
    try {
      const { data } = await APIService.get("v3/agencies");
      context.commit(SET_AGENCY, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_AGENCY](context, payload) {
    try {
      await APIService.update("v3/agencies", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_AGENCY](context, id) {
    try {
      await APIService.delete("v3/agencies", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_AGENCY](context, data) {
    try {
      await APIService.post("v3/agencies", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_IMAGE_SOURCE](context) {
    try {
      const { data } = await APIService.get("/v3/image_sources");
      context.commit(SET_IMAGE_SOURCE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_CLEARANCE](context, attributes) {
    try {
      const { data } = await APIService.get("/v3/clearances", attributes);
      context.commit(SET_CLEARANCE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_CLEARANCE](context, payload) {
    try {
      await APIService.update("clearances", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_CLEARANCE](context, id) {
    try {
      await APIService.delete("clearances", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_USER_CLEARANCE](context, payload) {
    try {
      await APIService.update("v3/user_clearances", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_CLEARANCE](context, data) {
    try {
      await APIService.post("clearances", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_SEARCH_COMPONENT](context) {
    return state.search_component;
  },

  async [CREATE_SEARCH_HISTORY](context, data) {
    try {
      await APIService.post("v3/search_histories", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_SEARCH_HISTORY](context, query) {
    try {
      const { data } = await APIService.query("v3/search_histories", {
        table_name: query,
      });
      context.commit(SET_SEARCH_HISTORY, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_SEARCH_HISTORY](context, payload) {
    try {
      await APIService.update("v3/search_histories", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_SEARCH_HISTORY](context, id) {
    try {
      await APIService.delete("v3/search_histories", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_RESOURCE_PARAMETER](context, params) {
    try {
      const { data } = await APIService.query("/v3/resource_params", params);
      context.commit(SET_RESOURCE_PARAMETER, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_RESOURCE_PARAMETER_DATA](context, input) {
    try {
      const { data } = await APIService.query(
        `/v3/${input.apiRoute}`,
        input.query
      );
      context.commit(SET_RESOURCE_PARAMETER_DATA, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_RESOURCE_FOR_OPEN_SEAT](context, input) {
    try {
      const { data } = await APIService.query("/v3/resources_open_seat", input);
      context.commit(SET_RESOURCE_FOR_OPEN_SEAT, data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [DELETE_RESOURCE_PARAMETER_DATA](context, input) {
    try {
      await APIService.delete(`/v3/${input.apiRoute}`, input.id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_RESOURCE_PARAMETER_DATA](context, input) {
    try {
      await APIService.post(`/v3/${input.apiRoute}`, input.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_NOTIFICATION](context) {
    return state.notification;
  },
  async [GET_INDUSTRY](context) {
    try {
      const { data } = await APIService.get("v3/industries");
      context.commit(SET_INDUSTRY, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_RESOURCE_PARAMETER_DATA](context, input) {
    try {
      await APIService.update(
        input.apiRoute,
        input.payload.id,
        input.payload.data
      );
    } catch (error) {
      throw new Error(error);
    }
  },

  //@To-Do
  async [GET_OPEN_SEAT_USER_STATUS](context, search_component) {
    try {
      const { data } = await APIService.query(
        "v3/open_seat_user_status",
        search_component
      );
      context.commit(SET_OPEN_SEAT_USER_STATUS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [CREATE_RESOURCE](context, payload) {
    try {
      await APIService.post("v3/resources", payload);
    } catch (error) {
      throw new Error(error);
    }
  },

  async [GET_CANDIDATE_USER](context, search_component) {
    try {
      const { data } = await APIService.query(
        "user/candidate",
        search_component
      );
      context.commit(SET_CANDIDATE_USER, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_RESOURCE_USER](context, search_component) {
    try {
      const { data } = await APIService.query("v3/resources", search_component);

      context.commit(SET_RESOURCES, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPLOAD_RESOURCES](context, data) {
    try {
      await APIService.postFormMultipart("v3/resources/upload", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_RESOURCE_BY_ID](context, id) {
    try {
      const { data } = await APIService.get("v3/resources", id);
      context.commit(SET_RESOURCE_USER, data.rows[0]);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_RESOURCE_SERIAL](context, id) {
    try {
      const { data } = await APIService.get("v3/resources_serial", id);
      context.commit(SET_RESOURCE_USER, data.rows[0]);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_OPEN_SEAT_CANDIDATE](context, search_component) {
    try {
      const { data } = await APIService.query(
        "v3/open_seat_candidates",
        search_component
      );
      context.commit(SET_OPEN_SEAT_CANDIDATE, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_CURRENCY](context, variables) {
    try {
      const { data } = await APIService.query("v3/currencies", variables);
      context.commit(SET_CURRENCY, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_EMPLOYEE_TYPE](context, search_component) {
    try {
      const { data } = await APIService.query(
        "/v3/employee_types",
        search_component
      );
      context.commit(SET_EMPLOYEE_TYPE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_OPEN_SEAT_STATUS](context) {
    try {
      const { data } = await APIService.get("v3/open_seat_status");
      context.commit(SET_OPEN_SEAT_STATUS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  //To-Do change this to the Status Detail API on app.config.
  async [GET_SEAT_STATUS](context) {
    try {
      const { data } = await APIService.get("v3/seat_status");
      context.commit(SET_SEAT_STATUS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_OPEN_SEAT_TYPE](context) {
    try {
      const { data } = await APIService.get("v3/open_seat_types");
      context.commit(SET_OPEN_SEAT_TYPE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_OPEN_SEAT_STATUS_HISTORY](context, id) {
    try {
      const { data } = await APIService.get("v3/open_seat_status_history", id);
      context.commit(SET_OPEN_SEAT_STATUS_HISTORY, data.seat_history);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_ATTACHMENTS_BY_ID](context, id) {
    // id to be passed is the email template id
    try {
      const { data } = await APIService.get("v3/attachments", id);
      context.commit(SET_ATTACHMENTS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_ATTACHMENT](context, payload) {
    try {
      await APIService.post("v3/attachments", payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_ATTACHMENT](context, payload) {
    try {
      await APIService.update("v3/attachments", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPLOAD_ATTACHMENT](context, data) {
    try {
      await APIService.post("v3/upload_file", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_EMAIL_TEMPLATE](context, payload) {
    try {
      await APIService.post("v3/email_templates", payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_EMAIL_TEMPLATE](context) {
    try {
      const { data } = await APIService.query("v3/email_templates", {
        page: 1,
        perPage: -1,
      });
      context.commit(SET_EMAIL_TEMPLATE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_EMAIL_TEMPLATE_BY_ID](context, id) {
    try {
      const { data } = await APIService.get("v3/email_templates", id);
      context.commit(SET_EMAIL_TEMPLATE, data.email_template);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_EMAIL_TEMPLATE](context, payload) {
    try {
      await APIService.update("v3/email_templates", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_EMAIL_TEMPLATE](context, id) {
    try {
      await APIService.update("v3/delete_email_templates", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_USER_PURCHASE_ORDERS](context, id) {
    try {
      const { data } = await APIService.get("v3/user_purchase_orders", id);
      context.commit(SET_USER_PURCHASE_ORDERS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_USER_PURCHASE_ORDERS](context, data) {
    try {
      await APIService.post("v3/user_purchase_orders", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_USER_PURCHASE_ORDERS](context, payload) {
    try {
      await APIService.update(
        "v3/user_purchase_orders",
        payload.id,
        payload.data
      );
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_USER_PURCHASE_ORDERS](context, id) {
    try {
      await APIService.update("v3/user_po_delete", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_USER_INVOICE](context, id) {
    try {
      const { data } = await APIService.get("v3/user_invoices_user_id", id);
      context.commit(SET_USER_INVOICE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_INVOICES_BY_PO](context, po_id) {
    try {
      const { data } = await APIService.get("v3/user_invoices_by_po", po_id);
      context.commit(SET_USER_INVOICE, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_USER_INVOICE](context, data) {
    try {
      await APIService.post("v3/user_invoices", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_USER_INVOICE](context, id) {
    try {
      await APIService.update("v3/user_invoices_delete", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_USER_INVOICE](context, payload) {
    try {
      await APIService.update("v3/user_invoices", payload.id, payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_PROJECT_BOARD](context, payload) {
    try {
      await APIService.update(
        "v3/user_project_board",
        payload.id,
        payload.data
      );
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_PROJECT_BOARD](context, data) {
    try {
      await APIService.post("v3/user_project_board", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_ACCOUNT_BOARD](context, payload) {
    try {
      await APIService.update(
        "v3/user_account_board",
        payload.id,
        payload.data
      );
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_ACCOUNT_BOARD](context, data) {
    try {
      await APIService.post("v3/user_account_board", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_RESOURCE_GROUP](context, search_component) {
    try {
      const { data } = await APIService.query(
        "v3/resource_groups",
        search_component
      );
      context.commit(SET_RESOURCE_GROUPS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_RESOURCE_GROUP_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("v3/resource_groups", id);
      context.commit(SET_RESOURCE_GROUP_DETAIL, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_RESOURCE_GROUP](context, payload) {
    try {
      await APIService.post("v3/resource_groups", payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_RESOURCE_GROUP](context, payload) {
    try {
      await APIService.update("v3/resource_groups", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [DELETE_RESOURCE_GROUP](context, id) {
    try {
      await APIService.destroy("v3/resource_groups", id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_REPORT](context, query) {
    try {
      const { data } = await APIService.query(`v3/report/${query.apiString}`, {
        filter: query.filter,
      });
      context.commit(SET_REPORT, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [SEND_EMAIL](context, attributes) {
    try {
      await APIService.post("v3/html_email", attributes);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_EMAIL_LOGS](context, attributes) {
    try {
      const { data } = await APIService.get("v3/email_logs", attributes);
      context.commit(SET_EMAIL_LOGS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_EMAIL_LOG_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("v3/email_logs", id);
      context.commit(SET_EMAIL_LOG_DETAIL, data.email_log);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_EMAIL_LOGS_BY_SEAT](context, id) {
    try {
      const { data } = await APIService.get("v3/email_logs/open_seat", id);
      context.commit(SET_EMAIL_LOGS, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [CREATE_EMAIL_LOG](context, data) {
    try {
      await APIService.post("v3/email_logs", data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_CANDIDATE_TRACKER](context, search_component) {
    try {
      const { data } = await APIService.query(
        "v3/candidate_tracker",
        search_component
      );
      context.commit(SET_CANDIDATE_TRACKER, data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_CANDIDATE_TRACKER_DETAIL](context, id) {
    try {
      const { data } = await APIService.get("v3/candidate_tracker", id);
      context.commit(SET_CANDIDATE_TRACKER_DETAIL, data.rows[0]);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [UPDATE_CANDIDATE_TRACKER](context, payload) {
    try {
      await APIService.update("v3/candidate_tracker", payload.id, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_ONBOARDING_STATUSES](context, search_component) {
    try {
      const { data } = await APIService.query(
        "v3/onboarding_statuses",
        search_component
      );
      context.commit(SET_ONBOARDING_STATUSES, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
  async [GET_SLACK_STATUSES](context, search_component) {
    try {
      const { data } = await APIService.query(
        "v3/slack_statuses",
        search_component
      );
      context.commit(SET_SLACK_STATUSES, data.rows);
    } catch (error) {
      throw new Error(error);
    }
  },
};

const mutations = {
  [SET_ACTION_TYPE](state, actionType) {
    state.action_type = actionType;
  },
  [SET_IMAGE_MODAL](state, image) {
    state.image_modal = image;
  },
  [SET_ASSIGNED_CANDIDATES](state, data) {
    state.assigned_candidates = data;
  },
  [SET_STATUSES](state, statuses) {
    state.statuses = statuses;
  },
  [SET_CLEARANCE](state, clearance) {
    state.clearance = clearance;
  },
  [SET_PROJECT](state, project) {
    state.project = project;
  },
  [SET_PROJECT_DETAIL](state, project) {
    state.project_detail = project;
  },
  [SET_PROJECT_OPEN_SEATS](state, seats) {
    state.project_open_seats = seats;
  },
  [SET_PROJECT_RESOURCES](state, resources) {
    state.project_resources = resources;
  },
  [SET_CLIENT](state, client) {
    state.client = client;
  },
  [SET_ARCHIVE](state, archive) {
    state.archive = archive;
  },
  [SET_OPEN_SEAT](state, open_seat) {
    state.open_seat = open_seat;
  },
  [SET_OPEN_SEAT_DETAIL](state, open_seat) {
    state.open_seat_detail = open_seat;
  },
  [SET_SEAT_STATUS](state, seat_status) {
    state.seat_status = seat_status;
  },
  [SET_RESOURCE_TYPE](state, resource_type) {
    state.resource_type = resource_type;
  },
  [SET_CONTACT_DETAIL](state, contact_detail) {
    state.contact_detail = contact_detail;
  },
  [SET_AGENCY](state, agency) {
    state.agency = agency;
  },
  [SET_SEARCH_COMPONENT](state, search_component) {
    state.search_component = search_component;
  },
  [SET_SEARCH_HISTORY](state, search_history) {
    state.search_history = search_history;
  },
  [SET_NOTIFICATION](state, notification) {
    state.notification = notification;
  },
  [SET_RESOURCE_PARAMETER](state, resource_parameter) {
    state.resource_parameter = resource_parameter;
  },
  [SET_RESOURCE_PARAMETER_DATA](state, resource_parameter_data) {
    state.resource_parameter_data = resource_parameter_data;
  },
  [SET_RESOURCE_FOR_OPEN_SEAT](state, resource_open_seat) {
    state.resource_open_seat = resource_open_seat;
  },
  [SET_INDUSTRY](state, industry) {
    state.industry = industry;
  },
  [SET_CURRENCY](state, currency) {
    state.currency = currency;
  },
  [SET_OPEN_SEAT_USER_STATUS](state, open_seat_user_status) {
    state.open_seat_user_status = open_seat_user_status;
  },
  [SET_CANDIDATE_USER](state, user) {
    state.candidate_user = user;
  },
  [SET_RESOURCE_USER](state, user) {
    state.resource_user = user;
  },
  [SET_RESOURCES](state, users) {
    state.resource = users;
  },
  [SET_OPEN_SEAT_CANDIDATE](state, open_seat_candidate) {
    state.open_seat_candidate = open_seat_candidate;
  },
  [SET_OPEN_SEAT_USERS_BY_ID](state, open_seat_users) {
    state.open_seat_users = open_seat_users;
  },
  [SET_SUITABLE_CANDIDATES](state, suitable_candidates) {
    state.suitable_candidates = suitable_candidates;
  },
  [SET_EMPLOYEE_TYPE](state, employee_type) {
    state.employee_type = employee_type;
  },
  [SET_OPEN_SEAT_STATUS](state, data) {
    state.open_seat_status = data;
  },
  [SET_OPEN_SEAT_STATUS_HISTORY](state, data) {
    state.open_seat_status_history = data;
  },
  [SET_OPEN_SEAT_TYPE](state, data) {
    state.open_seat_type = data;
  },
  [SET_EMAIL_TEMPLATE](state, email_template) {
    state.email_template = email_template;
  },
  [SET_SELECTED_EMAIL_TEMPLATE](state, id) {
    state.selectedEmailTemplate++;
  },
  [SET_ATTACHMENTS](state, attachments) {
    state.attachments = attachments;
  },
  [SET_TSC_SNAPSHOT](state, data) {
    state.tsc_snapshot = data;
  },
  [SET_TSC](state, data) {
    state.tsc = data;
  },
  [SET_TSC_DETAIL](state, data) {
    state.tsc_detail = data;
  },
  [SET_DOCUMENT_TYPE](state, data) {
    state.document_type = data;
  },
  [SET_IMAGE_SOURCE](state, data) {
    state.image_source = data;
  },
  [SET_USER_INVOICE](state, data) {
    state.user_invoices = data;
  },
  [SET_USER_PURCHASE_ORDERS](state, data) {
    state.user_purchase_orders = data;
  },
  [SET_RESOURCE_GROUPS](state, data) {
    state.resource_groups = data;
  },
  [SET_RESOURCE_GROUP_DETAIL](state, data) {
    state.resource_group_detail = data;
  },
  [SET_REPORT](state, report) {
    state.report = report;
  },
  [SET_EMAIL_LOGS](state, data) {
    state.email_logs = data;
  },
  [SET_EMAIL_LOG_DETAIL](state, data) {
    state.email_log_detail = data;
  },
  [SET_CANDIDATE_TRACKER](state, data) {
    state.candidate_tracker = data;
  },
  [SET_CANDIDATE_TRACKER_DETAIL](state, data) {
    state.candidate_tracker_detail = data;
  },
  [SET_ONBOARDING_STATUSES](state, data) {
    state.onboarding_statuses = data;
  },
  [SET_SLACK_STATUSES](state, data) {
    state.slack_statuses = data;
  },
};

const getters = {
  attachments(state) {
    return state.attachments;
  },
  action_type(state) {
    return dropdownOptions(state.action_type);
  },
  assigned_candidates() {
    return state.assigned_candidates;
  },
  statuses(state) {
    return dropdownOptions(state.statuses);
  },
  client(state) {
    return dropdownOptions(state.client);
  },
  project(state) {
    return state.project;
  },
  project_detail(state) {
    return state.project_detail;
  },
  project_open_seats(state) {
    return state.project_open_seats;
  },
  project_resources(state) {
    return state.project_resources;
  },
  tsc_snapshot(state) {
    return dropdownOptions(state.tsc_snapshot);
  },
  seat_status(state) {
    return dropdownOptions(state.seat_status);
  },

  open_seat_status_history(state) {
    return state.open_seat_status_history;
  },
  resource_type(state) {
    return dropdownOptions(state.resource_type);
  },
  open_seat(state) {
    return state.open_seat;
  },
  open_seat_detail(state) {
    return state.open_seat_detail;
  },
  agency(state) {
    return dropdownOptions(state.agency);
  },
  clearance(state) {
    return dropdownOptions(state.clearance);
  },
  search_component(state) {
    return state.search_component;
  },
  search_history(state) {
    return state.search_history;
  },
  notification(state) {
    return state.notification;
  },
  resource_parameter(state) {
    return state.resource_parameter;
  },
  resource_parameter_data(state) {
    return state.resource_parameter_data;
  },
  resource_open_seat(state) {
    return state.resource_open_seat;
  },
  industry(state) {
    return dropdownOptions(state.industry);
  },
  image_modal(state) {
    return state.image_modal;
  },
  currency(state) {
    return dropdownOptions(state.currency);
  },
  open_seat_user_status(state) {
    return dropdownOptions(state.open_seat_user_status);
  },
  candidate_user(state) {
    return state.candidate_user;
  },
  resource_user(state) {
    return state.resource_user;
  },
  resource(state) {
    return state.resource;
  },
  employee_type(state) {
    return dropdownOptions(state.employee_type);
  },
  open_seat_type(state) {
    return dropdownOptions(state.open_seat_type);
  },
  open_seat_status(state) {
    return dropdownOptions(state.open_seat_status);
  },
  email_template(state) {
    return state.email_template;
  },
  selectedEmailTemplate(state) {
    return state.selectedEmailTemplate;
  },
  open_seat_candidate(state) {
    return state.open_seat_candidate;
  },
  open_seat_users(state) {
    return state.open_seat_users;
  },
  tsc(state) {
    return state.tsc;
  },
  tsc_detail(state) {
    return state.tsc_detail;
  },
  document_type(state) {
    return dropdownOptions(state.document_type);
  },
  image_source(state) {
    return dropdownOptions(state.image_source);
  },
  person_clearance(state) {
    return dropdownOptions(state.person_clearance);
  },
  user_invoices(state) {
    return state.user_invoices;
  },
  archive(state) {
    return state.archive;
  },
  contact_detail(state) {
    return state.contact_detail;
  },
  suitable_candidates(state) {
    return state.suitable_candidates;
  },
  user_purchase_orders(state) {
    return state.user_purchase_orders;
  },
  resource_groups(state) {
    return state.resource_groups;
  },
  resource_group_detail(state) {
    return state.resource_group_detail;
  },
  report(state) {
    return state.report;
  },
  email_logs(state) {
    return state.email_logs;
  },
  email_log_detail(state) {
    return state.email_log_detail;
  },
  candidate_tracker(state) {
    return state.candidate_tracker;
  },
  candidate_tracker_detail(state) {
    return state.candidate_tracker_detail;
  },
  onboarding_statuses(state) {
    return state.onboarding_statuses;
  },
  slack_statuses(state) {
    return state.slack_statuses;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
