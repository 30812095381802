<template>
  <cv-toast-notification
    class="error-notification"
    v-if="isNotificationVisible"
    kind="error"
    :title="title"
    :sub-title="subTitle"
    :caption="caption"
    @close="closeNotification"
  />
</template>
<script>
export default {
  name: "ErrorNotification",
  props: ["title", "subTitle", "caption"],
  data() {
    return { isNotificationVisible: true };
  },
  methods: {
    closeNotification() {
      this.isNotificationVisible = false;
    },
  },
  created() {
    setTimeout(() => (this.isNotificationVisible = false), 3000);
  },
};
</script>
<style scoped>
.error-notification {
  pointer-events: auto;
}
</style>
