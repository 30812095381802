<template>
  <div id="app" :class="darkmode">
    <LoadingOverlay />
    <NavBar />
    <cv-content id="main">
      <router-view />
    </cv-content>
    <!-- <Footer /> -->
    <NotificationWrapper />
    <TimeoutModal />
  </div>
</template>

<script>
import NavBar from "./components/general/navbar/NavBar.vue";
// import Footer from "./components/general/footer/Footer.vue";
import NotificationWrapper from "./components/general/notification/NotificationWrapper.vue";
import LoadingOverlay from "./components/general/loadingOverlay/LoadingOverlay.vue";
import TimeoutModal from "../src/views/TimeoutModal.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    NavBar,
    // Footer,
    NotificationWrapper,
    LoadingOverlay,
    TimeoutModal,
  },
  data() {
    return {
      darkmode:
        window.localStorage.getItem("darkmode") === "true"
          ? "carbon-theme--g100"
          : "carbon-theme--g10",
    };
  },
  watch: {
    userPreferences() {
      this.darkmode =
        window.localStorage.getItem("darkmode") === "true"
          ? "carbon-theme--g100"
          : "carbon-theme--g10";
    },
  },
  computed: {
    ...mapGetters({
      userPreferences: "userPreferences",
    }),
  },
};
</script>

<style lang="scss">
@import "./styles/_carbon";

body {
  margin: 0px;
  height: 100vh;
  width: 100%;
}

html {
  width: 100%;
  overflow: hidden;
}

#app {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--cds-ui-background);
  color: var(--cds-text-primary);
}

router-view {
  width: 100% !important;
  height: 100% !important;
}

#main {
  max-height: calc(100vh - 3rem);
  height: calc(100vh - 3rem);
  padding-left: 3rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.bx--progress-line {
  position: absolute;
  left: 0;
  height: 1px;
  width: 100% !important;
  border: 1px inset transparent;
}

.bx--progress-label {
  max-width: 90%;
}

.detail-tile {
  padding: 0px;
  width: 100%;
  max-width: 1100px;
  background-color: var(--cds-ui-background);
  overflow: auto;
  margin: 0 auto;
}

.detail-tile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: $spacing-05;
}

.detail-input-container {
  width: 100%;
  padding-bottom: $spacing-05;
}

.detail-input-row {
  width: 100%;
}

.detail-title {
  @include carbon--type-style("productive-heading-03");
}

.detail-description {
  @include carbon--type-style("helper-text-01");
}

.detail-description-container {
  padding-bottom: $spacing-05;
}

.form-container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

@media (min-width: 42rem) {
  .detail-input-row {
    display: flex;

    .detail-input-container:nth-child(odd) {
      padding-right: $spacing-05;
    }

    .detail-input-container:nth-child(even) {
      padding-left: $spacing-05;
    }
  }

  .calendar-container {
    .detail-input-container {
      padding-right: $spacing-05;
    }
  }

  .detail-tile-container {
    padding: $spacing-07;
  }

  .detail-description-container {
    padding-bottom: $spacing-07;
  }
}
</style>
