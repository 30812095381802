import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";
import store from "./store/index";
import router from "./router/router";
import CarbonComponentsVue from "@carbon/vue/src/index";
import VuePapaParse from "vue-papa-parse";
import chartsVue from "@carbon/charts-vue";
import VueRx from "vue-rx";
import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd";
import Vuelidate from "vuelidate";
import "vuejs-clipper/dist/vuejs-clipper.css";
import { CHECK_AUTH } from "./store/actions.type";
import { BluepagesAPIService, APIService } from "./common/api.service";

Vue.use(chartsVue);
Vue.use(VuePapaParse);
Vue.use(VueRx);
Vue.use(VuejsClipper);
Vue.use(CarbonComponentsVue);
// used for simplifying input validation
Vue.use(Vuelidate);

Vue.config.productionTip = false;
/** @TODO add error handling for Vue components */
Vue.config.errorHandler = (error) => {
  console.log(error);
};
APIService.init();
BluepagesAPIService.init();

router.beforeEach(async (to, from, next) => {
  // if user just filled out a form and is going to preview, don't reload user data
  await store.dispatch(CHECK_AUTH);

  // direct to login page if user not authenticated
  if (
    to.name !== "Login" &&
    !store.state?.user?.isAuthenticated &&
    to.name !== "feedback" &&
    to.name !== "suggestions" &&
    to.name !== "bug"
  ) {
    next({ name: "Login" });
  } else if (
    //if user has not completed the form they should be redirected to it unless resource manager viewing candidate card
    to.name === "TopTrump" &&
    !to.query.user &&
    !store.state?.user?.data?.penpic_complete
  ) {
    next({ path: "/penpic/edit-details", query: { toptrump: true } });
  } else if (to.name == "Login" && !store.state?.user?.isAuthenticated) {
    next();
  } else {
    next();
  }
});

new Vue({
  router,
  render: (h) => h(App),
  store: store,
}).$mount("#app");
