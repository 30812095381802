import store from "../store/index";
import { SET_ERROR } from "@/store/mutations.type";
import AuthService from "@/common/auth.service";
import Cookies from "js-cookie";

export const ErrorHandler = (error) => {
  if (error.title && error.subTitle) {
    store.commit(SET_ERROR, {
      title: error.title,
      subTitle: error.subTitle,
    });
  } else {
    if (error.message == "Request failed with status code 429") {
      store.commit(SET_ERROR, {
        title: "Too Many Requests",
        subTitle: "Too Many Requests - access revoked for 1 minute",
      });
    }
    //location.reload();
    // check if we got response from the server
    else if (error?.response?.data) {
      store.commit(SET_ERROR, {
        title: "Error",
        subTitle: error.response.data.error,
      });
      // errors when BluePages API is not available
    } else if (
      error?.error?.config?.url.slice(0, 8) === "profiles" ||
      error?.error?.config?.url.slice(0, 5) === "image"
    ) {
      //error for Cisco VPN not connected
      if (error.error.message === "Network Error") {
        store.commit(SET_ERROR, {
          title: "Cisco VPN Error",
          subTitle: "Couldn't connect to BluePages API",
          caption: "Check if Cisco VPN connection is enabled",
        });
      }
      //error for Cisco API connected but 404 not found response from BluePages
      if (error.error.message === "Request failed with status code 404") {
        store.commit(SET_ERROR, {
          title: "Error 404: not found",
          subTitle: "Could not find data from BluePages with the inputed value",
          caption: "Please check your inputed details",
        });
      }
      // when request to backend times out
    } else if (error.message === "Request failed with status code 401") {
      // If 401 response from api - purge auth
      AuthService.destroyAccessToken();
      AuthService.destroyRefreshToken();
      AuthService.destroySerialNumber();
      AuthService.destroyAPItoken();
      AuthService.destroyApiTokenExpiryTime();
      AuthService.destroyAccessTokenExpiryTime();
      Cookies.remove("user");
      store.commit(SET_ERROR, {
        title: "User Timed Out",
        subTitle: "Please log in again",
      });
    } else if (error.code === "ECONNABORTED") {
      store.commit(SET_ERROR, {
        title: "Database error",
        subTitle: "Connection to the database timeout reached",
      });
      // when backend services can't be reached
    } else if (error.message === "Network Error") {
      store.commit(SET_ERROR, {
        title: "Backend error",
        subTitle: "Couldn't connect to the backend services",
      });
    } else if (error === "User Exists") {
      store.commit(SET_ERROR, {
        title: "User Already Exists",
        subTitle: "User exists matching given serial number",
        caption: "Continue to update existing user",
      });
      // if error is not handled, just show "Something went wrong"
    } else {
      store.commit(SET_ERROR, {
        title: "Error",
        subTitle: "Something went wrong",
      });
    }
  }
};
