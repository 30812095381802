<template>
  <cv-header aria-label="Carbon header">
    <cv-modal
      kind="danger"
      ref="modalview"
      :visible="showModal"
      @modal-hidden="hideModal"
      @primary-click="handleDelete"
    >
      <template slot="title">
        <h3>Delete Account</h3>
      </template>

      <template slot="content">
        <p style="font-size: 16px">
          Are you sure you want to delete your SkillsMatch account?
        </p>
        <br />
        <p>This action cannot be undone.</p>
      </template>
      <template slot="secondary-button">Cancel</template>
      <template slot="primary-button">Delete</template>
    </cv-modal>
    <cv-header-menu-button aria-label="Header menu" aria-controls="side-nav" />
    <cv-skip-to-content href="#main-content">
      Skip to content
    </cv-skip-to-content>
    <cv-header-name to="/" prefix="IBM">
      <pre></pre>
      SkillsMatch
    </cv-header-name>
    <cv-header-nav aria-label="Carbon nav">
      <MenuItem
        name="Login"
        path="/login"
        type="header"
        v-if="!isAuthenticated"
      />
    </cv-header-nav>
    <template v-slot:left-panels v-if="isAuthenticated">
      <cv-side-nav id="side-nav" class="sidebar" rail>
        <cv-side-nav-items>
          <MenuItem
            name="My Penpic"
            path="/penpic"
            type="link"
            :imgSource="'yourPenPicShortcutLogo.svg'"
            :permNeeded="['CG']"
          />
          <!-- <MenuItem
            name="Tasks"
            path="/tasks"
            type="link"
            :imgSource="'yourTasksShortcutLogo.svg'"
            /> -->
          <div v-if="checkPerms(['UPPF'])">
            <hr />
            <MenuItem
              name="PenPic Search"
              path="/search"
              type="link"
              :imgSource="'penpicSearchShortcutLogo.svg'"
            />
          </div>
          <div v-if="checkPerms(['URF'])">
            <hr />
            <MenuItem
              v-for="item in resourceManagement"
              :key="item.name"
              :name="item.name"
              :path="item.path"
              :permNeeded="item.permNeeded"
              :imgSource="item.imgSource"
              type="link"
            />
            <hr />
            <MenuItem
              v-for="item in management"
              :key="item.name"
              :name="item.name"
              :path="item.path"
              :permNeeded="item.permNeeded"
              :imgSource="item.imgSource"
              type="link"
            />
          </div>
          <div v-if="checkPerms(['ANL'])">
            <hr />
            <MenuItem
              v-for="item in analytics"
              :key="item.name"
              :name="item.name"
              :path="item.path"
              :permNeeded="item.permNeeded"
              :imgSource="item.imgSource"
              type="link"
            />
          </div>
          <hr />
          <MenuItem
            name="Give Feedback"
            path="/feedback"
            type="link"
            :imgSource="'feedback.svg'"
          />
          <div v-if="checkPerms(['AC', 'AA', 'AURM'])">
            <hr />
            <MenuItem
              v-for="item in settings"
              :key="item.name"
              :name="item.name"
              :path="item.path"
              :permNeeded="item.permNeeded"
              :mustHavePenpic="item.mustHavePenpic"
              :roleOverride="item.roleOverride"
              :showWhenNotLoggedIn="item.showWhenNotLoggedIn"
              :imgSource="item.imgSource"
              :disabled="item.disabled"
              type="link"
            />
          </div>
        </cv-side-nav-items>
      </cv-side-nav>
    </template>
    <template v-slot:header-global>
      <cv-header-global-action
        v-if="isAuthenticated"
        :label="user?.full_name ? user.full_name : ''"
        aria-controls="user-panel"
        tipPosition="bottom"
        tipAlignment="end"
      >
        <UserAvatar20
          v-if="
            isAuthenticated && (!profilePicture || profilePicture == 'data')
          "
        />
        <img
          v-else
          class="headerProfilePicture"
          width="18"
          height="18"
          :src="profilePicture"
        />
      </cv-header-global-action>
    </template>
    <template v-slot:right-panels>
      <cv-header-panel id="user-panel" v-if="isAuthenticated">
        <div style="font-size: 0.875rem; padding: 1rem">
          <h4
            class="name-font-size resizeNavFirst"
            :class="getNames(names) ? 'name-font-size' : 'resizeNavFirst'"
          >
            {{ user?.full_name ? user.full_name : "" }}
          </h4>
          <p v-if="isAuthenticated" class="candidate-name">
            {{ profileEmail }}
          </p>
        </div>
        <cv-toggle
          @change="toggleTheme"
          style="margin-left: 1rem"
          value="themeToggle"
          label="Dark Mode"
          :small="true"
          :checked="darkmode"
        >
        </cv-toggle>
        <cv-switcher>
          <cv-switcher-item>
            <cv-switcher-item-link @click="logout">
              Logout
            </cv-switcher-item-link>
          </cv-switcher-item>
          <cv-switcher-item v-if="isAuthenticated">
            <cv-switcher-item-link @click="openDelete">
              Delete Account
            </cv-switcher-item-link>
          </cv-switcher-item>
        </cv-switcher>
      </cv-header-panel>
    </template>
  </cv-header>
</template>

<script>
import UserAvatar20 from "@carbon/icons-vue/es/user--avatar/20";
import { mapGetters, mapActions, mapMutations } from "vuex";
import MenuItem from "./MenuItem.vue";
import { LOGOUT } from "@/store/actions.type";
import {
  DELETE_USER,
  GET_USER_PREFERENCES,
  UPDATE_USER_PREFERENCES,
} from "@/store/actions.type";
import { SET_USER_PREFERENCES } from "../../../store/mutations.type";
import { UserImagesService } from "@/common/api.service";

export default {
  name: "MyHeader",
  components: { MenuItem, UserAvatar20 },
  watch: {
    async $route() {
      if (this.isAuthenticated && !this.profilePicture) {
        await this.getUserIconData();
      }
    },
  },
  data() {
    return {
      isPictureLoading: true,
      profilePicture: null,
      profileEmail: null,
      profileName: "User",
      darkmode: window.localStorage.getItem("darkmode") === "true",

      management: [
        {
          name: "Candidates",
          path: "/engagements/candidates",
          permNeeded: ["OSCF"],
          imgSource: "candidatesShortcutLogo.svg",
        },
        {
          name: "Open Seats",
          path: "/engagements/open-seats",
          permNeeded: ["OSF"],
          imgSource: "openSeatsShortcutLogo.svg",
        },
        {
          name: "Project Search",
          path: "/engagements/projects",
          permNeeded: ["PROF"],
          imgSource: "projectSearchShortcutLogo.svg",
        },
      ],

      resourceManagement: [
        {
          name: "Boarding",
          path: "/boarding",
          permNeeded: ["AA"],
          imgSource: "onboarding.svg",
        },
        {
          name: "Resources",
          path: "/resourcing/resources",
          permNeeded: ["URF"],
          imgSource: "resourcesShortcutLogo.svg",
        },
        {
          name: "TSC",
          path: "/resourcing/tsc",
          permNeeded: ["TSCF"],
          imgSource: "TSCShortcutLogo.svg",
        },
      ],

      settings: [
        {
          name: "Application Configuration",
          path: "/system_settings/application_configuration",
          permNeeded: ["AA"],
          imgSource: "appConfig.svg",
        },
        {
          name: "Feedback & Bugs",
          path: "/admin/feedback",
          permNeeded: ["AA"],
          imgSource: "feedbackAndBugs.svg",
        },
        {
          name: "Resource Management",
          path: "/system_settings/resource_management_configuration",
          permNeeded: ["AA"],
          imgSource: "rm.svg",
        },
        {
          name: "Resource Management +",
          path: "/system_settings/advanced",
          permNeeded: ["AC"],
          imgSource: "advancedRM.svg",
        },
        {
          name: "User & Role Management",
          path: "/system_settings/user_role_management",
          permNeeded: ["AURM"],
          imgSource: "urm.svg",
        },
      ],
      analytics: [
        {
          name: "Insights",
          path: "/insights",
          permNeeded: ["ANL"],
          imgSource: "analyticsShortcutLogo.svg",
        },
        {
          name: "Reporting",
          path: "/reporting",
          permNeeded: ["ANL"],
          imgSource: "projectSnapshotShortcutLogo.svg",
        },
      ],

      showModal: false,
    };
  },
  methods: {
    ...mapActions({
      logoutUser: LOGOUT,
      deleteUser: DELETE_USER,
      updateUserPreferences: UPDATE_USER_PREFERENCES,
      getUserPreferences: GET_USER_PREFERENCES,
    }),
    ...mapMutations({
      setUserPreferences: SET_USER_PREFERENCES,
    }),
    hideModal() {
      this.showModal = false;
    },
    openDelete() {
      this.showModal = true;
    },
    logout() {
      this.logoutUser();
      this.$router.push("/login");
    },
    routerPush(route) {
      this.$router.push(route);
    },
    async handleDelete() {
      try {
        this.hideModal();
        await this.deleteUser();
        await this.logoutUser();
      } catch (error) {
        throw new Error(error);
      }
    },
    async getUserIconData() {
      //Gets both profile image and profile full name.  This is then used in the user icon on the navbar
      if (this.user.penpic_complete) {
        try {
          const { data } = await UserImagesService.get(this.user.serial_number);
          this.profileName = this.user.full_name;
          this.profilePicture = data;
          this.profileEmail = this.user.email;
        } catch (error) {
          if (
            error.message ==
            "Cannot read properties of undefined (reading 'data')"
          ) {
            this.$router.push("/");
            this.logoutUser();
          }
          throw new Error(error);
        }
      }
    },
    fontSizeCalc(namelength) {
      const maxFont = 90;
      const minFont = 55;
      const maxNameLength = 50;
      const minNameLength = 30;
      let ans =
        (namelength * minFont -
          minNameLength * minFont +
          maxNameLength * maxFont -
          namelength * maxFont) /
        (maxNameLength - minNameLength);
      return ans;
    },

    async getNames(name) {
      try {
        if (name[0].length >= 21) {
          const resize = document.querySelector(".resizeNavFirst");
          resize.style.fontSize = this.fontSizeCalc(name[0].length) + "%";
        }
        if (name.length != 1 && name[name.length - 1].length >= 21) {
          const resize = document.querySelector(".resizeNavLast");
          resize.style.fontSize =
            this.fontSizeCalc(name[name.length - 1].length) + "%";
        }
        return false;
      } catch (error) {
        throw new Error(error);
      }
    },
    async toggleTheme() {
      // if darkmode is not in local storage, get from API
      if (this.darkmode !== null) {
        this.darkmode = !this.darkmode;
      } else {
        await this.getUserPreferences(this.user.id);
        this.darkmode = !this.userPreferences.darkmode;
      }

      // update local storage and database
      localStorage.setItem("darkmode", this.darkmode);
      this.updateUserPreferences({
        data: { darkmode: this.darkmode },
        id: this.user.id,
      });
      this.setUserPreferences({ darkmode: this.darkmode });
    },
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      user: "currentUser",
      checkPerms: "userHasPerm",
      userPreferences: "userPreferences",
    }),
    width() {
      return document.width < 1250;
    },
    names: function () {
      if (this.profileName) {
        return this.profileName.split(" ");
      } else {
        return ["N/A"];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1250px) {
  .bx--header__nav {
    display: none !important;
  }

  .bx--header__menu-toggle__hidden {
    display: flex !important;
  }
}
.resourcing:hover,
.engagements:hover {
  cursor: pointer;
}
.menu-item-wrapper > * {
  text-align: center;
  width: 200px;
}

.headerProfilePicture {
  border-radius: 100%;
  border: solid 1px white;
}

.name-font-size {
  font-size: 0.875rem;
}

.resizeNavFirst {
  font-size: 0.875rem;
}

.resizeNavLast {
  font-size: 0.875rem;
}

.sidebar {
  overflow: hidden;
  background-color: #161616;
  color: #f4f4f4;
  margin-right: 15px;
  border-right: 1px solid #393939;
}
.sidebar a {
  float: left;
  font-size: 16px;
  color: #c6c6c6;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropbtnNav,
.dropdown-content-nav {
  padding-left: 10px;
}

.last-dropdown {
  margin-bottom: 50px;
}

.dropdown {
  float: left;
  overflow: hidden;
  width: 200px;
  border: none;
}
.dropdown .dropbtn {
  font-size: 0.875rem;
  border: none;
  width: 200px;
  outline: none;
  color: #c6c6c6;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}
.sidebar a:hover,
.dropdown:hover .dropbtn {
  background-color: #292929;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #292929;
  border-bottom: 1px solid #525252;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content > div {
  border: 1px solid #525252;
}
.dropdown-content a:hover {
  background-color: #292929;
}
.dropdown:hover .dropdown-content {
  display: block;
  width: 200px;
}

.menu-box {
  font-size: 14px;
  border: none;
  width: 200;
  outline: none;
  color: #c6c6c6;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropbtntest:hover {
  cursor: pointer;
}

img {
  height: 20px;
}
hr {
  width: calc(100% - 1.75rem);
  border: 1px solid #393939;
  border-bottom: 0;
  margin: 0.75rem 0.8125rem;
}
</style>
