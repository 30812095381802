import Vue from "vue";
import axios from "axios";
import AuthService from "@/common/auth.service.js";
import store from "../store/index";
import { SET_LOADING, SET_TIMING_OUT } from "../store/mutations.type";
import { ErrorHandler } from "../plugins";

export const APIService = {
  init() {
    const apiServiceInstance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: 30000,
    });
    apiServiceInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        ErrorHandler(error);
      }
    );
    Vue.prototype.$apiService = apiServiceInstance;
  },
  setHeaders() {
    return {
      accesstoken: AuthService.getAccessToken(),
      serialnumber: AuthService.getSerialNumber(),
      apiToken: AuthService.getAPItoken(),
      expiry: AuthService.getApiTokenExpiryTime(),
    };
  },

  query(resource, params) {
    const qs = require("qs");
    try {
      // store.commit(SET_LOADING, true);
      return Vue.prototype.$apiService.get(resource, {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
        headers: this.setHeaders(),
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      // store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },

  get(resource, id = "") {
    try {
      store.commit(SET_LOADING, true);
      return Vue.prototype.$apiService.get(`${resource}/${id}`, {
        headers: this.setHeaders(),
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
  get_task(resource, params = []) {
    try {
      store.commit(SET_LOADING, true);
      let base = `${resource}`;
      params.forEach((p) => {
        base += `/${p}`;
      });
      return Vue.prototype.$apiService.get(`${base}`, {
        headers: this.setHeaders(),
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
  post(resource, data) {
    try {
      return Vue.prototype.$apiService.post(`${resource}`, data, {
        headers: this.setHeaders(),
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
  update(resource, id, data) {
    try {
      store.commit(SET_LOADING, true);

      return Vue.prototype.$apiService.put(`${resource}/${id}`, data, {
        headers: this.setHeaders(),
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
  delete(resource, id) {
    try {
      store.commit(SET_LOADING, true);
      return Vue.prototype.$apiService.delete(`${resource}/${id}`, {
        headers: this.setHeaders(),
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
  //delete with body
  deleteArray(resource, data) {
    try {
      store.commit(SET_LOADING, true);
      return Vue.prototype.$apiService.delete(`${resource}`, {
        data: data,
        headers: this.setHeaders(),
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
  patch(resource, data) {
    try {
      store.commit(SET_LOADING, true);
      return Vue.prototype.$apiService.patch(`${resource}`, data, {
        headers: this.setHeaders(),
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
  postFormMultipart(resource, formData) {
    try {
      store.commit(SET_LOADING, true);
      return Vue.prototype.$apiService.post(`${resource}`, formData, {
        headers: {
          ...this.setHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
};

export const downloadCVService = {
  get(filename) {
    try {
      store.commit(SET_LOADING, true);
      return APIService.get("cv", filename);
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
};

export const UserImagesService = {
  get(id) {
    try {
      store.commit(SET_LOADING, true);
      return APIService.get(`v3/users/image/${id}`);
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
  upload(id, picture) {
    try {
      store.commit(SET_LOADING, true);
      return APIService.post(`v3/users/image/${id}`, { image: picture });
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
  delete(id) {
    try {
      store.commit(SET_LOADING, true);
      return APIService.delete(`/v3/users/${id}/image`);
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
};

export const BluepagesAPIService = {
  init() {
    const bluepagesInstance = axios.create({
      baseURL: "https://w3-unifiedprofile-api.dal1a.cirrus.ibm.com/v3/",
      timeout: 30000,
    });
    bluepagesInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        let errorMessage = { error };
        ErrorHandler(errorMessage);
      }
    );
    Vue.prototype.$bluepagesService = bluepagesInstance;
  },
  get(resource, id = "", type = "", requestParams = "") {
    try {
      store.commit(SET_LOADING, true);
      return Vue.prototype.$bluepagesService.get(
        `${resource}/${id}/${type}`,
        requestParams
      );
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
};

export const BluepagesService = {
  get(resource, id, type) {
    try {
      store.commit(SET_LOADING, true);
      return BluepagesAPIService.get(resource, id, type);
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
  getImage(resource, id, type) {
    try {
      store.commit(SET_LOADING, true);
      return BluepagesAPIService.get(resource, id, type, {
        responseType: "blob",
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      store.commit(SET_LOADING, false);
      store.commit(SET_TIMING_OUT);
    }
  },
};
