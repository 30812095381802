const ACCESS_TOKEN = "accessToken";
const REFRESH_TOKEN = "refreshToken";
const SERIAL_NUMBER = "serialNumber";
const API_TOKEN = "apiToken";
const ACCESS_TOKEN_EXPIRY_TIME = "accessTokenExpiryTime";
const API_TOKEN_EXPIRY_TIME = "apiTokenExpiryTime";
const USER_EMAIL = "userEmail";

export const getAccessToken = () => {
  return window.sessionStorage.getItem(ACCESS_TOKEN);
};

export const getRefreshToken = () => {
  return window.sessionStorage.getItem(REFRESH_TOKEN);
};

export const getAPItoken = () => {
  return window.sessionStorage.getItem(API_TOKEN);
};

export const getSerialNumber = () => {
  return window.sessionStorage.getItem(SERIAL_NUMBER);
};

export const getAccessTokenExpiryTime = () => {
  return window.sessionStorage.getItem(ACCESS_TOKEN_EXPIRY_TIME);
};

export const getApiTokenExpiryTime = () => {
  return window.sessionStorage.getItem(API_TOKEN_EXPIRY_TIME);
};

export const getSavedEmail = () => {
  return window.localStorage.getItem(USER_EMAIL);
};

export const saveAccessToken = (token) => {
  window.sessionStorage.setItem(ACCESS_TOKEN, token);
};

export const saveRefreshToken = (token) => {
  window.sessionStorage.setItem(REFRESH_TOKEN, token);
};

export const saveAPItoken = (token) => {
  window.sessionStorage.setItem(API_TOKEN, token);
};

export const saveSerialNumber = (serialNumber) => {
  window.sessionStorage.setItem(SERIAL_NUMBER, serialNumber);
};

export const saveEmail = (email) => {
  return window.localStorage.setItem(USER_EMAIL, email);
};

export const saveAccessTokenExpiryTime = (time) => {
  window.sessionStorage.setItem(ACCESS_TOKEN_EXPIRY_TIME, time);
};

export const saveApiTokenExpiryTime = (time) => {
  window.sessionStorage.setItem(API_TOKEN_EXPIRY_TIME, time);
};

export const destroyAccessToken = () => {
  window.sessionStorage.removeItem(ACCESS_TOKEN);
};

export const destroyRefreshToken = () => {
  window.sessionStorage.removeItem(REFRESH_TOKEN);
};

export const destroyAPItoken = () => {
  window.sessionStorage.removeItem(API_TOKEN);
};

export const destroySerialNumber = () => {
  window.sessionStorage.removeItem(SERIAL_NUMBER);
};

export const destroyAccessTokenExpiryTime = () => {
  window.sessionStorage.removeItem(ACCESS_TOKEN_EXPIRY_TIME);
};

export const destroyApiTokenExpiryTime = () => {
  window.sessionStorage.removeItem(API_TOKEN_EXPIRY_TIME);
};

export const destroySavedEmail = () => {
  return window.localStorage.removeItem(USER_EMAIL);
};

export default {
  getAccessToken,
  getRefreshToken,
  getSerialNumber,
  getAPItoken,
  getApiTokenExpiryTime,
  getAccessTokenExpiryTime,
  getSavedEmail,
  saveAccessToken,
  saveRefreshToken,
  saveAPItoken,
  saveApiTokenExpiryTime,
  saveSerialNumber,
  saveAccessTokenExpiryTime,
  saveEmail,
  destroyAccessToken,
  destroyRefreshToken,
  destroyAPItoken,
  destroyApiTokenExpiryTime,
  destroySerialNumber,
  destroyAccessTokenExpiryTime,
  destroySavedEmail,
};
