export const LOGIN = "login";
export const LOGOUT = "logout";
export const CHECK_AUTH = "checkAuth";
export const GET_ACCESS_TOKEN = "getAccessToken";
export const GET_API_TOKEN = "getApiToken";
export const REFRESH_TOKEN_TIMERS = "refreshTokenTimers";
export const GET_USER_DATA = "getUserData";
export const CREATE_NEW_USER = "createNewUser";
export const ONBOARD_NEW_USER = "onboardNewUser";
export const ONBOARD_FETCH_USER = "onboardFetchUser";
export const UPDATE_USER = "updateUser";
export const DELETE_USER = "deleteUser";
export const DELETE_OTHER_USER = "deleteOtherUser";
export const GET_USER_PROFILE_PICTURE = "getUserProfilePicture";
export const GET_USER_BLUEPAGES_PICTURE = "getUserBluepagesPicture";
export const GET_USER_BLUEPAGES_PICTURE_RAW = "getUserBluepagesPictureRaw";
export const UPDATE_USER_FROM_BLUEPAGES = "updateUserFromBluepages";
export const UPLOAD_RESOURCES = "uploadResources";

export const GET_CLIENT = "getClients";
export const CREATE_CLIENT = "createClients";
export const UPDATE_CLIENT = "updateClients";
export const DELETE_CLIENT = "deleteClients";

// export const UPDATE_USER_BADGE = "updateUserBadges";

export const GET_SECTOR = "getSector";
export const CREATE_SECTOR = "createSector";
export const UPDATE_SECTOR = "updateSector";
export const DELETE_SECTOR = "deleteSector";

export const GET_ACCOUNT_GROUP = "getAccountGroups";
export const CREATE_ACCOUNT_GROUP = "createAccountGroups";
export const UPDATE_ACCOUNT_GROUP = "updateAccountGroups";
export const DELETE_ACCOUNT_GROUP = "deleteAccountGroups";

export const GET_RESOURCE_GROUP = "getResourceGroup";
export const GET_RESOURCE_GROUP_DETAIL = "getResourceGroupDetail";
export const CREATE_RESOURCE_GROUP = "createResourceGroup";
export const UPDATE_RESOURCE_GROUP = "updateResourceGroup";
export const DELETE_RESOURCE_GROUP = "deleteResourceGroup";

export const GET_ACCOUNT = "getAccount";
export const CREATE_ACCOUNT = "createAccount";
export const UPDATE_ACCOUNT = "updateAccount";
export const DELETE_ACCOUNT = "deleteAccount";

export const GET_PROJECT = "getProjects";
export const GET_PROJECT_BY_ID = "getProjectDetail";
export const GET_PROJECT_BY_NAME = "getProjectByName";
export const CREATE_PROJECT = "createProject";
export const UPDATE_PROJECT = "updateProject";
export const DELETE_SOFT = "deleteSoft";

export const GET_PROJECT_OPEN_SEATS = "getProjectOpenSeats";
export const GET_PROJECT_RESOURCES = "getProjectResources";
export const CREATE_PROJECT_RESOURCES = "createProjectResources";
export const UPDATE_PROJECT_RESOURCES = "updateProjectResources";

export const GET_SKILL = "Skill";
export const CREATE_SKILL = "createSkill";
export const UPDATE_SKILL = "updateSkill";
export const DELETE_SKILL = "deleteSkill";
export const GET_TECH_SKILL = "TechSkill";
export const GET_SOFT_SKILL = "SoftSkill";

export const GET_ROLE = "getRole";

export const GET_USER_ROLE = "getUserRoles";
export const CREATE_USER_ROLE = "createUserRole";
export const UPDATE_USER_ROLE = "updateUserRole";
export const DELETE_USER_ROLE = "deleteUserRole";

export const GET_FILES = "getFiles";
export const CREATE_FILES = "createFiles";
export const UPDATE_FILES = "updateFiles";
export const DELETE_FILES = "deleteFiles";

export const GET_FEEDBACK = "getFeedback";
export const GET_FEEDBACK_DETAIL = "getFeedbackDetail";
export const CREATE_FEEDBACK = "createFeedback";
export const UPDATE_FEEDBACK = "updateFeedback";
export const DELETE_FEEDBACK = "deleteFeedback";

export const GET_BUG = "getBug";
export const GET_BUG_DETAIL = "getBugDetail";
export const CREATE_BUG = "createBug";
export const UPDATE_BUG = "updateBug";
export const UPLOAD_BUG_IMAGE = "uploadBugImage";
export const DELETE_BUG = "deleteBug";
export const GET_BAND = "getBands";
export const GET_SEAT_STATUS = "getSeatStatus";
export const GET_RESOURCE_TYPE = "getResourceType";

export const CREATE_RESOURCE = "createResources";

export const GET_CANDIDATE = "getCandidates";
export const GET_CANDIDATE_DATA = "getCandidateData";
export const GET_CANDIDATE_DETAIL = "getCandidateDetail";
export const CREATE_CANDIDATE_COMMENT = "createCandidateComment";
export const UPDATE_COMMENTS = "updateComments";

export const GET_COMMENTS_BY_CANDIDATE = "getCommentByCandidate";
export const GET_TOPTRUMP_CANDIDATE = "getTopTrumpCandidates";

export const GET_JRSS = "getJrss";
export const UPDATE_JRSS = "updateJrss";
export const DELETE_JRSS = "deleteJrss";
export const CREATE_JRSS = "createJrss";

export const GET_ARCHIVES = "getArchives";

export const GET_OPEN_SEAT = "getOpenSeat";
export const GET_OPEN_SEAT_BY_ID = "getOpenSeatById";
export const GET_OPEN_SEAT_USERS_BY_ID = "getOpenSeatUsersById";
export const UPDATE_OPEN_SEAT_CANDIDATE = "updateOpenSeatUsers";
export const GET_OPEN_SEAT_STATUS_HISTORY = "getOpenSeatStatusHistory";
export const GET_SUITABLE_CANDIDATES = "getSuitableCandidates";
export const UPDATE_OPEN_SEAT = "updateOpenSeat";
export const DELETE_OPEN_SEAT = "deleteOpenSeat";
export const CREATE_OPEN_SEAT = "createOpenSeat";
export const CREATE_OPEN_SEAT_CANDIDATE = "createOpenSeatCandidate";

export const GET_CONTACT_DETAIL = "getContactDetail";
export const UPDATE_CONTACT_DETAIL = "updateContactDetail";
export const CREATE_CONTACT_DETAIL = "createContactDetail";
export const DELETE_CONTACT_DETAIL = "deleteContactDetail";

export const GET_TSC = "getTsc";
export const GET_TSC_BY_ID = "getTscById";
export const GET_TSC_BY_SERIAL = "getTscBySerial";
export const UPDATE_TSC = "updateTsc";
export const DELETE_TSC = "deleteTsc";
export const CREATE_TSC = "createTsc";

export const GET_AGENCY = "getAgency";
export const UPDATE_AGENCY = "updateAgency";
export const DELETE_AGENCY = "deleteAgency";
export const CREATE_AGENCY = "createAgency";

export const GET_CLEARANCE = "getClearance";
export const UPDATE_CLEARANCE = "updateClearance";
export const DELETE_CLEARANCE = "deleteClearance";
export const CREATE_CLEARANCE = "createClearance";

export const UPDATE_USER_CLEARANCE = "updateUserClearance";

export const GET_CV = "getCV";
export const CREATE_CV = "createCV";
export const DELETE_CV = "deleteCV";
export const UPDATE_CV = "updateCV";

export const GET_STATUSES = "getStatuses";
export const GET_ACTION_TYPE = "getActionTypes";
export const downloadCV = "downloadCV";
export const GET_SEARCH_COMPONENTS = "getSearchComponents";
export const GET_PERSON_BY_ID = "getPersonById";
export const GET_OPEN_SEATS_BY_ID = "getOpenSeatsById";
export const GET_OPEN_SEATS_BY_PMP_ID = "getOpenSeatsByPMPId";

export const CREATE_SEARCH_HISTORY = "createSearchHistory";
export const GET_SEARCH_HISTORY = "getSearchHistory";
export const DELETE_SEARCH_HISTORY = "deleteSearchHistory";
export const UPDATE_SEARCH_HISTORY = "updateSearchHistory";

export const GET_NOTIFICATIONS = "getNotifications";

export const GET_SECTORS = "getSectors";
export const GET_INDUSTRY = "getIndustry";

export const GET_CURRENCY = "getCurrency";
export const GET_OPEN_SEAT_PERSON_STATUS = "getOpenSeatPersonStatus";

export const GET_PERSON = "getPerson";
export const GET_CANDIDATE_PERSON = "getCandidatePerson";
export const GET_RESOURCE_PERSON = "getResourcePerson";
export const DELETE_PERSON = "deletePerson";

export const GET_OPEN_SEAT_CANDIDATE = "getOpenSeatCandidate";
export const GET_OS_CANDIDATE_BY_SERIAL = "getOpenSeatCandidateBySerial";

export const GET_IMAGE_SOURCE = "getImageSource";
export const GET_EMPLOYEE_TYPE = "getEmployeeType";
export const GET_GENDER = "getGender";

export const GET_ATTACHMENTS_BY_ID = "getAttachmentById";
export const CREATE_ATTACHMENT = "createAttachment";
export const UPDATE_ATTACHMENT = "updateAttachment";
export const UPLOAD_ATTACHMENT = "uploadAttachment";

export const GET_EMAIL_TEMPLATE = "getEmailTemplate";
export const GET_EMAIL_TEMPLATE_BY_ID = "getEmailTemplateById";
export const CREATE_EMAIL_TEMPLATE = "createEmailTemplate";
export const UPDATE_EMAIL_TEMPLATE = "updateEmailTemplate";
export const DELETE_EMAIL_TEMPLATE = "deleteEmailTemplate";

export const GET_CONTACT_EMAIL_TYPE = "getContactEmailType";
export const GET_CONTACT_NUMBER_TYPE = "getContactNumberType";

export const GET_DOCUMENT_TYPE = "getDocumentType";
export const GET_LOCATION = "getLocation";

//Analytics Actions
export const GET_PERSON_ACCOUNT_CHART = "getPersonAccountChart";
export const GET_PERSON_AVAILABILITY_CHART = "getPersonAvailabilityChart";
export const GET_PERSON_BAND_CHART = "getPersonBandChart";
export const GET_PERSON_BAND_GROUP_CHART = "getPersonGroupBandChart";
export const GET_PERSON_JRSS_CHART = "getPersonJrssChart";
export const GET_PERSON_GROWTH_CHART = "getPersonGrowthChart";
export const GET_PERSON_PRACTICE_CHART = "getPersonPracticeChart";
export const GET_TECH_SKILL_OVERVIEW_CHART = "getTechSkillsOverviewChart";
export const GET_TECH_SKILL_EXPERIENCE_CHART = "getTechSkillsExperienceChart";
export const GET_PROF_SKILL_OVERVIEW_CHART = "getProfSkillsOverviewChart";
export const GET_PROF_SKILL_EXPERIENCE_CHART = "getProfSkillsExperienceChart";
export const GET_BAND_BY_ACCOUNT_CHART = "getBandByAccountChart";
export const GET_PERSONS_OVER_TIME_BY_ACCOUNT_CHART =
  "getPersonsOverTimeByAccountChart";
export const GET_OPEN_SEAT_OVER_TIME_CHART = "getOpenSeatsOverTimeChart";
export const GET_OPEN_SEAT_BY_ACCOUNT_CHART = "getOpenSeatsByAccountChart";
export const GET_OPEN_SEAT_BY_JRSS_CHART = "getOpenSeatsByJrssChart";
export const GET_OPEN_SEAT_BY_MONTH_CHART = "getOpenSeatsByMonthChart";
export const GET_OPEN_SEAT_BY_PROJECT_CHART = "getOpenSeatsByProjectChart";
export const GET_CANDIDATE_BY_SERIAL = "getCandidatesBySerial";
export const GET_RESOURCE_BOARDING_BY_GROUP = "getResourceBoardingCount";

export const GET_BADGE = "getBadge";
export const GET_USER_BADGE = "getBadge";
export const CREATE_BADGE = "createBadge";
export const UPDATE_BADGE = "updateBadge";
export const DELETE_BADGE = "deleteBadge";

export const CREATE_BAND = "createBand";
export const UPDATE_BAND = "updateBand";
export const DELETE_BAND = "deleteBand";
export const GET_CONTACT_METHOD = "getContactMethod";
export const CREATE_CONTACT_METHOD = "createContactMethod";
export const UPDATE_CONTACT_METHOD = "updateContactMethod";
export const DELETE_CONTACT_METHOD = "deleteContactMethod";
export const GET_CONTACT_TYPE = "getContactType";
export const CREATE_CONTACT_TYPE = "createContactType";
export const UPDATE_CONTACT_TYPE = "updateContactType";
export const DELETE_CONTACT_TYPE = "deleteContactType";
export const GET_DEGREE_LEVEL = "getDegreeLevel";
export const CREATE_DEGREE_LEVEL = "createDegreeLevel";
export const UPDATE_DEGREE_LEVEL = "updateDegreeLevel";
export const DELETE_DEGREE_LEVEL = "deleteDegreeLevel";
export const GET_EXPERIENCE_LEVEL = "getExperienceLevel";
export const CREATE_EXPERIENCE_LEVEL = "createExperienceLevel";
export const UPDATE_EXPERIENCE_LEVEL = "updateExperienceLevel";
export const DELETE_EXPERIENCE_LEVEL = "deleteExperienceLevel";
export const CREATE_RESOURCE_USER = "createResourceUser";

export const CREATE_GENDER = "createGender";
export const UPDATE_GENDER = "updateGender";
export const DELETE_GENDER = "deleteGender";
export const GET_GROWTH_PLATFORM = "getGrowthPlatform";
export const CREATE_GROWTH_PLATFORM = "createGrowthPlatform";
export const UPDATE_GROWTH_PLATFORM = "updateGrowthPlatform";
export const DELETE_GROWTH_PLATFORM = "deleteGrowthPlatform";

export const CREATE_LOCATION = "createLocation";
export const UPDATE_LOCATION = "updateLocation";
export const DELETE_LOCATION = "deleteLocation";
export const GET_LOCATION_TYPE = "getLocationType";
export const CREATE_LOCATION_TYPE = "createLocationType";
export const UPDATE_LOCATION_TYPE = "updateLocationType";
export const DELETE_LOCATION_TYPE = "deleteLocationType";
export const GET_OTHER_ROLE = "getOtherRole";
export const CREATE_OTHER_ROLE = "createOtherRole";
export const UPDATE_OTHER_ROLE = "updateOtherRole";
export const DELETE_OTHER_ROLE = "deleteOtherRole";
export const GET_PRACTICE = "getPractice";
export const CREATE_PRACTICE = "createPractice";
export const UPDATE_PRACTICE = "updatePractice";
export const DELETE_PRACTICE = "deletePractice";
export const GET_PRONOUN = "getPronoun";
export const CREATE_PRONOUN = "createPronoun";
export const UPDATE_PRONOUN = "updatePronoun";
export const DELETE_PRONOUN = "deletePronoun";
export const GET_SPECIALISM = "getSpecialism";
export const CREATE_SPECIALISM = "createSpecialism";
export const UPDATE_SPECIALISM = "updateSpecialism";
export const DELETE_SPECIALISM = "deleteSpecialism";

export const CREATE_ROLE = "createRole";
export const UPDATE_ROLE = "updateRole";
export const DELETE_ROLE = "deleteRole";
export const GET_PERMISSION = "getPermission";
export const CREATE_PERMISSION = "createPermission";
export const UPDATE_PERMISSION = "updatePermission";
export const DELETE_PERMISSION = "deletePermission";

export const GET_ROLE_PERMISSION = "getRolePermission";
export const CREATE_ROLE_PERMISSION = "createRolePermission";
export const UPDATE_ROLE_PERMISSION = "updateRolePermission";
export const DELETE_ROLE_PERMISSION = "deleteRolePermission";

export const GET_SEARCH_COMPONENT = "getSearchComponent";
export const GET_NOTIFICATION = "getNotification";
export const GET_OPEN_SEAT_TYPE = "getOpenSeatType";
export const GET_OPEN_SEAT_STATUS = "getOpenSeatStatus";
export const GET_OPEN_SEAT_USER_STATUS = "getOpenSeatUserStatus";
export const GET_CANDIDATE_USER = "getCandidateUser";
export const GET_RESOURCE_USER = "getResourceUser";
export const GET_RESOURCE_SERIAL = "getResourceSerial";
export const GET_RESOURCES = "getResources";
export const GET_RESOURCE_BY_ID = "getResources";
export const GET_TSC_SNAPSHOT = "getTscSnapshot";
export const GET_RESOURCE_PARAMETER = "getResourceParameter";
export const GET_RESOURCE_PARAMETER_DATA = "getResourceParameterData";
export const GET_RESOURCE_FOR_OPEN_SEAT = "getResourceForOpenSeat";
export const CREATE_RESOURCE_PARAMETER_DATA = "createResourceParameterData";
export const UPDATE_RESOURCE_PARAMETER_DATA = "updateResourceParameterData";
export const DELETE_RESOURCE_PARAMETER_DATA = "deleteResourceParameterData";

export const GET_USER_INVOICE = "getUserInvoice";
export const GET_INVOICES_BY_PO = "getInvoicesByPo";
export const UPDATE_USER_INVOICE = "updateUserInvoice";
export const CREATE_USER_INVOICE = "createUserInvoice";
export const DELETE_USER_INVOICE = "deleteUserInvoice";

export const GET_USER_PURCHASE_ORDERS = "getUserPurchaseOrders";
export const UPDATE_USER_PURCHASE_ORDERS = "updateUserPurchaseOrders";
export const CREATE_USER_PURCHASE_ORDERS = "createUserPurchaseOrders";
export const DELETE_USER_PURCHASE_ORDERS = "deleteUserPurchaseOrders";

export const CREATE_PROJECT_BOARD = "createProjectBoard";
export const UPDATE_PROJECT_BOARD = "updateProjectBoard";

export const CREATE_ACCOUNT_BOARD = "createAccountBoard";
export const UPDATE_ACCOUNT_BOARD = "updateAccountBoard";

export const GET_TASKLIST_TEMPLATE = "getTasklistTemplate";
export const GET_TASKLIST_TEMPLATE_DETAIL = "getTasklistTemplateDetail";
export const UPDATE_TASKLIST_TEMPLATE = "updateTasklistTemplate";
export const CREATE_TASKLIST_TEMPLATE = "createTasklistTemplate";
export const DELETE_TASKLIST_TEMPLATE = "deleteTasklistTemplate";

export const GET_TASKLIST = "getTasklist";
export const GET_TASKLIST_DETAIL = "getTasklistDetail";
export const GET_USER_TASKLIST = "getUserTasklist";
export const UPDATE_TASKLIST = "updateTasklist";
export const CREATE_TASKLIST = "createTasklist";
export const DELETE_TASKLIST = "deleteTasklist";

export const GET_TASK_TEMPLATE = "getTaskTemplate";
export const GET_TASK_TEMPLATE_DETAIL = "getTaskTemplateDetail";
export const GET_TASK_TEMPLATE_BY_TASKLIST = "getTaskTemplateByTasklist";
export const UPDATE_TASK_TEMPLATE = "updateTaskTemplate";
export const CREATE_TASK_TEMPLATE = "createTaskTemplate";
export const DELETE_TASK_TEMPLATE = "deleteTaskTemplate";

export const GET_TASK = "getTask";
export const GET_TASK_DETAIL = "getTaskDetail";
export const GET_USER_TASKS = "getUserTasks";
export const UPDATE_USER_TASKS = "updateUserTasks";
export const UPDATE_TASK = "updateTask";
export const CREATE_TASK = "createTask";
export const DELETE_TASK = "deleteTask";

export const GET_TASK_TYPE = "getTaskType";
export const GET_TASK_TYPE_DETAIL = "getTaskTypeDetail";
export const UPDATE_TASK_TYPE = "updateTaskType";
export const CREATE_TASK_TYPE = "createTaskType";
export const DELETE_TASK_TYPE = "deleteTaskType";

export const GET_TASKLIST_STATUS = "getTasklistStatus";
export const GET_TASKLIST_STATUS_DETAIL = "getTasklistStatusDetail";
export const UPDATE_TASKLIST_STATUS = "updateTasklistStatus";
export const CREATE_TASKLIST_STATUS = "createTasklistStatus";
export const DELETE_TASKLIST_STATUS = "deleteTasklistStatus";

export const GET_TASK_STATUS = "getTaskStatus";
export const GET_TASK_STATUS_DETAIL = "getTaskStatusDetail";
export const UPDATE_TASK_STATUS = "updateTaskStatus";
export const CREATE_TASK_STATUS = "createTaskStatus";
export const DELETE_TASK_STATUS = "deleteTaskStatus";

export const GET_PERMISSIONS_GROUPS = "getPermissionsGroups";
export const GET_PERMISSIONS_GROUPS_DETAIL = "getPermissionsGroupsDetail";
export const UPDATE_PERMISSION_GROUPS = "updatePermissionsGroups";
export const CREATE_PERMISSION_GROUPS = "createPermissionsGroups";

export const GET_REPORT = "getReport";

export const GET_ROLE_MAPPERS = "getRoleMappers";

export const GET_USER_PREFERENCES = "getUserPreferences";
export const UPDATE_USER_PREFERENCES = "updateUserPreferences";
export const CREATE_USER_PREFERENCES = "createUserPreferences";

export const SEND_EMAIL = "sendEmail";

export const GET_EMAIL_LOGS = "getEmailLogs";
export const GET_EMAIL_LOG_DETAIL = "getEmailLogDetail";
export const GET_EMAIL_LOGS_BY_SEAT = "getEmailLogsBySeat";
export const CREATE_EMAIL_LOG = "createEmailLog";

export const GET_CANDIDATE_TRACKER = "getCandidateTracker";
export const GET_CANDIDATE_TRACKER_DETAIL = "getCandidateTrackerDetail";
export const UPDATE_CANDIDATE_TRACKER = "updateCandidateTracker";

export const GET_ONBOARDING_STATUSES = "getOnboardingStatuses";
export const GET_SLACK_STATUSES = "getSlackStatuses";
