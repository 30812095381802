<template>
  <div class="form-container">
    <reportingBug ref="reportingbug" />
  </div>
</template>

<script>
import reportingBug from "../../../components/form/reportingBug/reportingBug.vue";
export default {
  name: "reportingbug",
  components: {
    reportingBug,
  },
};
</script>
